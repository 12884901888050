
import {
  create_firestore_document,
  update_firestore_document,
} from '@/services/firestore_utils';

import {
  timeline_events_with_multiple_links,
} from '@/services/timeline-events-with-links';

import {
  doc,
} from 'firebase/firestore';

/**
 * Regenerates thread data by:
 * - creating new thread documents for threads that have changed (and deleting the old thread documents)
 * - keeping old thread documents for threads that have not changed.
 * @param {Object} param0
 * @param {Record<string, boolean>} param0.event_link_ids
 * @param {Record<string, string[]>} param0.old_events_in_threads
 * @param {import('firebase/firestore').DocumentReference} param0.timeline_event_references_ref
 * @param {import('firebase/firestore').CollectionReference} param0.threads_collection_ref
 * @param {string} param0.user_id
 * @param {import('firebase/firestore').WriteBatch} [param0.batch] If passed in, writes are performed against the batch operation.
 * @param {import('firebase/firestore').Transaction} [param0.transaction] If passed in, writes are performed against the transaction.
 */
export function regenerate_threads({
  event_link_ids,
  old_events_in_threads,
  timeline_event_references_ref,
  threads_collection_ref,
  user_id,
  transaction,
  batch,
}) {

  if (!batch && !transaction || (batch && transaction)) {
    throw new Error('regenerate_threads must be called with either a batch or a transaction (but not both)');
  }

  const events_with_links = timeline_events_with_multiple_links({
    event_link_ids: Object.keys(event_link_ids),
  });

  const all_events_with_links = new Set([
    ...Object.keys(events_with_links.to_event_ids),
    ...Object.keys(events_with_links.from_event_ids),
  ]);

  /** @type {string[][]} */
  const grouped_events = walk_nodes({
    events_with_links,
    all_events_with_links,
  });

  // We can match unchanged threads to their existing thread ID
  // by creating a key from their sorted, joined event IDs.
  const historical_threads_by_key = Object.keys(old_events_in_threads)
    .reduce((acc, thread_id) => {
      const key = [
        ...old_events_in_threads[thread_id],
      ].sort().join('|');
      acc[key] = thread_id;
      return acc;
    }, {});

  /**
   * New timeline_event_references.events_in_threads property.
   * @type {Record<string, string[]>}
   */
  const events_in_threads = {};

  /** @type {import('firebase/firestore').DocumentReference[]} */
  const threads_to_create = [];

  grouped_events
    .forEach((events) => {

      const key = [
        ...events,
      ].sort().join('|');

      // Let's look at historical_threads_by_key to see if we have
      // a match.
      const existing_thread_id = historical_threads_by_key[key];

      if (existing_thread_id) {
        // We found an existing thread for these events.
        events_in_threads[existing_thread_id] = events;
      } else {
        // There is no existing thread for these events, let's create a new one.

        const new_thread_ref = doc(threads_collection_ref);
        const new_thread_id = new_thread_ref.id;

        threads_to_create.push(new_thread_ref);

        events_in_threads[new_thread_id] = events;
      }
    });

  const batch_or_transaction = (batch || transaction);

  // Let's delete any no-longer-used threads.
  const deletion_promises = Object.keys(old_events_in_threads)
    .filter((old_id) => !events_in_threads[old_id])
    .map((id) => /** @type {NonNullable<typeof batch_or_transaction>} */(batch_or_transaction).delete(doc(threads_collection_ref, id)));

  // Let's create any new threads.
  const creation_promises = threads_to_create
    .map((document_ref) => {
      return create_firestore_document({
        document_ref,
        document: {},
        user_id,
        transaction,
        batch,
      }).promise;
    });

  // Update the timeline event references document.
  const write_promise = update_firestore_document({
    document_ref: timeline_event_references_ref,
    document: {
      events_in_threads,
    },
    user_id,
    transaction,
    batch,
  }).promise;

  return Promise.all([
    write_promise,
    ...deletion_promises,
    ...creation_promises,
  ]);
}

/**
 *
 * @param {Object} param0
 * @param {Set} param0.all_events_with_links
 * @param {ReturnType<timeline_events_with_multiple_links>} param0.events_with_links
 */
function walk_nodes({
  events_with_links,
  all_events_with_links,
}) {

  // Starting at the potential beginning of each thread.
  // - build an initial list of events linked from or to this event
  // - add the first event to the current thread and track as a visited event
  // - loop over each event in the to_visit list
  //   - add any unvisited to/from events from current event to to_visit list
  //   - add current event to visited set
  //   - continue until we run out of to_visit events

  const visited_events = new Set();

  const grouped_events = [];

  while (all_events_with_links.size) {

    const id = [
      ...all_events_with_links,
    ][0];

    const events_in_thread = [
      id,
    ];
    visited_events.add(id);
    all_events_with_links.delete(id);

    let events_to_visit = [
      ...(events_with_links.to_event_ids[id] || []),
      ...(events_with_links.from_event_ids[id] || []),
    ].filter((evt) => !visited_events.has(evt));

    let next_event = events_to_visit.shift();

    while (next_event) {
      visited_events.add(next_event);
      all_events_with_links.delete(next_event);
      events_in_thread.push(next_event);
      events_to_visit = [
        ...events_to_visit,
        ...(events_with_links.to_event_ids[next_event] || []),
        ...(events_with_links.from_event_ids[next_event] || []),
      ].filter((evt) => !visited_events.has(evt));

      next_event = events_to_visit.shift();
    }

    if (events_in_thread.length > 1) {
      grouped_events.push(events_in_thread);
    }
  }

  return grouped_events;
}
