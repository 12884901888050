<template>
  <div class="breadcrumbs">
    <v-breadcrumbs
      data-test-id="breadcrumbs"
      class="breadcrumbs__list"
      :items="items" />
  </div>
</template>

<script>

import {
  defineComponent,
} from 'vue';

export default defineComponent({
  name: 'BreadCrumbs',
  components: {},
  methods: {
    get_parent_breadcrumb_route(parent_route) {
      return parent_route?.meta?.breadcrumb?.parent_name && this.$router.resolve({
        name: parent_route.meta.breadcrumb.parent_name,
        params: this.$route.params,
      });
    },
  },
  computed: {
    items() {

      let items = [];

      if (!this.$route.meta?.breadcrumb) {
        return items;
      }

      items.push({
        disabled: true,
        text: this.$route.meta.breadcrumb.name,
      });

      let next_route = this.get_parent_breadcrumb_route(this.$route);

      while (next_route && next_route.route && next_route.route.meta && next_route.route.meta.breadcrumb) {

        items.push({
          to: {
            name: next_route.route.name,
            params: this.$route.params,
          },
          link: true,
          exact: true,
          text: next_route.route.meta.breadcrumb.name,
        });

        next_route = this.get_parent_breadcrumb_route(next_route.route);
      }

      return items.reverse();
    },
  },
});

</script>

<style lang="scss">

.breadcrumbs {
  &__list {
    &#{&} {
      padding-left: 0;
    }
  }
}

</style>
