import axiosInstance from './axios';
import {
  API_ROOT,
} from './api-paths';

/**
 * Generates a URL for the project-api-keys endpoint.
 * @param {Object} param0
 * @param {string} param0.workspace_id
 * @param {string} param0.project_id
 * @param {string} [param0.resource_id] If provided, returns the URL for this specific resource. Otherwise, returns the URL for the list endpoint.
 * @returns
 */
function generate_url({
  workspace_id,
  project_id,
  resource_id,
}) {
  const url = `${API_ROOT}workspaces/${workspace_id}/projects/${project_id}/api-keys/`;

  if (resource_id) {
    return `${url}${resource_id}/`;
  }

  return url;
}

/**
 * @typedef {Object} ProjectApiKeyApiResponse
 * @property {string} id
 * @property {string} type
 * @property {string} key
 */

/**
 * Creates an API key for a project.
 *
 * @param {Object} param0
 * @param {string} param0.workspace_id ID of the workspace this key should be created under.
 * @param {string} param0.project_id ID of the project this key should be created under.
 * @param {string} param0.key_type Type of key to be created.
 * @returns {Promise<ProjectApiKeyApiResponse>}
 */
export function create({
  workspace_id,
  project_id,
  key_type,
}) {

  const URL = generate_url({
    workspace_id,
    project_id,
  });

  const data = {
    type: key_type,
  };

  return axiosInstance.post(URL, data)
    .then((response) => response.data);
}

/**
 * Deletes an API key for a project.
 *
 * @param {Object} param0
 * @param {string} param0.workspace_id ID of the workspace this key should be created under.
 * @param {string} param0.project_id ID of the project this key should be created under.
 * @param {string} param0.project_api_key_id ID of the API key we're deleting.
 * @returns {Promise} A promise that resolves when deletion has completed.
 */
export function delete_resource({
  workspace_id,
  project_id,
  project_api_key_id,
}) {

  const URL = generate_url({
    workspace_id,
    project_id,
    resource_id: project_api_key_id,
  });

  return axiosInstance.delete(URL);
}
