/**
 * This file contains centralised definitions of complex
 * firestore document/collection structures.
 */

export const workspace_child_collections_map = {
  projects: 'projects',
  workspace_members: 'workspace_members',
};

export const workspace_child_collections = Object.values(workspace_child_collections_map);

export const project_child_collections_map = {
  narrative_events: 'narrative_events',
  project_api_keys: 'project_api_keys',
  published_sequenced_timelines: 'published_sequenced_timelines',
  sequenced_timelines: 'sequenced_timelines',
  webhook_secrets: 'webhook_secrets',
};

export const project_child_collections = Object.values(project_child_collections_map);

/**
 * Stores names of all child collections for a sequenced timeline.
 * @type {{ [K in keyof TimelineChildCollections]: K }}
 */
export const sequenced_timeline_child_collections_map = {
  timeline_events: 'timeline_events',
  timeline_rows: 'timeline_rows',
  timeline_beats: 'timeline_beats',
  timeline_event_links: 'timeline_event_links',
  timeline_linked_threads: 'timeline_linked_threads',
  timeline_progression_conditions: 'timeline_progression_conditions',
  timeline_push_events: 'timeline_push_events',
};

export const sequenced_timeline_child_collections = Object.values(sequenced_timeline_child_collections_map);

/**
 * Stores names of all "child documents" for a timeline.
 * A "child document" is a single document within a child
 * collection. These usually have the same ID as the parent
 * (i.e. timeline) document.
 */
export const sequenced_timeline_child_documents_map = {
  timeline_event_references: 'timeline_event_references',
};

export const sequenced_timeline_child_documents = Object.values(sequenced_timeline_child_documents_map);
