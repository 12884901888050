import GlobalVue from 'vue';

import {
  get as get_event_bus,
} from '@/services/event-bus';

const EventBus = {};

/**
 * @param {import('vue').VueConstructor} Vue
 */
EventBus.install = function (Vue) {
  Object.defineProperties(Vue.prototype, {
    $bus: {
      get: function () {
        return get_event_bus();
      },
    },
  });
};

GlobalVue.use(EventBus);

export default EventBus;
