import {
  collection,
  doc,
} from 'firebase/firestore';

import {
  db,
} from '@/services/firebase.js';

import {
  converters,
} from '../converters/index.js';

export const must_user_refs = {
  document: ({
    must_user_id,
  }) => {
    const path = [
      'must_users',
      must_user_id,
    ].join('/');
    return doc(db, path).withConverter(converters.must_users);
  },
  collection: () => {
    const path = [
      'must_users',
    ].join('/');
    return collection(db, path).withConverter(converters.must_users);
  },
};
