
/** @type {Required<RuleFields & {id: string,}>} */
export const rule_fields_defaults = {
  id: '',
  operator: '',
  realtime_date: '',
  realtime_time: '',
  time_is_before: false,
  relativetime_time: '',
  normalizedtime_days: 0,
  normalizedtime_time: '',
  variable_rules: [],
  real_time_variable: '',
  real_time_variable_delta: false,
  real_time_variable_delta_days: 0,
  real_time_variable_delta_time: '',
  real_time_variable_delta_direction_before: true,
};
