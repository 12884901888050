<template>
  <v-container fluid>
    <v-row data-test-id="project-list">
      <v-col
        v-for="project in sorted_projects"
        :key="project.id"
        class="col-12 col-sm-6 col-md-4 col-lg-3">
        <v-card elevation="9">
          <v-card-title class="pb-0">
            <h5 class="text-h2 project__title">
              {{ project.title }}
            </h5>
            <v-spacer />
            <v-menu offset-y>
              <template #activator="{ on, attrs }">
                <v-btn
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  :to="{
                    name: 'ProjectSettings',
                    params: {
                      project_id: project.id,
                    },
                  }">
                  <v-list-item-icon class="mr-0">
                    <v-icon small>
                      mdi-cog
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    Project Settings
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-card-text class="project__project-id mt-1">
            <span class="code-format pr-1">{{ project.id }}</span>
            <v-tooltip
              top>
              <template
                #activator="{ on: tooltip }">
                <div
                  v-on="tooltip"
                  class="d-inline-block">
                  <CopyToClipboardButton
                    :text="project.id"
                    size="x-small" />
                </div>
              </template>
              <span>Copy the project ID to clipboard</span>
            </v-tooltip>
          </v-card-text>
          <v-card-text
            v-if="project.modified_at"
            class="project__last-edited-container">
            <span class="project__last-edited">
              Last edited: {{ $store.getters.member_display_name_from_id(project.modified_by) }}, {{ format_datetime_from_now(project.modified_at) }}
            </span>
          </v-card-text>
          <v-card-actions class="justify-end-sm ml-auto">
            <v-btn
              text
              color="primary"
              :to="{
                name: 'TimelineList',
                params: {
                  project_id: project.id,
                },
              }">
              View project
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        v-if="$store.getters.user_can_edit"
        class="col-12 col-sm-6 col-md-4 col-lg-3">
        <router-link
          data-test-id="new-project-list-item"
          :to="{
            name: 'CreateProject',
          }"
          class="new-project">
          <div>
            <v-icon color="#1EB980">
              mdi-plus-circle
            </v-icon>
            <span class="new-project__label">New project</span>
          </div>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {
  defineComponent,
} from 'vue';

import CopyToClipboardButton from '@/components/CopyToClipboardButton.vue';

import {
  format_datetime_from_now,
} from 'shared-js/date-formats';

export default defineComponent({
  name: 'ProjectList',
  components: {
    CopyToClipboardButton,
  },
  methods: {
    format_datetime_from_now,
  },
  computed: {
    sorted_projects() {
      return [
        ...Object.values(this.$store.state.projects),
      ].sort((a, b) => a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1);
    },
  },
});
</script>

<style lang="scss" scoped>
.new-project {
  align-items: center;
  border: 1px dashed #1EB980;
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: 140px;
  text-decoration: none;
  &__label {
    color: #1EB980;
    font-style: italic;
    padding-left: 4px;
  }
}
.project {
  &__title {
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__last-edited {
    color: rgba(255, 255, 255, .56);
  }
  &__project-id {
    color: rgba(255, 255, 255, 1) !important;
  }
  &__last-edited-container {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  &__project-id .code-format {
    display: inline;
  }
}
</style>
