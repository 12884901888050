import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import NotFoundError from '../views/NotFoundError.vue';
import ServerError from '../views/ServerError.vue';
import SignOut from '../views/SignOut.vue';
import {
  config,
} from '@/services/config';
import {
  app_bar_layouts_map,
} from '@/services/constants';

import {
  CLEAR_ROUTE_ERROR,
} from '../store/mutation-types';

import {
  get_current_user,
} from '@/services/firebase-auth';

Vue.use(VueRouter);

/** @type {import('vue-router').RouteConfig[]} */
const routes = [
  {
    path: '/500',
    name: '500',
    component: ServerError,
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      breadcrumb: {
        name: 'Projects',
      },
      html_meta: {
        title: 'Projects',
      },
    },
  },
  {
    path: '/sign-in',
    name: 'Auth',
    component: () => import('../views/Auth.vue'),
    meta: {
      bypass_auth: true,
    },
  },
  {
    path: '/terms-of-service',
    name: 'TermsOfService',
    component: () => import('../views/TermsOfService.vue'),
    meta: {
      bypass_auth: true,
    },
  },
  {
    path: '/workspace-settings',
    component: () => import('../views/WorkspaceSettings.vue'),
    children: [
      {
        path: '',
        name: 'WorkspaceSettings',
        component: () => import('../components/forms/WorkspaceForm.vue'),
        meta: {
          breadcrumb: {
            name: 'Workspace settings',
          },
          html_meta: {
            title: 'Workspace settings',
          },
        },
      },
      {
        path: 'members',
        name: 'WorkspaceMembers',
        component: () => import('../components/WorkspaceMembers.vue'),
        meta: {
          breadcrumb: {
            name: 'Workspace members',
          },
          html_meta: {
            title: 'Workspace members',
          },
        },
      },
    ],
  },
  {
    path: '/projects/new',
    name: 'CreateProject',
    component: () => import('../views/CreateProject.vue'),
    meta: {
      breadcrumb: {
        name: 'Add Project',
      },
      html_meta: {
        title: 'Add Project',
      },
    },
  },
  {
    path: '/projects/:project_id',
    name: 'Project',
    component: () => import('../views/Project.vue'),
    children: [
      {
        path: '/projects/:project_id/timelines',
        name: 'TimelineList',
        component: () => import('../views/TimelineList.vue'),
        meta: {
          breadcrumb: {
            name: 'Timelines',
            parent_name: 'Home',
          },
          html_meta: {
            title: 'Timelines',
          },
        },
      },
      {
        path: '/projects/:project_id/timelines/new',
        name: 'CreateTimeline',
        component: () => import('../views/CreateTimeline.vue'),
        meta: {
          breadcrumb: {
            name: 'Create Timeline',
            parent_name: 'TimelineList',
          },
          html_meta: {
            title: 'New Timeline',
          },
        },
      },
      {
        path: '/projects/:project_id/timelines/:timeline_id',
        name: 'Timeline',
        component: () => import('../views/Timeline.vue'),
        meta: {
          app_bar_layout: app_bar_layouts_map.TIMELINE,
          breadcrumb: {
            name: 'Timeline',
            parent_name: 'TimelineList',
          },
          hide_footer: true,
          html_meta: {
            title: 'Timeline',
          },
        },
        children: [
          {
            path: '/projects/:project_id/timelines/:timeline_id/events/:timeline_event_id/edit',
            name: 'TimelineEventEdit',
            components: {
              inspector_panel: () => import('../components/forms/TimelineEventForm.vue'),
            },
            meta: {
              breadcrumb: {
                name: 'Edit Timeline Event',
                parent_name: 'TimelineEvent',
              },
              hide_footer: true,
              html_meta: {
                title: 'Edit Timeline Event',
              },
            },
          },
          {
            path: '/projects/:project_id/timelines/:timeline_id/events/:timeline_event_id/:tab?',
            name: 'TimelineEvent',
            props: {
              inspector_panel: true,
            },
            components: {
              inspector_panel: () => import('../components/TimelineEvent.vue'),
            },
            meta: {
              breadcrumb: {
                name: 'Timeline Event',
                parent_name: 'Timeline',
              },
              hide_footer: true,
              html_meta: {
                title: 'Timeline Event',
              },
            },
          },
          {
            path: '/projects/:project_id/timelines/:timeline_id/links/:timeline_link_id',
            name: 'TimelineEventLink',
            props: {
              inspector_panel: true,
            },
            components: {
              inspector_panel: () => import('../components/TimelineEventLink.vue'),
            },
            meta: {
              breadcrumb: {
                name: 'Timeline Link',
                parent_name: 'Timeline',
              },
              hide_footer: true,
              html_meta: {
                title: 'Timeline Link',
              },
            },
          },
          {
            path: '/projects/:project_id/timelines/:timeline_id/rows/:timeline_row_id',
            name: 'TimelineRow',
            components: {
              inspector_panel: () => import('../components/TimelineRow.vue'),
            },
            meta: {
              breadcrumb: {
                name: 'Timeline Row',
                parent_name: 'Timeline',
              },
              hide_footer: true,
              html_meta: {
                title: 'Timeline Row',
              },
            },
          },
          {
            path: '/projects/:project_id/timelines/:timeline_id/rows/:timeline_row_id/edit',
            name: 'TimelineRowEdit',
            components: {
              inspector_panel: () => import('../components/forms/TimelineRowForm.vue'),
            },
            meta: {
              breadcrumb: {
                name: 'Edit Timeline Row',
                parent_name: 'TimelineRow',
              },
              hide_footer: true,
              html_meta: {
                title: 'Edit Timeline Row',
              },
            },
          },
          {
            path: '/projects/:project_id/timelines/:timeline_id/beats/:timeline_beat_id',
            name: 'TimelineBeat',
            components: {
              inspector_panel: () => import('../components/TimelineBeat.vue'),
            },
            meta: {
              breadcrumb: {
                name: 'Timeline Beat',
                parent_name: 'Timeline',
              },
              hide_footer: true,
              html_meta: {
                title: 'Timeline Beat',
              },
            },
          },
          {
            path: '/projects/:project_id/timelines/:timeline_id/beats/:timeline_beat_id/edit',
            name: 'TimelineBeatEdit',
            components: {
              inspector_panel: () => import('../components/forms/TimelineBeatForm.vue'),
            },
            meta: {
              breadcrumb: {
                name: 'Edit Timeline Beat',
                parent_name: 'TimelineBeat',
              },
              hide_footer: true,
              html_meta: {
                title: 'Edit Timeline Beat',
              },
            },
          },
          {
            path: '/projects/:project_id/timelines/:timeline_id/progression-conditions/:timeline_progression_condition_id',
            name: 'TimelineProgressionCondition',
            components: {
              inspector_panel: () => import('../components/TimelineProgressionCondition.vue'),
            },
            meta: {
              breadcrumb: {
                name: 'Timeline Progression Condition',
                parent_name: 'Timeline',
              },
              hide_footer: true,
              html_meta: {
                title: 'Timeline Progression Condition',
              },
            },
          },
          {
            path: '/projects/:project_id/timelines/:timeline_id/progression-conditions/:timeline_progression_condition_id/edit',
            name: 'TimelineProgressionConditionEdit',
            components: {
              inspector_panel: () => import('../components/forms/TimelineProgressionConditionForm.vue'),
            },
            meta: {
              breadcrumb: {
                name: 'Edit Timeline Progression Condition',
                parent_name: 'TimelineProgressionCondition',
              },
              hide_footer: true,
              html_meta: {
                title: 'Edit Timeline Progression Condition',
              },
            },
          },
          {
            path: '/projects/:project_id/timelines/:timeline_id/variables/:timeline_variable_id',
            name: 'TimelineVariable',
            props: {
              inspector_panel: true,
            },
            components: {
              inspector_panel: () => import('../components/TimelineVariable.vue'),
            },
            meta: {
              breadcrumb: {
                name: 'Timeline Variable',
                parent_name: 'Timeline',
              },
              hide_footer: true,
              html_meta: {
                title: 'Timeline Variable',
              },
            },
          },
          {
            path: '/projects/:project_id/timelines/:timeline_id/simulate/:timeline_event_id?',
            name: 'TimelineSimulation',
            components: {
              bottom_panel: () => import('../views/TimelineSimulation.vue'),
            },
            meta: {
              breadcrumb: {
                name: 'Timeline Simulation',
                parent_name: 'Timeline',
              },
              hide_footer: true,
              html_meta: {
                title: 'Simulator',
              },
            },
          },
          {
            path: '/projects/:project_id/timelines/:timeline_id/ai-chat',
            name: 'TimelineAIChat',
            props: {
              inspector_panel: true,
            },
            components: {
              inspector_panel: () => import('../components/AiChat.vue'),
            },
            meta: {
              breadcrumb: {
                name: 'Timeline AI Chat',
                parent_name: 'Timeline',
              },
              hide_footer: true,
              html_meta: {
                title: 'Timeline AI Chat',
              },
            },
          },
        ],
      },
      {
        path: '/projects/:project_id/published-timelines/:timeline_id',
        name: 'PublishedTimeline',
        component: () => import('../views/PublishedTimeline.vue'),
        meta: {
          app_bar_layout: app_bar_layouts_map.TIMELINE,
          breadcrumb: {
            name: 'Published Timeline',
            parent_name: 'TimelineList',
          },
          hide_footer: true,
          html_meta: {
            title: 'Published Timeline',
          },
        },
        children: [
          {
            path: '/projects/:project_id/published-timelines/:timeline_id/events/:timeline_event_id/edit',
            name: 'PublishedTimelineEventEdit',
            components: {
              inspector_panel: () => import('../components/forms/TimelineEventForm.vue'),
            },
            meta: {
              breadcrumb: {
                name: 'Edit Published Timeline Event',
                parent_name: 'PublishedTimelineEvent',
              },
              hide_footer: true,
              html_meta: {
                title: 'Edit Published Timeline Event',
              },
            },
          },
          {
            path: '/projects/:project_id/published-timelines/:timeline_id/events/:timeline_event_id/:tab?',
            name: 'PublishedTimelineEvent',
            props: {
              inspector_panel: true,
            },
            components: {
              inspector_panel: () => import('../components/TimelineEvent.vue'),
            },
            meta: {
              breadcrumb: {
                name: 'Published Timeline Event',
                parent_name: 'PublishedTimeline',
              },
              hide_footer: true,
              html_meta: {
                title: 'Published Timeline Event',
              },
            },
          },
          {
            path: '/projects/:project_id/published-timelines/:timeline_id/links/:timeline_link_id',
            name: 'PublishedTimelineEventLink',
            props: {
              inspector_panel: true,
            },
            components: {
              inspector_panel: () => import('../components/TimelineEventLink.vue'),
            },
            meta: {
              breadcrumb: {
                name: 'Published Timeline Link',
                parent_name: 'PublishedTimeline',
              },
              hide_footer: true,
              html_meta: {
                title: 'Published Timeline Link',
              },
            },
          },
          {
            path: '/projects/:project_id/published-timelines/:timeline_id/rows/:timeline_row_id',
            name: 'PublishedTimelineRow',
            components: {
              inspector_panel: () => import('../components/TimelineRow.vue'),
            },
            meta: {
              breadcrumb: {
                name: 'Published Timeline Row',
                parent_name: 'PublishedTimeline',
              },
              hide_footer: true,
              html_meta: {
                title: 'Published Timeline Row',
              },
            },
          },
          {
            path: '/projects/:project_id/published-timelines/:timeline_id/rows/:timeline_row_id/edit',
            name: 'PublishedTimelineRowEdit',
            components: {
              inspector_panel: () => import('../components/forms/TimelineRowForm.vue'),
            },
            meta: {
              breadcrumb: {
                name: 'Edit Published Timeline Row',
                parent_name: 'PublishedTimelineRow',
              },
              hide_footer: true,
              html_meta: {
                title: 'Edit Published Timeline Row',
              },
            },
          },
          {
            path: '/projects/:project_id/published-timelines/:timeline_id/beats/:timeline_beat_id',
            name: 'PublishedTimelineBeat',
            components: {
              inspector_panel: () => import('../components/TimelineBeat.vue'),
            },
            meta: {
              breadcrumb: {
                name: 'Published Timeline Beat',
                parent_name: 'PublishedTimeline',
              },
              hide_footer: true,
              html_meta: {
                title: 'Published Timeline Beat',
              },
            },
          },
          {
            path: '/projects/:project_id/published-timelines/:timeline_id/beats/:timeline_beat_id/edit',
            name: 'PublishedTimelineBeatEdit',
            components: {
              inspector_panel: () => import('../components/forms/TimelineBeatForm.vue'),
            },
            meta: {
              breadcrumb: {
                name: 'Edit Published Timeline Beat',
                parent_name: 'PublishedTimelineBeat',
              },
              hide_footer: true,
              html_meta: {
                title: 'Edit Published Timeline Beat',
              },
            },
          },
          {
            path: '/projects/:project_id/published-timelines/:timeline_id/progression-conditions/:timeline_progression_condition_id',
            name: 'PublishedTimelineProgressionCondition',
            components: {
              inspector_panel: () => import('../components/TimelineProgressionCondition.vue'),
            },
            meta: {
              breadcrumb: {
                name: 'Published Timeline Progression Condition',
                parent_name: 'PublishedTimeline',
              },
              hide_footer: true,
              html_meta: {
                title: 'Published Timeline Progression Condition',
              },
            },
          },
          {
            path: '/projects/:project_id/published-timelines/:timeline_id/progression-conditions/:timeline_progression_condition_id/edit',
            name: 'PublishedTimelineProgressionConditionEdit',
            components: {
              inspector_panel: () => import('../components/forms/TimelineProgressionConditionForm.vue'),
            },
            meta: {
              breadcrumb: {
                name: 'Edit Published Timeline Progression Condition',
                parent_name: 'PublishedTimelineProgressionCondition',
              },
              hide_footer: true,
              html_meta: {
                title: 'Edit Published Timeline Progression Condition',
              },
            },
          },
          {
            path: '/projects/:project_id/published-timelines/:timeline_id/variables/:timeline_variable_id',
            name: 'PublishedTimelineVariable',
            props: {
              inspector_panel: true,
            },
            components: {
              inspector_panel: () => import('../components/TimelineVariable.vue'),
            },
            meta: {
              breadcrumb: {
                name: 'Published Timeline Variable',
                parent_name: 'PublishedTimeline',
              },
              hide_footer: true,
              html_meta: {
                title: 'Published Timeline Variable',
              },
            },
          },
          {
            path: '/projects/:project_id/published-timelines/:timeline_id/simulate/:timeline_event_id?',
            name: 'PublishedTimelineSimulation',
            components: {
              bottom_panel: () => import('../views/TimelineSimulation.vue'),
            },
            meta: {
              breadcrumb: {
                name: 'Published Timeline Simulation',
                parent_name: 'PublishedTimeline',
              },
              hide_footer: true,
              html_meta: {
                title: 'Simulator',
              },
            },
          },
        ],
      },
      {
        path: '/projects/:project_id/published-timelines/:timeline_id/analytics',
        name: 'TimelineAnalytics',
        component: () => import('../views/TimelineAnalytics.vue'),
        meta: {
          app_bar_layout: app_bar_layouts_map.TIMELINE,
          breadcrumb: {
            name: 'Timeline analytics',
            parent_name: 'TimelineList',
          },
          hide_footer: true,
          html_meta: {
            title: 'Timeline analytics',
          },
        },
        children: [
          {
            path: '/projects/:project_id/published-timelines/:timeline_id/analytics/events/:timeline_event_id',
            name: 'TimelineEventAnalytics',
            props: {
              inspector_panel: true,
            },
            components: {
              inspector_panel: () => import('../components/TimelineEventAnalytics.vue'),
            },
            meta: {
              breadcrumb: {
                name: 'Timeline Event Analytics',
                parent_name: 'TimelineAnalytics',
              },
              hide_footer: true,
              html_meta: {
                title: 'Timeline Event Analytics',
              },
            },
          },
        ],
      },
    ],
  },
  {
    path: '/projects/:project_id/settings',
    component: () => import('../views/ProjectSettings.vue'),
    children: [
      {
        path: '',
        name: 'ProjectSettings',
        props: true,
        component: () => import('../components/forms/ProjectForm.vue'),
        meta: {
          breadcrumb: {
            name: 'Project settings',
            parent_name: 'Home',
          },
          html_meta: {
            title: 'Project settings',
          },
        },
      },
      {
        path: 'api-keys',
        name: 'ApiKeys',
        component: () => import('../views/ApiKeys.vue'),
        props: true,
        meta: {
          breadcrumb: {
            name: 'API Keys',
            parent_name: 'Home',
          },
          html_meta: {
            title: 'API Keys',
          },
        },
      },
      {
        path: 'webhooks',
        name: 'Webhooks',
        component: () => import('../views/Webhooks.vue'),
        props: true,
        meta: {
          breadcrumb: {
            name: 'Webhooks',
            parent_name: 'Home',
          },
          html_meta: {
            title: 'Webhooks',
          },
        },
      },
    ],
  },
  {
    path: '/typography',
    name: 'Typography',
    component: () => import('../views/Typography.vue'),
    meta: {
      conditional_typography_route: true,
    },
  },
  {
    path: '/sign-out',
    name: 'SignOut',
    component: SignOut,
  },
  {
    path: '*',
    name: '404',
    component: NotFoundError,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes,
});

// When using `$router.push` to change the "page" to the same page you are on vue-router will throw an Error.
// Here we're swallowing this error for all routes as it becomes problematic in handling params with breadcrumb history functionality.
const originalPush = router.push;
router.push = function push(location) {
  return originalPush.call(this, location).catch(err => {
    if (err && err.name !== 'NavigationDuplicated') {
      throw err;
    }
  });
};

/**
 * Tests whether a route name is considered an "error route" e.g. a 404 or a 500.
 * @param {string | null | undefined} [route_name] The name of the route to test.
 * @returns {boolean} Returns true is the passed route_name is an error route, false otherwise.
 */
function is_error_route(route_name = '') {
  if (!route_name) {
    return false;
  }
  const error_route_names = [
    '404',
    '500',
  ];
  return error_route_names.includes(route_name);
}

router.beforeResolve((to, from, next) => {

  if (!is_error_route(to.name) && router.app.$store.state.route_error) {
    // If we're navigating away from a route error page (e.g. 404/500 error screen)
    // clear the route error in state before loading the next (non-error) view.
    router.app.$store.commit(CLEAR_ROUTE_ERROR);
  }

  next();
});

router.beforeEach(async (to, from, next) => {

  const is_auth_route = to.name === 'Auth';

  const requires_auth = !is_error_route(to.name) && to.matched.some(record => !record.meta.bypass_auth);
  const conditional_typography_route = to.matched.some(record => record.meta.conditional_typography_route);

  if (requires_auth || is_auth_route) {
    const user_is_authenticated = await get_current_user();

    if (requires_auth && !user_is_authenticated) {
      next({
        name: 'Auth',
        query: {
          'redirect_to': to.path,
        },
      });
      return;
    } else if (is_auth_route && user_is_authenticated?.user && to.query.redirect_to) {

      const next_params = {};

      if (Array.isArray(to.query.redirect_to)) {
        // It's unlikely, but the to.query.redirect_to param may be an array of strings.
        if (to.query.redirect_to.length) {
          next_params.path = to.query.redirect_to[0];
        }
      } else if (to.query.redirect_to.length) {
        // Otherwise, we're a (hopefully non-empty) string.
        next_params.path = to.query.redirect_to;
      }

      next(next_params);

      return;
    }
  }

  if (conditional_typography_route) {
    if (!config.fictioneers.include_typography_page) {
      next({
        path: '/404',
      });
    }
  }

  next();
});

router.onError((error) => {
  if (error.message.startsWith('Failed to fetch dynamically imported module')) {
    // If we've failed to lazy-load an async component, reload the page.
    window.location.reload();
  }
});

export default router;
