
import moment from 'moment';

import {
  date_input_format,
} from '../constants/index.js';

export const format_datetime_for_api = (datetime) => {
  // We treat all dates as UTC+00:00.
  return moment(datetime).format('YYYY-MM-DDTHH:mm:ss.SSS+00:00');
};

export const format_datetime_for_text = (datetime) => {
  return moment(datetime).format('DD/MM/YYYY HH:mm:ss');
};

export const format_date_for_text = (datetime) => {
  return moment(datetime).format(date_input_format);
};

export const format_datetime_from_now = (datetime) => {
  return moment(datetime).fromNow();
};

export const format_date_for_picker = (datetime) => {
  return moment(datetime).format('YYYY-MM-DD');
};
