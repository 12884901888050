
import {
  auth,
  provider,
} from '@/services/firebase';
import {
  browserLocalPersistence,
  onAuthStateChanged,
  setPersistence,
  signInWithRedirect,
} from 'firebase/auth';
import {
  update_custom_claims,
} from '@/services/api/update-custom-claims';

let CURRENT_USER;

/** @type {Promise<{user: import("firebase/auth").User, token: import("firebase/auth").IdTokenResult} | null>} */
let get_current_user_promise;

/**
 * Returns a singleton promise that resolves to the current user + token if logged in
 * and resolves to null if not logged in.
 */
export const get_current_user = () => {

  if (get_current_user_promise) {
    return get_current_user_promise;
  }

  get_current_user_promise = new Promise((resolve) => {

    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {

          unsubscribe();

          if (!user) {
            resolve(null);
            return;
          }

          CURRENT_USER = user;

          if (window.FS?.identify && user.email) {
            window.FS.identify(user.uid, {
              displayName: user.displayName || user.email,
              email: user.email,
            });
          }

          const token = await user.getIdTokenResult();

          resolve({
            user,
            token,
          });

        });
      });
  });

  return get_current_user_promise;
};

export const sign_out = () => {
  return new Promise((resolve, reject) => {
    auth.signOut()
      .then(() => {
        if (window.FS?.anonymize) {
          window.FS.anonymize();
        }
        resolve(true);
      })
      .catch(reject);
  });
};

/**
 * @returns {ReturnType<get_current_user>}
 */
export const sign_in = () => {

  provider.addScope('profile');
  provider.addScope('email');

  return new Promise((resolve, reject) => {
    signInWithRedirect(auth, provider)
      .then(() => {
        return get_current_user();
      })
      .catch(reject);
  });
};

export function set_custom_claims() {
  if (!auth.currentUser) {
    throw new Error('No user');
  }
  return update_custom_claims();
}

export function refresh_user_token() {
  if (!auth.currentUser) {
    throw new Error('No user');
  }
  return auth.currentUser.getIdTokenResult(true);
}

export const get_bearer_token = async () => {

  if (!CURRENT_USER?.getIdToken) {
    return '';
  }

  return CURRENT_USER.getIdToken();
};
