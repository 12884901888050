
import * as dagre from '@dagrejs/dagre';

function generateNodeGraphGridPositions({
  vertices,
  edges,
}) {
  const graph = new dagre.graphlib.Graph();
  graph.setGraph({
    rankdir: 'LR',
    nodesep: 1,
    ranksep: 1,
    edgesep: 1,
    marginx: .5,
    marginy: .5,
  });
  graph.setDefaultEdgeLabel(function() {
    return {};
  });

  vertices.forEach((vertex) => {
    graph.setNode(vertex.name, {
      name: vertex.name,
      width: 1,
      height: 1,
    });
  });

  edges.forEach((edge) => {
    graph.setEdge(edge.from_name, edge.to_name);
  });

  const layout = /** @type {Object.<string, any>} */(/** @type {any} */(dagre.layout(graph)));

  const positions = [];
  for (const nodeId in layout) {
    const node = graph.node(nodeId);
    positions.push({
      // @ts-ignore
      name: node.name,
      row: node.y,
      column: node.x,
    });
  }

  return graph;
}

export const createGraphFromVerticesEdges = ({
  vertices,
  edges,
}) => {
  const graph = generateNodeGraphGridPositions({
    vertices,
    edges,
  });

  const v_obj = vertices.reduce((acc, cur) => {
    acc[cur.name] = {
      source: cur,
      dims: null,
    };
    return acc;
  }, {});

  graph.nodes().forEach((nodeName) => {
    console.log(nodeName);
    v_obj[nodeName].dims = graph.node(nodeName);
  });

  return {
    nodes: Object.values(v_obj),
  };
};
