<template>
  <section class="404" />
</template>

<script>

import {
  defineComponent,
} from 'vue';

import {
  SET_404_ROUTE_ERROR,
} from '@/store/mutation-types';

export default defineComponent({
  name: 'NotFoundView',
  created() {
    this.$store.commit(SET_404_ROUTE_ERROR);
  },
});
</script>

<style scoped lang="scss">
</style>
