
export default (Vuetify) => new Vuetify({
    theme: {
      dark: true,
      options: { customProperties: true, },
      themes: {
        dark: {
          primary: '#1EB980',
          secondary: '#3A3A42',
          tertiary: '#FF6859',
          quaternary: '#FFCF44',
          quinary: '#B15DFF',
          senary: '#72DEFF',
          accent: '#36817A',
          accent_2: '#7200F1',
          accent_3: '#8457F5',
          accent_4: '#465BA3',
          accent_5: '#62716F',
          background: '#282828',
          surface: '#353535',
          light_surface: '#3e3e3e',
          border: '#202020',
          success: '#4CAF50',
          error: '#FF5252',
          info: '#2196F3',
          warn: '#C39615',
        },
      },
    },
  });
