import {
  collection,
  doc,
} from 'firebase/firestore';

import {
  db,
} from '@/services/firebase.js';

import {
  converters,
} from '../converters/index.js';

export const project_refs = {
  document: ({
    workspace_id,
    project_id,
  }) => {
    const path = [
      'workspaces',
      workspace_id,
      'projects',
      project_id,
    ].join('/');
    return doc(db, path).withConverter(converters.projects);
  },
  collection: ({
    workspace_id,
  }) => {
    const path = [
      'workspaces',
      workspace_id,
      'projects',
    ].join('/');
    return collection(db, path).withConverter(converters.projects);
  },
};


