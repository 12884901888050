import axiosInstance from './axios';
import {
  API_ROOT,
} from './api-paths';

/**
 * Call API to create a workspace membership
 * @param {Object} param0
 * @param {string} param0.invitee_email
 * @param {string} param0.workspace_id
 * @param {string} param0.role
 * @returns
 */
export function create({
  invitee_email, workspace_id, role,
}) {
  const URL = `${API_ROOT}workspaces/${workspace_id}/memberships/`;
  const data = {
    invitee_email,
    role,
  };
  return axiosInstance.post(URL, data)
    .then((response) => response.data);
}

/**
 * Call API to update a workspace membership
 * @param {Object} param0
 * @param {string} param0.id
 * @param {string} param0.workspace_id
 * @param {string} param0.role
 * @returns
 */
export function update({
  id, workspace_id, role,
}) {
  const URL = `${API_ROOT}workspaces/${workspace_id}/memberships/${id}`;
  const data = {
    role,
  };
  return axiosInstance.patch(URL, data)
    .then((response) => response.data);
}

/**
 * Call API to update a workspace membership
 * @param {Object} param0
 * @param {string} param0.id
 * @param {string} param0.workspace_id
 * @returns
 */
export function delete_resource({
  id, workspace_id,
}) {
  const URL = `${API_ROOT}workspaces/${workspace_id}/memberships/${id}`;
  return axiosInstance.delete(URL)
    .then((response) => response.data);
}
