<template>
  <div class="workspace-menu">
    <span class="pr-1 workspace-menu__workspace-name">{{ $store.state.current_workspace && $store.state.current_workspace.name }}</span>
    <v-menu
      v-model="menu_expanded"
      offset-y
      min-width="220"
      max-height="100%">
      <template #activator="{ on, attrs }">
        <v-btn
          dark
          icon
          small
          v-bind="attrs"
          v-on="on"
          aria-label="Show settings and workspaces">
          <v-icon
            :class="{
              'workspace-menu__expand-icon--expanded': menu_expanded
            }">
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>
      <v-list
        dense
        subheader>
        <v-subheader v-if="$store.state.current_project">
          <strong>{{ $store.state.current_project?.title }}</strong>
        </v-subheader>
        <v-list-item
          v-if="$store.state.current_project"
          :to="{
            name: 'ProjectSettings',
            params: {
              project_id: $store.state.current_project.id,
            },
          }">
          <v-list-item-icon class="mr-0">
            <v-icon small>
              mdi-cog
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Project settings
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-subheader><strong>{{ cached_current_workspace && cached_current_workspace.name }}</strong></v-subheader>
        <v-list-item
          :to="{
            name: 'WorkspaceSettings',
          }">
          <v-list-item-icon class="mr-0">
            <v-icon small>
              mdi-cog
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              Settings &amp; members
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="logout">
          <v-list-item-icon class="mr-0">
            <v-icon small>
              mdi-logout
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Sign Out</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list
        v-if="inactive_workspaces.length"
        dense
        subheader>
        <v-subheader>
          Available workspaces
        </v-subheader>
        <v-list-item
          v-for="(workspace, index) in inactive_workspaces"
          :key="index"
          @click="switch_workspace(workspace.id)">
          <v-list-item-content>
            <v-list-item-title>
              <button>
                {{ workspace.name }}
              </button>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>

import {
  defineComponent,
} from 'vue';

export default defineComponent({
  name: 'WorkspaceMenu',
  data: () => ({
    menu_expanded: false,
    /** @type {Workspace[]} */
    cached_workspaces: [],
    cached_current_workspace: {},
  }),
  methods: {
    async logout () {
      const auth_route_with_logged_out_msg = this.$router.resolve({
        name: 'Auth',
        query: {
          message: 'signed_out',
        },
      });
      await this.$store.dispatch('firebase_logout', auth_route_with_logged_out_msg?.href);
    },
    async switch_workspace(workspace_id) {

      this.menu_expanded = false;

      await this.$store.dispatch('set_current_workspace', workspace_id);

      this.$router.push({
        name: 'Home',
      });
    },
  },
  computed: {
    inactive_workspaces() {
      return this.cached_workspaces
        .filter(({
          id,
        }) => id !== this.cached_current_workspace?.id)
        .sort((a, b) => a.name < b.name ? -1 : 1);
    },
  },
  watch: {
    menu_expanded() {
      if (this.menu_expanded) {
        this.cached_workspaces = [
          ...Object.values(this.$store.state.workspaces),
        ];
        this.cached_current_workspace = {
          ...this.$store.state.current_workspace,
        };
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.workspace-menu {

  &__workspace-name {
    position: relative;
    top: 1px;
  }

  &__expand-icon {
    transition: .4s;

    &--expanded {
      transform: rotate(-180deg);
    }
  }
}
</style>
