import axiosInstance from './axios';
import {
  API_ROOT,
} from './api-paths';

/**
 * Calls an RPC endpoint which updates the current user custom claims.
 */
export function update_custom_claims() {
  const URL = `${API_ROOT}update-custom-claims`;
  return axiosInstance.post(URL);
}
