<template>
  <v-btn
    class="copy-to-clipboard-button"
    @click="copy"
    icon
    :x-small="is_x_small"
    aria-label="Copy to clipboard"
    data-test-id="copy-to-clipboard">
    <v-icon>mdi-content-copy</v-icon>
  </v-btn>
</template>

<script>

import {
  defineComponent,
} from 'vue';

export default defineComponent({
  name: 'CopyToClipboardButton',
  props: {
    text: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.text);
    },
  },
  computed: {
    is_x_small() {
      return this.size === 'x-small';
    },
  },
});
</script>
