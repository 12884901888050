<template>
  <v-app-bar
    app
    clipped-left
    ref="app_bar"
    class="app-header"
    data-test-id="app-header"
    color="surface">
    <template v-if="is_default_layout">
      <v-app-bar-nav-icon
        :to="{name: 'Home'}"
        active-class="v-btn--no-active-style"
        data-test-id="app-header-home-button">
        <v-icon>mdi-home</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title
        data-test-id="app-logo"
        class="pl-1">
        FICTIONEERS
        <span
          v-if="app_title_is_local"
          class="app-header__env-banner app-header__env-banner--is-local"
          data-test-id="env-is-local-label">
          LOCAL
        </span>
        <span
          v-if="app_title_is_dev"
          class="app-header__env-banner app-header__env-banner--is-dev"
          data-test-id="env-is-dev-label">
          DEV
        </span>
      </v-toolbar-title>
      <Breadcrumbs class="app-header__breadcrumbs" />
    </template>
    <template v-if="is_timeline_layout">
      <v-tooltip bottom>
        <template #activator="{ on: tooltip }">
          <v-btn
            :to="{
              name: 'TimelineList',
              params: {
                project_id: $route.params.project_id,
              },
            }"
            v-on="tooltip"
            exact
            icon
            rounded
            aria-label="Back to project timelines"
            data-test-id="app-header-back-to-timelines"
            class="app-header__back-to-timelines">
            <v-icon>
              mdi-arrow-left
            </v-icon>
          </v-btn>
        </template>
        Timelines
      </v-tooltip>
      <v-divider
        class="ml-1 mr-4"
        inset
        align-self-center
        vertical />
      <v-toolbar-title
        v-if="$store.state.current_timeline_is_ready && $store.state.current_timeline"
        data-test-id="app-header-timeline-title">
        <v-tooltip bottom>
          <template #activator="{ on: tooltip }">
            <v-icon
              v-on="tooltip"
              class="app-header__is-published-icon"
              :aria-label="`This timeline is ${$store.state.current_timeline_is_published ? 'published' : 'in draft'}`"
              data-test-id="app-header-is-published-icon">
              {{ $store.state.current_timeline_is_published ? 'mdi-check-circle-outline' : 'mdi-file-outline' }}
            </v-icon>
          </template>
          This timeline is {{ $store.state.current_timeline_is_published ? 'published' : 'in draft' }}
        </v-tooltip>
        {{ $store.state.current_timeline.title }}
      </v-toolbar-title>
      <v-divider
        class="ml-4 mr-4"
        inset
        align-self-center
        vertical />
      <span
        class="code-format"
        v-if="$store.state.current_timeline_is_ready && $store.state.current_timeline">
        {{ $store.state.current_timeline.id }}
        <v-tooltip
          top>
          <template
            #activator="{ on: tooltip }">
            <div
              v-on="tooltip"
              class="d-inline-block">
              <CopyToClipboardButton
                :text="$store.state.current_timeline.id"
                size="x-small" />
            </div>
          </template>
          <span>Copy the timeline ID to clipboard</span>
        </v-tooltip>
      </span>
    </template>
    <v-alert
      class="app-header__global-alert"
      :value="global_alert"
      mode="scroll-y-reverse-transition"
      color="success"
      dense
      text>
      <div
        v-for="(text, $index) in global_alert_text"
        :key="$index"
        :data-test-id="`global-alert-message-${$index}`">
        {{ text }}
      </div>
    </v-alert>
    <v-spacer />
    <template v-if="is_timeline_layout">
      <span
        v-if="$store.state.current_timeline_is_ready && $store.state.current_timeline"
        class="caption text--secondary"
        data-test-id="app-header-last-edited">
        Last edited:
        <span data-test-id="app-header-last-edited-by">{{ $store.getters.member_display_name_from_id($store.state.current_timeline.modified_by) }}</span>,
        {{ format_datetime_from_now($store.state.current_timeline.modified_at) }}
      </span>
      <v-divider
        inset
        align-self-center
        class="mx-4"
        vertical />
    </template>
    <WorkspaceMenu />
  </v-app-bar>
</template>

<script>

import Vue from 'vue';

import {
  defineComponent,
} from 'vue';

import Breadcrumbs from '@/components/Breadcrumbs.vue';
import WorkspaceMenu from '@/components/WorkspaceMenu.vue';
import CopyToClipboardButton from '@/components/CopyToClipboardButton.vue';

import {
  config,
} from '@/services/config';

import {
  app_bar_layouts_map,
} from '@/services/constants';

import {
  SET_APP_BAR_HEIGHT,
} from '@/store/mutation-types';

import {
  format_datetime_from_now,
} from 'shared-js/date-formats';

export default defineComponent({
  name: 'AppHeader',
  components: {
    Breadcrumbs,
    WorkspaceMenu,
    CopyToClipboardButton,
  },
  data: () => ({
    global_alert_message_key_to_message_text: {},
    app_title_is_dev: config.fictioneers.app_title.is_dev,
    app_title_is_local: config.fictioneers.app_title.is_local,
  }),
  mounted() {
    this.$bus.$on('show_alert', this.show_alert);
    this.$bus.$on('clear_alert', this.clear_alert);
    this.$bus.$on('resize:AppContent', this.set_app_bar_height_in_vuex);
    this.$nextTick(() => {
      this.set_app_bar_height_in_vuex();
    });
  },
  methods: {
    show_alert({
      message_key,
      text,
    }) {
      Vue.set(this.global_alert_message_key_to_message_text, message_key, text);
    },
    clear_alert(message_key) {
      Vue.delete(this.global_alert_message_key_to_message_text, message_key);
    },
    set_app_bar_height_in_vuex() {

      // @ts-ignore
      const height = this.$refs.app_bar?.computedHeight || 0;

      if (height !== this.$store.state.app_bar_height) {
        this.$store.commit(SET_APP_BAR_HEIGHT, height);
      }
    },
    format_datetime_from_now,
  },
  computed: {
    global_alert: {
      get() {
        return !!Object.keys(this.global_alert_message_key_to_message_text).length;
      },
      set() {},
    },
    global_alert_text() {
      return Object.values(this.global_alert_message_key_to_message_text)
        .filter((text, index, messages) => {
          return messages.indexOf(text) === index;
        });
    },
    is_default_layout() {
      return !this.is_timeline_layout;
    },
    is_timeline_layout() {
      return this.closest_route_meta_app_bar_layout === app_bar_layouts_map.TIMELINE && !this.$store.getters.is_error_route;
    },
    /**
     * Checks the current and all parent routes for one that has a meta.app_bar_layout defined.
     *
     * @returns {string|undefined} Returns the value of the first matching route.meta.app_bar_layout property or undefined.
     */
    closest_route_meta_app_bar_layout() {
      // this.$route.matched goes in parent -> child order, we want to check children first.
      const routes_to_check = this.$route.matched.slice().reverse();
      return routes_to_check.find((route) => route.meta?.app_bar_layout)?.meta?.app_bar_layout;
    },
  },
});
</script>

<style lang="scss">

.app-header {
  &__breadcrumbs {
    margin-top: 4px;
    margin-left: 32px;
  }

  &__global-alert {
    &#{&} {
      left: 50%;
      position: absolute;
      top: 100%;
      transform: translate(-50%, -50%);
    }
  }

  &__env-banner {
    padding: 0 4px;

    &--is-local {
      background-color: green;
    }

    &--is-dev {
      background-color: orange;
      color: black;
    }
  }

  &__is-published-icon {
    margin-bottom: 3px;
  }

  &__back-to-timelines {
    margin-left: -12px;
  }

  .theme--dark.v-divider {
    border-color: rgb(131, 131, 136);
  }
}

</style>
