import {
  config,
} from '../config';

const API_DOMAIN = config.fictioneers.api_domain;

export const API_ROOT = `${API_DOMAIN}/api-cms/v1/`;


// Generates list and detail URLs for an API path.
// `parent_resources` should be a list of {
//    path,
//    param_getter,
// }
// objects that are used to add parent resources to the URL.
//
// `path` should be the path of the parent resource and `param_getter`
// should be a function that takes the resource and returns the pk of that
// parent resource from the subresource.
//
// This allows us to build sub-resource URLs of the form `/time-based-acts/1/content-overrides/2/`.
export function create_api_urls(path, {
  is_public_api = false,
  parent_resources = [],
} = {}) {

  let api_root = is_public_api ? `${API_DOMAIN}/v1/` : API_ROOT;

  let list_url_getter = (resource) => {
    let list_url = parent_resources
      .reduce((list_url, {
        path,
        param_getter,
      }) => {
        return `${list_url}${path}/${param_getter(resource)}/`;
      }, '');

    return `${api_root}${list_url}${path}/`;
  };

  let detail_url_getter = (resource) => {
    let list_url = list_url_getter(resource);
    return `${list_url}${resource.pk}/`;
  };

  return {
    list_url_getter,
    detail_url_getter,
  };
}
