

// Bring environment variables into app config
// Use these config variables within the app instead of
// directly referencing "import.meta.env" environment variables

// IMPORTANT: env variables must be referenced using their full
// import.meta.env.FOO string https://vitejs.dev/guide/env-and-mode.html#production-replacement
const VITE_APP_FICTIONEERS_APP_TITLE = import.meta.env.VITE_APP_FICTIONEERS_APP_TITLE;
const VITE_APP_FICTIONEERS_API_DOMAIN = import.meta.env.VITE_APP_FICTIONEERS_API_DOMAIN;
const VITE_APP_FICTIONEERS_INCLUDE_TYPOGRAPHY_PAGE = import.meta.env.VITE_APP_FICTIONEERS_INCLUDE_TYPOGRAPHY_PAGE;
const VITE_APP_COOKIE_CONTROL_KEY = import.meta.env.VITE_APP_COOKIE_CONTROL_KEY;
const VITE_APP_COOKIE_CONTROL_PRODUCT = import.meta.env.VITE_APP_COOKIE_CONTROL_PRODUCT;
const VITE_APP_FULL_STORY_KEY = import.meta.env.VITE_APP_FULL_STORY_KEY;
const VITE_APP_SENTRY_ORG = import.meta.env.VITE_APP_SENTRY_ORG;
const VITE_APP_SENTRY_PROJECT = import.meta.env.VITE_APP_SENTRY_PROJECT;
const VITE_APP_SENTRY_DSN = import.meta.env.VITE_APP_SENTRY_DSN;
const VITE_APP_SENTRY_TRACING_ORIGIN = import.meta.env.VITE_APP_SENTRY_TRACING_ORIGIN;
const VITE_APP_SENTRY_ENVIRONMENT = import.meta.env.VITE_APP_SENTRY_ENVIRONMENT;
const VITE_APP_SENTRY_UPLOAD_SOUCEMAPS = import.meta.env.VITE_APP_SENTRY_UPLOAD_SOUCEMAPS;
const VITE_APP_FIREBASE_PROJECT_ID = import.meta.env.VITE_APP_FIREBASE_PROJECT_ID;
const VITE_APP_FIREBASE_API_KEY = import.meta.env.VITE_APP_FIREBASE_API_KEY;
const VITE_APP_FIREBASE_AUTH_DOMAIN = import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN;
const VITE_APP_FIREBASE_STORAGE_BUCKET = import.meta.env.VITE_APP_FIREBASE_STORAGE_BUCKET;
const VITE_APP_FIREBASE_MESSAGING_SENDER_ID = import.meta.env.VITE_APP_FIREBASE_MESSAGING_SENDER_ID;
const VITE_APP_FIREBASE_APP_ID = import.meta.env.VITE_APP_FIREBASE_APP_ID;
const VITE_APP_FIREBASE_EMULATOR = import.meta.env.VITE_APP_FIREBASE_EMULATOR;
const VITE_APP_FIREBASE_FORCE_LONG_POLLING = import.meta.env.VITE_APP_FIREBASE_FORCE_LONG_POLLING;
const VITE_APP_DATASTORE_EMULATOR = import.meta.env.VITE_APP_DATASTORE_EMULATOR;
const VITE_PLUGIN_SENTRY_CONFIG = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG;


/**
 * App config
 * @type {Config} config
 */
const config = {
  fictioneers: {
    app_title: {
      is_dev: VITE_APP_FICTIONEERS_APP_TITLE === 'DEV',
      is_local: VITE_APP_FICTIONEERS_APP_TITLE === 'LOCAL',
    },
    api_domain: VITE_APP_FICTIONEERS_API_DOMAIN,
    include_typography_page: VITE_APP_FICTIONEERS_INCLUDE_TYPOGRAPHY_PAGE === 'true',
  },
  cookie_control: {
    key: VITE_APP_COOKIE_CONTROL_KEY,
    product: VITE_APP_COOKIE_CONTROL_PRODUCT,
  },
  full_story: {
    key: VITE_APP_FULL_STORY_KEY,
  },
  sentry: {
    org: VITE_APP_SENTRY_ORG,
    project: VITE_APP_SENTRY_PROJECT,
    dsn: VITE_APP_SENTRY_DSN,
    tracing: VITE_APP_SENTRY_TRACING_ORIGIN,
    environment: VITE_APP_SENTRY_ENVIRONMENT,
    upload_sourcemaps: VITE_APP_SENTRY_UPLOAD_SOUCEMAPS === 'true',
    release: VITE_PLUGIN_SENTRY_CONFIG?.release,
  },
  firebase: {
    project_id: VITE_APP_FIREBASE_PROJECT_ID,
    api_key: VITE_APP_FIREBASE_API_KEY,
    auth_domain: VITE_APP_FIREBASE_AUTH_DOMAIN,
    storage_bucket: VITE_APP_FIREBASE_STORAGE_BUCKET,
    messaging_sender_id: VITE_APP_FIREBASE_MESSAGING_SENDER_ID,
    app_id: VITE_APP_FIREBASE_APP_ID,
    emulator: VITE_APP_FIREBASE_EMULATOR === 'true',
    force_long_polling: VITE_APP_FIREBASE_FORCE_LONG_POLLING === 'true',
  },
  datastore: {
    emulator: VITE_APP_DATASTORE_EMULATOR === 'true',
  },
};

export {
  config,
};
