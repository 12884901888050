


/**
 * Converts integer variables from strings to ints (as all variables are stores as strings).
 *
 * @param {import("firebase/firestore").DocumentSnapshot<SequencedTimeline|PublishedTimeline>} snapshot
 * @param {import("firebase/firestore").SnapshotOptions} options
 */
export function from_firestore(snapshot, options) {

  const doc_data = snapshot.data(options);

  if (!doc_data) {
    return undefined;
  }

  if (doc_data.variables) {
    Object.values(doc_data.variables).forEach((var_def) => {
      if (var_def.type === 'int') {
        var_def.default = parseInt(`${var_def.default}`, 10);
      }
    });
  }

  return {
    id: snapshot.id,
    data: doc_data,
  };
}
