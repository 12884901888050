import axiosInstance from './axios';
import {
  API_ROOT,
} from './api-paths';


/**
 * Get analytics data for a timeline
 *
 * @param {Object} param0
 * @param {string} param0.workspace_id
 * @param {string} param0.project_id
 * @param {string} param0.timeline_id
 * @returns {Promise<TimelineAnalytics>}
 */
export function get({
  workspace_id,
  project_id,
  timeline_id,
}) {
  if (!workspace_id || !project_id || !timeline_id) {
    throw new Error('Missing workspace_id / project_id / timeline_id');
  }
  const url = `${API_ROOT}workspaces/${workspace_id}/projects/${project_id}/timeline-analytics/${timeline_id}/`;
  return axiosInstance.get(url)
    .then((response) => response.data);
}
