import axiosInstance from './axios';

import {
  create_api_urls,
} from './api-paths';
import {
  config,
} from '../config';

// The Datastore Emulator can only handle 25 transactions max.
// Production Datastore can handle 250.
const is_datastore_emulator = config.datastore.emulator;
const TRANSACTION_MAXIMUM_ENTITIES_COUNT = is_datastore_emulator ? 25 : 250;

/**
 * @param {string} api_path
 * @param {Object} argument
 * @param {Object[]} [argument.dummy_data] An optional array of static values that are returned on the list endpoint instead of a `fetch`.
 * @param {boolean} [argument.is_public_api] Default (false) hits /api-cms/, if true we hit the public `/v1/` endpoint.
 * @param {function} [argument.parser] Optional function that accepts a resource representation from the API and should return the resource in a format ready for the app.
 * @param {function} [argument.formatter] (function) Optional function that accepts a resource representation from the app and should return the resource in a format ready for the API.
 * @param {boolean} [argument.is_data_object] {boolean} Default (false) the list endpoint returns an array, if true the list endpoint returns a data object.
 * @param {string[]} [argument.parent_resources] An array of parent resources used to construct the API URL for nested resources.
 */

export default function create_api_client(api_path, {
  dummy_data,
  is_public_api = false,
  parser,
  formatter,
  is_data_object,
  parent_resources = [],
} = {}) {

  const parser_fn = parser || ((resource) => resource);

  const copy_and_parse = (resource) => parser_fn(JSON.parse(JSON.stringify(resource)));

  const formatter_fn = formatter || ((resource) => resource);

  const {
    list_url_getter,
    detail_url_getter,
  } = create_api_urls(api_path, {
    is_public_api,
    parent_resources,
  });

  /**
   * @return { Promise<Array | Object> } List data from API.
   */
  async function get_list(payload, {
    params = {},
  } = {}) {
    if (dummy_data) {
      return new Promise((resolve) => {
        resolve(dummy_data);
      });
    }
    return axiosInstance
      .get(list_url_getter(payload), {
        params,
      })
      .then(response => {
        if (is_data_object) {
          return response.data;
        } else {
          return response.data
            .map(copy_and_parse);
        }
      });
  }

  async function update(payload) {

    if (payload.pk) {
      return put(payload);
    }

    return post(payload);
  }

  async function post(payload) {

    let url = list_url_getter(payload);

    return axiosInstance.post(url, formatter_fn(payload))
      .then(response => {
        return copy_and_parse(response.data.data);
      });
  }

  async function put(payload) {

    // We sometimes PUT to a bare endpoint (e.g. in the case of narrative delivery config singleton).
    let url = list_url_getter(payload);

    if (payload.pk) {
      url = detail_url_getter(payload);
    }

    return axiosInstance.put(url, formatter_fn(payload))
      .then(response => {
        return copy_and_parse(response.data.data);
      });
  }

  async function delete_resource(resource) {

    let url = detail_url_getter(resource);

    return axiosInstance.delete(url)
      .then(response => {
        return response.data.data;
      });
  }

  async function patch(payload) {

    let url = detail_url_getter(payload);

    return axiosInstance.patch(url, formatter_fn(payload))
      .then(response => {
        return copy_and_parse(response.data.data);
      });
  }

  function batched_bulk_request(bulk_request_fn, {
    has_empty_response = false,
  } = {}) {

    return async (payloads) => {

      let batches = [];

      for (var i = 0; i < payloads.length; i += TRANSACTION_MAXIMUM_ENTITIES_COUNT) {
        batches.push(payloads.slice(i, i + TRANSACTION_MAXIMUM_ENTITIES_COUNT));
      }

      return Promise.all(batches.map(bulk_request_fn))
        .then((batched_responses) => {

          if (has_empty_response) {
            return [];
          }

          return batched_responses.reduce((flattened_responses, responses) => {
            return [
              ...flattened_responses,
              ...responses,
            ];
          }, []);
        });
    };
  }

  async function bulk_update(payloads, parent_resource_pks) {

    let url = list_url_getter(parent_resource_pks);

    return axiosInstance.patch(url, payloads.map(formatter))
      .then(response => {
        return response.data.data
          .map(copy_and_parse);
      });
  }

  async function bulk_delete(pks, parent_resource_pks) {

    // We currently don't want to delete _all_ entities on an endpoint.
    if (!pks?.length) {
      return;
    }

    let url = list_url_getter(parent_resource_pks);

    return axiosInstance.delete(url, {
      params: {
        pk: pks,
      },
    });
  }

  return {
    get_list,
    update,
    patch,
    put,
    delete_resource,
    bulk_update: batched_bulk_request(bulk_update),
    bulk_delete: batched_bulk_request(bulk_delete, {
      has_empty_response: true,
    }),
  };
}
