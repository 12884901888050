
import {
  initializeApp,
} from 'firebase/app';

import {
  getAuth,
  GoogleAuthProvider,
  connectAuthEmulator,
} from 'firebase/auth';

import {
  getFirestore,
  connectFirestoreEmulator,
  initializeFirestore,
} from 'firebase/firestore';

import {
  config,
} from './config';

const firebaseConfig = {
  projectId: config.firebase.project_id,
  apiKey: config.firebase.api_key,
  authDomain: config.firebase.auth_domain,
  storageBucket: config.firebase.storage_bucket,
  messagingSenderId: config.firebase.messaging_sender_id,
  appId: config.firebase.app_id,
};

const app = initializeApp(firebaseConfig);

// Required to prevent issues with the Firestore emulator whilst running Cypress tests
// See https://github.com/cypress-io/cypress/issues/6350
if (config.firebase.force_long_polling) {
  initializeFirestore(app, {
    experimentalForceLongPolling: true,
  });
}

export const db = getFirestore(app);

export const provider = new GoogleAuthProvider();

export const auth = getAuth(app);

// When running locally, we use the firestore emulator.
if (config.firebase.emulator) {
  connectFirestoreEmulator(db, 'localhost', 8008);
  connectAuthEmulator(auth, 'http://localhost:9099', {
    disableWarnings: true,
  });
}
