import Vue from 'vue';

/** @type {Vue} */
let InternalEventBus;

/**
 * Return a global event bus singleton.
 */
export const get = function() {
  if (!InternalEventBus) {
    InternalEventBus = new Vue();
  }
  return InternalEventBus;
};
