
const state = {

  /**
   * True when all bootstrap data has been loaded (and the app is ready to initialise).
   * @type {boolean}
   */
  bootstrap_data_loaded: false,

  /**
   * Stores the current route error (404/500 etc.).
   * @type {string}
   */
  route_error: '',

  /**
   * Stores the sign in operation promise.
   * @type {Promise | null}
   */
  sign_in_promise: null,

  /**
   * True if the user is signed in.
   * @type {boolean}
   */
  is_signed_in: false,

  /**
   * Stores the ID of the logged in user
   * @type {string}
   */
  user_id: '',

  /**
   * Stores the details of the logged in user
   * @type {MustUser | null}
   */
  user: null,


  /**
   * Stores workspace IDs taken from firebase auth token.
   * @type {{
   *   ws_owner_ids: string[],
   *   ws_editor_ids: string[],
   *   ws_viewer_ids: string[],
   *   all: string[],
   * }}
   */
  user_token_workspace_ids: {
    ws_owner_ids: [],
    ws_editor_ids: [],
    ws_viewer_ids: [],
    all: [],
  },

  /**
   * Stores the app bar height.
   * @type {number}.
   */
  app_bar_height: 0,

  /**
   * Stores the currently selected timeline event id.
   * @type {string}
   */
  current_timeline_event_id: '',

  /**
   * Stores timeline's threads.
   * @type {Object.<string, TimelineLinkedThread>}
   */
  current_timeline_linked_threads: {},

  /**
   * Stores the currently highlighted linked timeline event id.
   * @type {HighlightedLinkedEventIds}
   */
  current_timeline_highlighted_linked_event_ids: {},

  /**
   * Stores the timeline analytics for the current timeline.
   * @type {TimelineAnalytics | null}
   */
  current_timeline_analytics: null,

  /**
   * Stores the currently selected timeline analytics filter.
   * @type {string}
   */
  timeline_analytics_filter: 'COMPLETED',

  /**
   * True if all data required for the current timeline has been loaded.
   * @type {boolean}
   */
  current_timeline_is_ready: false,

  /**
   * True if the timeline in current_timeline is published.
   * @type {boolean | null}
   */
  current_timeline_is_published: null,

  /**
   * A log of all event hooks generated by the simulator.
   * @type {SimulationHook[]}
   */
  simulation_hook_log: [],

  /**
   * All simulator timeline events.
   * @type {Object.<string, SimulationTimelineEvent>}
   */
  simulation_timeline_events: {},

  /**
   * The timeline_event_id of the currently active event in the simulation.
   * @type {string}
   */
  simulation_timeline_event_id: '',

  /**
   * The current step index of the simulator.
   * @type {number | undefined}
   */
  simulation_step_index: undefined,

  /**
   * The current act and beat of the simulator.
   * @type {Object}
   */
  simulation_act_and_beat: {},

  /**
   * The value of any variables in the simulator.
   * @type {SimulationTimelineVariables}
   */
  simulation_variables: {},

  /**
   * A mapping of condition machine ID to booleans representing
   * if the condition machine is blocked. NB only blocked conditions
   * are guarenteed to be present, non-blocked conditions shall either
   * be false or not present.
   *
   * @type {Object.<string, boolean>}
   */
  simulation_blocked_conditions: {},

  /**
   * Indicates if the simulation beat machine has reached its final state.
   * @type {boolean}
   */
  simulation_beat_machine_completed: false,

  /**
   * A mapping of link IDs to booleans representing
   * if a link has been followed when using the
   * simulator.
   *
   * @type {Object.<string, boolean>}
   */
  simulation_links_followed: {},

  /**
   * All the workspaces available for the current user.
   * @type {Object.<string, Workspace>}
   */
  workspaces: {},

  /**
   * The id of the current workspace.
   * @type {string}
   */
  current_workspace_id: '',

  /**
   * The current workspace.
   * @type {Workspace | null}
   */
  current_workspace: null,

  /**
   * The user's role for the current workspace (taken from token custom claims).
   * @type {string | undefined}
   */
  current_workspace_role: undefined,

  /**
   * The user's application role e.g. app_admin (taken from token custom claims).
   * @type {string | undefined}
   */
  current_user_role: undefined,

  /**
   * Members of the current workspace.
   * @type {Object.<string, WorkspaceMembership>}
   */
  workspace_members: {},

  /**
   * All the projects available in the current workspace.
   * @type {Object.<string, Project>}
   */
  projects: {},

  /**
   * ID of the current project.
   * @type {string}
   */
  current_project_id: '',

  /**
   * The current project.
   * @type {Project | null}
   */
  current_project: null,

  /**
   * All the timelines available to the user's currently selected project.
   * @type {Object.<string, SequencedTimeline>}
   */
  project_timelines: {},

  /**
   * All the narrative events available to the user's currently selected project.
   * @type {Object.<string, NarrativeEvent>}
   */
  project_narrative_events: {},

  /**
   * All the contents attached to any narrative event hooks in project_narrative_events.
   * @type {Object.<string, HookContents>}
   */
  project_hook_contents: {},

  /**
   * ID of the currently active timeline.
   * @type {string}
   */
  current_timeline_id: '',

  /**
   * The current timeline being used by the user.
   * @type {(SequencedTimeline | PublishedTimeline | null)}
   */
  current_timeline: null,

  /**
   * The timeline events that belong to the user's current timeline.
   * @type {Object.<string, TimelineEvent>}
   */
  current_timeline_events: {},

  /**
   * When creating an event, we store a local version here while the event is being persisted.
   * If the event is found in state.current_timeline_events then the version here shall be ignored
   * (and eventually removed). The timeline_events stored here never have an associated narrative_event
   * as that can only be created once the timeline event actually exists.
   * @type {Object.<string,TimelineEventBeingCreated>}
   */
  current_timeline_events_being_created: {},

  /**
   * The rows belonging to the current timeline.
   * @type {Object.<string, TimelineRow>}
   */
  current_timeline_rows: {},

  /**
   * The beats belonging to the current timeline.
   * @type {Object.<string, TimelineBeat>}
   */
  current_timeline_beats: {},

  /**
   * Links belonging to the current timeline.
   * @type {Object.<string, TimelineEventLink>}
   */
  current_timeline_event_links: {},

  /**
   * Timeline event references belonging to the current timeline.
   * @type {TimelineEventReference | null}
   */
  current_timeline_event_references: null,

  /**
   * Progression conditions belonging to the current timeline.
   * @type {Object.<string, TimelineProgressionCondition>}
   */
  current_timeline_progression_conditions: {},

  /**
   * Timeline snapshots belonging to the current timeline.
   * @type {Object.<string, TimelineSnapshot>}
   */
   current_sequenced_timeline_snapshots: {},

  /**
   * Progression conditions belonging to the current timeline.
   * @type {Object.<string, TimelinePushEvent>}
   */
  current_timeline_push_events: {},

  /**
   * Current inspector tab selection index
   * @type {number}
   */
  current_timeline_event_tab_index: 0,

  /**
   * All published timelines for the current project.
   * @type {Object.<string, PublishedTimeline>}
   */
  project_published_timelines: {},

  /**
   * All API keys for the project.
   * @type {Object.<string, ProjectApiKeys>}
   */
  project_api_keys: {},

  /**
   * All WebhookSecrets for the project.
   * @type {Object.<string, WebhookSecrets>}
   */
  project_webhook_secrets: {},

  /**
   * All enabled feature flags.
   * @type {Partial<{[k in keyof typeof import('@/services/ui-feature-flags').ui_feature_flags]: boolean}>}
   */
  ui_feature_flags: {},

  /**
   * Should canvas display large timeline event nodes
   * @type {boolean}
   */
  large_timeline_ui: false,

  /**
   * Events to highlight on the timeline canvas.
   * @type {Object.<string, boolean>}
   */
  highlighted_event_ids: {},

  /**
   * Links to highlight on the timeline canvas.
   * @type {Object.<string, boolean>}
   */
  highlighted_link_ids: {},

  /**
   * Left event ports to highlight on canvas.
   * @type {Object.<string, boolean>}
   */
  highlighted_left_ports: {},

  /**
   * Right event ports to highlight on canvas.
   * @type {Object.<string, boolean>}
   */
  highlighted_right_ports: {},

  /**
   * Progression conditions to highlight on the timeline canvas.
   * @type {Object.<string, boolean>}
   */
  highlighted_progression_condition_ids: {},
};

/** @typedef {typeof state} State */

export default state;
