
import {
  addDoc,
  doc,
  setDoc,
  updateDoc,
  writeBatch,
  serverTimestamp,
} from 'firebase/firestore';

import {
  chunk,
} from 'shared-js/chunk';

import {
  validate,
} from 'shared-js/validators';

/**
 * @typedef {Object} FirestoreWriteData
 * @property {Object} document The document that was written to firestore.
 * @property {Promise<Object>} promise A promise that resolves (with the document) when the document has been written to firestore or immediately if inside a transaction.
 */

/**
 * Adds created/modified attributes, validates a document then writes a document to firestore using one of:
 *  - collection_ref.add
 *  - document.set
 *  - document.update
 *  - batch.set
 *  - batch.update
 *  - transaction.set
 *  - transaction.update
 *
 * @param {Object} param0
 * @param {Object} param0.document The document being written.
 * @param {string} param0.user_id The user's ID (used in created_by and modified_by attribues).
 * @param {import("firebase/firestore").DocumentReference} [param0.document_ref] Firestore reference to the document being written to. Used in creting and updating.
 * @param {import("firebase/firestore").CollectionReference} [param0.collection_ref] Firestore reference to the collection, used when creating and not passing in document_ref.
 * @param {boolean} [param0.is_update] true if updating, false if creating.
 * @param {import("firebase/firestore").WriteBatch} [param0.batch] If passed in, writes are performed against the batch operation.
 * @param {import("firebase/firestore").Transaction} [param0.transaction] If passed in, writes are performed against the transaction.
 * @returns {FirestoreWriteData}
 */
function write_document({
  document,
  user_id,
  document_ref = undefined,
  collection_ref = undefined,
  is_update = false,
  batch = undefined,
  transaction = undefined,
}) {

  if (batch && transaction) {
    throw new Error('Cannot set both batch and transaction.');
  }

  if ((batch || transaction) && !document_ref) {
    throw new Error('Must provide a document_ref when using batch or transaction.');
  }

  if (document_ref && collection_ref) {
    throw new Error('Cannot set both document_ref and collection_ref.');
  }

  if (is_update && !document_ref) {
    throw new Error('Cannot update a document without a document_ref');
  }

  let collection_path = collection_ref ? collection_ref.path : /** @type {NonNullable<typeof document_ref>} */(document_ref).parent.path;

  const schema_type = collection_path.split('/').pop();

  /*eslint no-unused-vars: 0*/
  const {
    id,
    ...document_without_id
  } = document;

  const document_with_modified = is_update ? add_modified_attributes(document_without_id, user_id) : add_created_and_modified_attributes(document_without_id, user_id);

  const valid_document = validate(schema_type, document_with_modified, {
    is_create: !is_update,
  });

  if (!transaction && !batch) {

    if (document_ref) {

      let promise;

      if (is_update) {
        promise = updateDoc(document_ref, valid_document);
      } else {
        promise = setDoc(document_ref, valid_document);
      }

      return {
        promise: promise
          .then(() => ({
            id: document_ref.id,
            ...valid_document,
          })),
        document: valid_document,
      };
    } else {
      return {
        promise: addDoc(/** @type {NonNullable<typeof collection_ref>} */(collection_ref), valid_document)
          .then((doc_ref) => ({
            id: doc_ref.id,
            ...valid_document,
          })),
        document: valid_document,
      };
    }
  } else {

    if (transaction) {

      if (is_update) {
        transaction.update(/** @type {NonNullable<typeof document_ref>} */(document_ref), valid_document);
      } else {
        transaction.set(/** @type {NonNullable<typeof document_ref>} */(document_ref), valid_document);
      }

    } else if (batch) {

      if (is_update) {
        batch.update(/** @type {NonNullable<typeof document_ref>} */(document_ref), valid_document);
      } else {
        batch.set(/** @type {NonNullable<typeof document_ref>} */(document_ref), valid_document);
      }
    }

    return {
      document: valid_document,
      promise: Promise.resolve({
        id: document_ref?.id,
        ...valid_document,
      }),
    };
  }
}

/**
 * Adds created/modified attributes, validates a document then writes a partial document to firestore using one of:
 *  - document.update
 *  - batch.update
 *  - transaction.update
 *
 * @param {Object} param0
 * @param {Object} param0.document The document being written.
 * @param {string} param0.user_id The user's ID (used in created_by and modified_by attribues).
 * @param {import("firebase/firestore").DocumentReference} [param0.document_ref] Firestore reference to the document being written to. Used in creting and updating.
 * @param {import("firebase/firestore").WriteBatch} [param0.batch] If passed in, writes are performed against the batch operation.
 * @param {import("firebase/firestore").Transaction} [param0.transaction] If passed in, writes are performed against the transaction.
 * @returns {FirestoreWriteData}
 */
export function update_firestore_document({
  document_ref,
  document,
  batch = undefined,
  transaction = undefined,
  user_id,
}) {
  return write_document({
    document_ref,
    document,
    is_update: true,
    batch,
    transaction,
    user_id,
  });
}

/**
 * Adds created/modified attributes, validates a document then writes a new document to firestore using one of:
 *  - document.set
 *  - document.update
 *  - batch.set
 *  - batch.update
 *  - transaction.set
 *  - transaction.update
 *
 * @param {Object} param0
 * @param {Object} param0.document The document being written.
 * @param {string} param0.user_id The user's ID (used in created_by and modified_by attribues).
 * @param {import("firebase/firestore").DocumentReference} [param0.document_ref] Firestore reference to the document being written to. Used in creting and updating.
 * @param {import("firebase/firestore").WriteBatch} [param0.batch] If passed in, writes are performed against the batch operation.
 * @param {import("firebase/firestore").Transaction} [param0.transaction] If passed in, writes are performed against the transaction.
 * @returns {FirestoreWriteData}
 */
export function create_firestore_document({
  document_ref,
  document,
  batch = undefined,
  transaction = undefined,
  user_id,
}) {
  return write_document({
    document_ref,
    document,
    is_update: false,
    batch,
    transaction,
    user_id,
  });
}

/**
 * Adds created/modified attributes, validates a document then adds a new document to collection_ref using collection_ref.add.
 *
 * @param {Object} param0
 * @param {Object} param0.document The document being written.
 * @param {string} param0.user_id The user's ID (used in created_by and modified_by attribues).
 * @param {import("firebase/firestore").CollectionReference} [param0.collection_ref] Firestore reference to the collection, used when creating and not passing in document_ref.
 * @returns {FirestoreWriteData}
 */
export function add_firestore_document({
  collection_ref,
  document,
  user_id,
}) {
  return write_document({
    collection_ref,
    document,
    user_id,
  });
}

/**
 * @typedef {Object} DocumentWriteData
 * @property {string} path
 * @property {Object} data
 */

/**
 * Enables batch writing of 500+ documents (the firestore batch write limit) by splitting
 * writes into batches of 500 at a time.
 * @param {Object} param0
 * @param {import("firebase/firestore").Firestore} param0.db
 * @param {DocumentWriteData[]} param0.document_write_data
 * @param {string} param0.user_id
 * @param {typeof create_firestore_document | typeof update_firestore_document} [param0.write_method] If true, updates existing firestore documents. Otherwise, creates new firestore documents.
 * @returns {Promise[]}
 */
export function batch_write({
  db,
  document_write_data,
  user_id,
  write_method = create_firestore_document,
}) {

  const document_write_batches = chunk(document_write_data, 500);

  return document_write_batches
    .map((each_batch) => {

      const batch = writeBatch(db);

      each_batch.forEach((each_write) => {
        const document_ref = doc(db, each_write.path);
        write_method({
          document: each_write.data,
          document_ref,
          user_id,
          batch,
        }).promise;
      });

      return batch.commit();
    });
}

export function add_modified_attributes(payload, user_id) {
  return add_modified_timestamp(add_modified_by(payload, user_id));
}

export function add_created_attributes(payload, user_id) {
  return add_created_timestamp(add_created_by(payload, user_id));
}

export function add_created_and_modified_attributes(payload, user_id) {
  return add_modified_attributes(add_created_attributes(payload, user_id), user_id);
}

function add_modified_by(payload, user_id) {
  return {
    ...payload,
    modified_by: user_id,
  };
}

function add_created_by(payload, user_id) {
  return {
    ...payload,
    created_by: user_id,
  };
}

function add_modified_timestamp(payload) {
  return {
    ...payload,
    modified_at: serverTimestamp(),
  };
}

function add_created_timestamp(payload) {
  return {
    ...payload,
    created_at: serverTimestamp(),
  };
}
