<template>
  <section class="server-error" />
</template>

<script>

import {
  defineComponent,
} from 'vue';

import {
  SET_500_ROUTE_ERROR,
} from '@/store/mutation-types';

export default defineComponent({
  name: 'ServerErrorView',
  created() {
    this.$store.commit(SET_500_ROUTE_ERROR);
  },
});
</script>

<style scoped lang="scss">
</style>
