
/**
 * @type {string} Used to create a compound key from 2 (or more) keys.
 */
const COMPOSITE_ID_SEPARATOR = ':';

/**
 * Create a deterministic id between two ids.
 *
 * @param {Object} param0
 * @param {string[]} param0.ids
 * @param {boolean} [param0.sort_alpha]
 * @return {string} The composite ID.
 */
export function create_composite_id({
  ids,
  sort_alpha = false,
}) {

  if (sort_alpha) {
    ids = [
      ...ids,
    ].sort();
  }

  return ids.join(COMPOSITE_ID_SEPARATOR);
}

/**
 * Split a deterministic id to return two ids.
 *
 * @param {Object} param0
 * @param {string} param0.id
 * @return {string[]} The 2 original IDs.
 */
export function split_composite_id({
  id,
}) {
  return id.split(COMPOSITE_ID_SEPARATOR);
}


