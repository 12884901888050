import {
  collection,
  doc,
} from 'firebase/firestore';

import {
  db,
} from '@/services/firebase.js';

import {
  timeline_child_path,
} from './utils.js';

import {
  converters,
} from '../converters/index.js';

export const timeline_beat_refs = {
  document: ({
    is_published,
    workspace_id,
    project_id,
    timeline_id,
    timeline_beat_id,
  }) => {
    const path = timeline_child_path({
      is_published,
      workspace_id,
      project_id,
      timeline_id,
      collection_name: 'timeline_beats',
      document_id: timeline_beat_id,
    });
    return doc(db, path).withConverter(converters.timeline_beats);
  },
  collection: ({
    is_published,
    workspace_id,
    project_id,
    timeline_id,
  }) => {
    const path = timeline_child_path({
      is_published,
      workspace_id,
      project_id,
      timeline_id,
      collection_name: 'timeline_beats',
    });
    return collection(db, path).withConverter(converters.timeline_beats);
  },
};
