import {
  split_composite_id,
} from 'shared-js/create-composite-id';

/**
 * When you have 'event_a' or 'event_b', this helps you find:
 * - if there's a link
 * - the direction of the link
 *
 * Given an array of timeline event link IDs:
 *  ['event_a:event_b', 'event_b:event_c',]
 *
 * returns a mapping of:
 * {
 *   to_event_ids: {
 *     event_a: ['event_b',],
 *     event_b: ['event_c',],
 *   },
 *   from_event_ids: {
 *     event_b: ['event_a',],
 *     event_c: ['event_b',],
 *   },
 * }
 *
 * @param {Object} param0
 * @param {string[]} param0.event_link_ids
 */
export function timeline_events_with_multiple_links({
  event_link_ids,
}) {

  /** @type {Object.<string, string[]>} */
  const to_event_ids = {};

  /** @type {Object.<string, string[]>} */
  const from_event_ids = {};

  event_link_ids
    .forEach((link_id) => {
      const [
        from_event_id,
        to_event_id,
      ] = split_composite_id({
        id: link_id,
      });
      if (!to_event_ids[from_event_id]) {
        to_event_ids[from_event_id] = [];
      }
      to_event_ids[from_event_id].push(to_event_id);
      if (!from_event_ids[to_event_id]) {
        from_event_ids[to_event_id] = [];
      }
      from_event_ids[to_event_id].push(from_event_id);
    });

  return {
    to_event_ids,
    from_event_ids,
  };
}

/**
 * @param {Object} param0
 * @param {string} param0.timeline_event_id
 * @param {string[] | undefined} param0.event_link_ids
 */
export function get_next_linked_events({
  timeline_event_id,
  event_link_ids,
}) {
  if (!event_link_ids) {
    return [];
  }
  const linked_timeline_events = timeline_events_with_multiple_links({
    event_link_ids,
  });
  return linked_timeline_events.to_event_ids[timeline_event_id] || [];
}

/**
 * @param {Object} param0
 * @param {string} param0.timeline_event_id
 * @param {string[] | undefined} param0.event_link_ids
 */
export function get_prev_linked_events({
  timeline_event_id,
  event_link_ids,
}) {
  if (!event_link_ids) {
    return [];
  }
  const linked_timeline_events = timeline_events_with_multiple_links({
    event_link_ids,
  });
  return linked_timeline_events.from_event_ids[timeline_event_id] || [];
}
