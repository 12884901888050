
/**
 * Returns a mapping of {
 *   [event_id]: thread_id,
 * } for all events in threads.
 * @param {Object} param0
 * @param {Record<string, string[]>} [param0.events_in_threads]
 */
export function event_thread_ids({
  events_in_threads = {},
}) {
  return Object.keys(events_in_threads)
    .reduce((acc, thread_id) => {
      events_in_threads[thread_id]
        .forEach((event_id) => acc[event_id] = thread_id);
      return acc;
    }, {});
}
