/**
 * See https://www.notion.so/fictioneers/UI-Feature-Flags-07e6de3a3be646b48fcc1335943b252a
 *
 * This service defines/limits which feature flags are available throughout the app.
 *
 * Enabled feature flags are switched on through query string params (handled in App.vue and exposed through VueX state).
 *
 * Values can be controlled through the URL query string parameter ?featureFlags= and shall persist in state.
 */

// NB Do not import this directly when checking if a feature flag is switched on.
// Instead, you should read these from VueX store's `state.ui_feature_flags` property.

/**
 * @type {Record.<string, {
 *   available_in_url: boolean,
 *   force_on?: boolean,
 *   depends_on?: string,
 * }>}
 */
export const ui_feature_flags = {
  ai_chat: {
    available_in_url: true,
    force_on: false,
  },
  adv_ai_chat: {
    available_in_url: true,
    force_on: false,
  },
  ai_archive: {
    available_in_url: true,
    force_on: false,
  },
};
