
import {
  createGraphFromVerticesEdges,
} from '@/services/timeline-layout';

import {
  timeline_event_status_map,
  variable_rule_operators_map,
} from '@/services/constants';

const variable_changes_operator_map = {
  '=': 'set',
  '+': 'increment',
  '-': 'decrement',
};

/**
 * Converts data returned from OpenAI to a format we can
 * use to create firestore documents.
 * @param {Object} param0
 * @param {OpenAITimelineData} param0.openai_timeline_data
 * @param {string} param0.timeline_id
 */
export function convert_openai_schema_to_timeline({
  openai_timeline_data: {
    vertices,
    edges,
    variables,
  },
  timeline_id,
}) {

  // Re-maps objects to more grokkable properties.
  const timeline_data = {
    vertices: vertices.map(({
      n, d, i,
    }) => ({
      name: n,
      description: d,
      icon: i,
    })),
    edges: edges.map(({
      n, f, t, r, b,
    }) => ({
      intent_name: n,
      from_name: f,
      to_name: t,
      rules: (r || []).map((rule) => ({
        variable_id: rule.v,
        operator: rule.or,
        operand: `${rule.od}`,
      })),
      behaviors: (b || []).map((behaviour) => ({
        variable_id : behaviour.n,
        operator : variable_changes_operator_map[behaviour.o],
        operand : parseInt(`${behaviour.v}`, 10),
      })),
    })),
    variables: variables?.map(({
      n, v,
    }) => ({
      name: n,
      defaultValue: v,
    })) || [],
  };

  const variables_docs = timeline_data.variables.map(({
    name,
    defaultValue,
  }) => ({
    name,
    default: defaultValue,
    type: 'int',
    scope: 'user',
  }));

  const graph = createGraphFromVerticesEdges(timeline_data);

  const event_docs = graph.nodes.map(({
    source,
    dims,
  }) => {

    const narrative_event = {
      title: `${source.name}`,
      description: `${source.description}`,
      custom_data: [],
      icon: source.icon,
    };

    const timeline_event = {
      status: timeline_event_status_map.ENABLED,
      timeline_id,
      is_locked: false,
    };

    const step_index = Math.ceil(dims.x - 1);

    const row_index = Math.ceil(dims.y - 1);

    return {
      timeline_event,
      narrative_event,
      step_index,
      row_index,
    };
  });

  const link_docs = timeline_data.edges.map((edge) => {

    /** @type {string[]} */
    const rules_order = [];

    /** @type { Record<string, RuleFields> }*/
    const rules = {};

    edge.rules.forEach((rule, index) => {

      const rule_id = `${index}`;
      rules_order.push(rule_id);

      rules[rule_id] = {
        operator: variable_rule_operators_map.VARIABLE_PASSES_TEST,
        variable_rules: [
          rule,
        ],
      };
    });

    return {
      from_event_name: edge.from_name,
      to_event_name: edge.to_name,
      link: {
        intent_name: edge.intent_name || '',
        variable_changes:  edge.behaviors,
        rules,
        rules_and: false,
        rules_order,
      },
    };
  });

  return {
    variables: variables_docs,
    events: event_docs,
    links: link_docs,
  };

}

/**
 * Converts timeline data stored in VueX state to a format we can
 * send to OpenAI.
 * @param {Object} param0
 * @param {Object.<string, NarrativeEvent>} param0.narrative_events
 * @param {Object.<string, TimelineEvent>} param0.timeline_events
 * @param {Object.<string, TimelineEventLink>} param0.timeline_event_links
 * @param {TimelineVariables} [param0.timeline_variables]
 */
export function convert_timeline_to_openai_schema({
  narrative_events,
  timeline_events,
  timeline_event_links,
  timeline_variables = {},
}) {

  const vertices = Object.values(timeline_events)
    .map((evt) => {
      return {
        n: narrative_events[evt.narrative_event_id].title || '',
        d: narrative_events[evt.narrative_event_id].description || '',
        i: narrative_events[evt.narrative_event_id].icon || '',
      };
    });

  const edges = Object.values(timeline_event_links)
    .map((link) => {
      const from_te = timeline_events[link.from_event_id];
      const to_te = timeline_events[link.to_event_id];
      return {
        n: link.intent_name || '',
        f: narrative_events[from_te.narrative_event_id].title || '',
        t: narrative_events[to_te.narrative_event_id].title || '',
        r: Object.values(link.rules || {}).map((rule) => ({
          v: rule.variable_rules?.[0].variable_id,
          or: rule.variable_rules?.[0].operator,
          od: rule.variable_rules?.[0].operand,
        })),
        b: Object.values(link.variable_changes || {}).map((vc) => ({
          n: vc.variable_id,
          o: vc.operator,
          v: vc.operand,
        })),
      };
    });

  const variables = Object.values(timeline_variables || {})
    .map((variable) => {
      return {
        n: variable.name,
        v: variable.default,
      };
    });

  return {
    vertices,
    edges,
    variables,
  };
}
