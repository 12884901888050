<template>
  <div class="home flex-grow-1">
    <div class="d-flex mb-3 ml-3 mt-8">
      <h1 class="text-h1">
        Your projects
      </h1>
      <v-spacer />
      <v-btn
        :disabled="!$store.getters.user_can_edit"
        color="primary"
        :to="{
          name: 'CreateProject',
        }"
        data-test-id="create-project">
        Start a new project
      </v-btn>
    </div>
    <ProjectList />
  </div>
</template>

<script>

import {
  defineComponent,
} from 'vue';

import ProjectList from '@/components/ProjectList.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    ProjectList,
  },
});
</script>
