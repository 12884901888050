import Vue from 'vue';

import {
  firestore_bind_mutations,
} from 'shared-js/firestore-vuex-bindings';

import {
  member_roles_map,
} from '@/services/constants';

import {
  ADD_SIMULATION_HOOK,
  BOOTSTRAP_DATA_LOADED,
  CLEAR_ALL_SIMULATION_BLOCKED_CONDITIONS,
  CLEAR_CURRENT_PROJECT_ID,
  CLEAR_CURRENT_TIMELINE_ANALYTICS,
  CLEAR_CURRENT_TIMELINE_EVENTS_BEING_CREATED,
  CLEAR_CURRENT_TIMELINE_EVENT_BEING_CREATED,
  CLEAR_CURRENT_TIMELINE_EVENT_ID,
  CLEAR_CURRENT_TIMELINE_HIGHLIGHTED_LINKED_EVENT_IDS,
  CLEAR_CURRENT_TIMELINE_IS_READY,
  CLEAR_ROUTE_ERROR,
  CLEAR_SIGN_IN_PROMISE,
  CLEAR_SIMULATION_ACT_AND_BEAT,
  CLEAR_SIMULATION_DATA,
  CLEAR_SIMULATION_TIMELINE_EVENT_ID,
  SET_404_ROUTE_ERROR,
  SET_500_ROUTE_ERROR,
  SET_APP_BAR_HEIGHT,
  SET_CURRENT_PROJECT_ID,
  SET_CURRENT_TIMELINE_ANALYTICS,
  SET_CURRENT_TIMELINE_EVENT_BEING_CREATED,
  SET_CURRENT_TIMELINE_EVENT_ID,
  SET_CURRENT_TIMELINE_EVENT_TAB_INDEX,
  SET_CURRENT_TIMELINE_HIGHLIGHTED_LINKED_EVENT_IDS,
  SET_CURRENT_TIMELINE_ID,
  SET_CURRENT_TIMELINE_IS_PUBLISHED,
  SET_CURRENT_TIMELINE_IS_READY,
  SET_CURRENT_USER_ROLE,
  SET_CURRENT_WORKSPACE_ID,
  SET_CURRENT_WORKSPACE_ROLE,
  SET_FEATURE_FLAG,
  SET_HIGHLIGHTED_EVENT_IDS,
  SET_HIGHLIGHTED_LINK_IDS,
  SET_HIGHLIGHTED_PROGRESSION_CONDITION_IDS,
  SET_LARGE_TIMELINE_UI,
  SET_SIGNED_IN,
  SET_SIGN_IN_PROMISE,
  SET_SIMULATION_ACT_AND_BEAT,
  SET_SIMULATION_BEAT_MACHINE_COMPLETED,
  SET_SIMULATION_BLOCKED_CONDITION,
  SET_SIMULATION_LINK_FOLLOWED,
  SET_SIMULATION_STEP_INDEX,
  SET_SIMULATION_TIMELINE_EVENTS,
  SET_SIMULATION_TIMELINE_EVENT_ID,
  SET_SIMULATION_VARIABLES,
  SET_TIMELINE_ANALYTICS_FILTER,
  SET_USER_ID,
  SET_USER_TOKEN_WORKSPACE_IDS,
  UPDATE_SIMULATION_TIMELINE_EVENT,
} from '@/store/mutation-types';


/**
 * @typedef {import('@/store/types').StoreState} State
 */

const mutations = {

  ...firestore_bind_mutations,

  /**
   * Sets a 404 routing error.
   *
   * @param {State} state State instance.
   */
  [CLEAR_ROUTE_ERROR]: (state) => {
    Vue.set(state, 'route_error', '');
  },

  /**
   * Sets a 404 routing error.
   *
   * @param {State} state State instance.
   */
  [SET_404_ROUTE_ERROR]: (state) => {
    Vue.set(state, 'route_error', '404');
  },

  /**
   * Sets a 403 routing error.
   *
   * @param {State} state State instance.
   */
  [SET_500_ROUTE_ERROR]: (state) => {
    Vue.set(state, 'route_error', '500');
  },

  /**
   * @param {State} state State instance.
   * @param {number} new_height
   */
  [SET_APP_BAR_HEIGHT]: (state, new_height) => {
    Vue.set(state, 'app_bar_height', new_height);
  },

  /**
   * @param {State} state State instance.
   * @param {string} event_id
   */
  [SET_CURRENT_TIMELINE_EVENT_ID]: (state, event_id) => {
    Vue.set(state, 'current_timeline_event_id', event_id);
  },

  /**
   * @param {State} state State instance.
   * @param {boolean} user_is_signed_in
   */
  [SET_SIGNED_IN]: (state, user_is_signed_in) => {
    Vue.set(state, 'is_signed_in', user_is_signed_in);
  },

  /**
   * @param {State} state State instance.
   * @param {string} user_id
   */
  [SET_USER_ID]: (state, user_id) => {
    Vue.set(state, 'user_id', user_id);
  },

  /**
   * @param {State} state State instance.
   * @param {{ws_owner_ids: string, ws_editor_ids: string, ws_viewer_ids: string,}} workspace_ids
   */
  [SET_USER_TOKEN_WORKSPACE_IDS]: (state, {
    ws_owner_ids = '',
    ws_editor_ids = '',
    ws_viewer_ids = '',
  }) => {

    const owner_ids = ws_owner_ids ? ws_owner_ids.split(',') : [];
    const editor_ids = ws_editor_ids ? ws_editor_ids.split(',') : [];
    const viewer_ids = ws_viewer_ids ? ws_viewer_ids.split(',') : [];

    Vue.set(state, 'user_token_workspace_ids', {
      all: [
        ...owner_ids,
        ...editor_ids,
        ...viewer_ids,
      ],
      ws_owner_ids: owner_ids,
      ws_editor_ids: editor_ids,
      ws_viewer_ids: viewer_ids,
    });
  },

  /**
   * @param {State} state State instance.
   * @param {Promise} promise
   */
  [SET_SIGN_IN_PROMISE]: (state, promise) => {
    Vue.set(state, 'sign_in_promise', promise);
  },

  /**
   * @param {State} state State instance.
   */
  [CLEAR_SIGN_IN_PROMISE]: (state) => {
    Vue.set(state, 'sign_in_promise', null);
  },

  /**
   * @param {State} state State instance.
   */
  [BOOTSTRAP_DATA_LOADED]: (state) => {
    Vue.set(state, 'bootstrap_data_loaded', true);
  },

  /**
   * @param {State} state State instance.
   */
  [CLEAR_CURRENT_TIMELINE_EVENT_ID]: (state) => {
    Vue.set(state, 'current_timeline_event_id', '');
  },

  /**
   * @param {State} state State instance.
   */
  [CLEAR_CURRENT_TIMELINE_HIGHLIGHTED_LINKED_EVENT_IDS]: (state) => {
    Vue.set(state, 'current_timeline_highlighted_linked_event_ids', {});
  },

  /**
   * @param {State} state State instance.
   * @param {HighlightedLinkedEventIds} event_ids_obj
   */
  [SET_CURRENT_TIMELINE_HIGHLIGHTED_LINKED_EVENT_IDS]: (state, event_ids_obj) => {
    Vue.set(state, 'current_timeline_highlighted_linked_event_ids', event_ids_obj);
  },

  /**
   * @param {State} state State instance.
   */
  [CLEAR_CURRENT_TIMELINE_IS_READY]: (state) => {
    Vue.set(state, 'current_timeline_is_ready', false);
    Vue.set(state, 'current_timeline_is_published', null);
  },

  /**
   * @param {State} state State instance.
   */
  [SET_CURRENT_TIMELINE_IS_READY]: (state) => {
    Vue.set(state, 'current_timeline_is_ready', true);
  },

  /**
   * @param {State} state State instance.
   */
  [SET_SIMULATION_ACT_AND_BEAT]: (state, {
    act,
    beat,
  }) => {
    Vue.set(state, 'simulation_act_and_beat', {
      act,
      beat,
    });
  },

  /**
   * @param {State} state State instance.
   */
  [CLEAR_SIMULATION_ACT_AND_BEAT]: (state) => {
    Vue.set(state, 'simulation_act_and_beat', {
      act: undefined,
      beat: undefined,
    });
  },

  /**
   * @param {State} state State instance.
   * @param {boolean} is_completed
   */
  [SET_SIMULATION_BEAT_MACHINE_COMPLETED]: (state, is_completed) => {
    Vue.set(state, 'simulation_beat_machine_completed', is_completed);
  },

  /**
   * @param {State} state State instance.
   * @param {number} index
   */
  [SET_SIMULATION_STEP_INDEX]: (state, index) => {
    Vue.set(state, 'simulation_step_index', index);
  },

  /**
   * @param {State} state State instance.
   */
  [CLEAR_SIMULATION_TIMELINE_EVENT_ID]: (state) => {
    Vue.set(state, 'simulation_timeline_event_id', '');
  },

  /**
   * @param {State} state State instance.
   * @param {string} event_id
   */
  [SET_SIMULATION_TIMELINE_EVENT_ID]: (state, event_id) => {
    Vue.set(state, 'simulation_timeline_event_id', event_id);
  },

  /**
   * @param {State} state State instance.
   * @param {Object} param1
   * @param {string} param1.narrative_event_id
   * @param {string} param1.hook
   * @param {string} param1.timeline_event_id
   * @param {number} param1.step_index
   */
  [ADD_SIMULATION_HOOK]: (state, {
    narrative_event_id,
    hook,
    timeline_event_id,
    step_index,
  }) => {

    const narrative_event = state.project_narrative_events[narrative_event_id];

    if (!narrative_event) {
      throw new Error(`No narrative event found for id ${narrative_event_id}`);
    }

    // Get the content attached to this event and hook
    const hook_contents = Object.values(state.project_hook_contents)
      .filter((h) => h.narrative_event_id === narrative_event_id && h.hook === hook);

    const log_entry = {
      narrative_event_id,
      timeline_event_id,
      hook,
      narrative_event_icon: narrative_event.icon || '',
      narrative_event_title: narrative_event.title || '',
      narrative_event_description: narrative_event.description || '',
      narrative_event_contents: hook_contents,
      step_index,
    };

    state.simulation_hook_log.push(log_entry);
  },

  /**
   * @param {State} state State instance.
   * @param {Object.<string, SimulationTimelineEvent>} [timeline_events]
   */
  [SET_SIMULATION_TIMELINE_EVENTS]: (state, timeline_events = {}) => {
    Vue.set(state, 'simulation_timeline_events', timeline_events);
  },

  /**
   * @param {State} state State instance.
   * @param {Object} param1
   * @param {string} param1.timeline_event_id
   * @param {string} param1.state
   */
  [UPDATE_SIMULATION_TIMELINE_EVENT]: (state, {
    timeline_event_id,
    state: timeline_event_state,
  }) => {

    if (!state.simulation_timeline_events[timeline_event_id]) {
      throw new Error(`No simulation_timeline_event found for id ${timeline_event_id}`);
    }

    Vue.set(state.simulation_timeline_events[timeline_event_id], 'state', timeline_event_state);
  },

  [SET_SIMULATION_LINK_FOLLOWED]: (state, {
    link_id,
  }) => {
    Vue.set(state.simulation_links_followed, link_id, true);
  },

  /**
   * @param {State} state State instance.
   * @param {Object} param1
   * @param {boolean} [param1.keep_variables]
   */
  [CLEAR_SIMULATION_DATA]: (state, {
    keep_variables = false,
  }) => {

    Vue.set(state, 'simulation_timeline_events', {});

    Vue.set(state, 'simulation_hook_log', []);

    Vue.set(state, 'simulation_step_index', undefined);

    if (!keep_variables) {
      Vue.set(state, 'simulation_variables', {});
    }

    Vue.set(state, 'simulation_blocked_conditions', {});

    Vue.set(state, 'simulation_links_followed', {});
  },

  /**
   * @param {State} state State instance.
   * @param {string} id
   */
  [SET_CURRENT_WORKSPACE_ID]: (state, id) => {
    Vue.set(state, 'current_workspace_id', id);
  },

  /**
   * @param {State} state State instance.
   */
  [CLEAR_CURRENT_PROJECT_ID]: (state) => {
    Vue.set(state, 'current_project_id', '');
  },

  /**
   * @param {State} state State instance.
   * @param {string} id
   */
  [SET_CURRENT_PROJECT_ID]: (state, id) => {
    Vue.set(state, 'current_project_id', id);
  },

  /**
   * @param {State} state State instance.
   * @param {string} id
   */
  [SET_CURRENT_TIMELINE_ID]: (state, id) => {
    Vue.set(state, 'current_timeline_id', id);
  },

  /**
   * @param {State} state State instance.
   * @param {boolean} is_published
   */
  [SET_CURRENT_TIMELINE_IS_PUBLISHED]: (state, is_published) => {
    Vue.set(state, 'current_timeline_is_published', is_published);
  },

  /**
   * @param {State} state State instance.
   * @param {Object} param1
   * @param {string} param1.id
   */
  [CLEAR_CURRENT_TIMELINE_EVENT_BEING_CREATED]: (state, {
    id,
  }) => {
    Vue.delete(state.current_timeline_events_being_created, id);
  },

  /**
   * @param {State} state State instance.
   */
  [CLEAR_CURRENT_TIMELINE_EVENTS_BEING_CREATED]: (state) => {
    Vue.set(state, 'current_timeline_events_being_created', {});
  },

  /**
   * @param {State} state State instance.
   * @param {TimelineEventBeingCreated} new_event
   */
  [SET_CURRENT_TIMELINE_EVENT_BEING_CREATED]: (state, new_event) => {
    Vue.set(state.current_timeline_events_being_created, new_event.id, new_event);
  },

  /**
   * @param {State} state State instance.
   * @param {TimelineAnalytics} analytics
   */
  [SET_CURRENT_TIMELINE_ANALYTICS]: (state, analytics) => {
    Vue.set(state, 'current_timeline_analytics', analytics);
  },

  /**
   * @param {State} state State instance.
   */
  [CLEAR_CURRENT_TIMELINE_ANALYTICS]: (state) => {
    Vue.set(state, 'current_timeline_analytics', null);
  },

  /**
   * @param {State} state State instance.
   * @param {string} filter
   */
  [SET_TIMELINE_ANALYTICS_FILTER]: (state, filter) => {
    Vue.set(state, 'timeline_analytics_filter', filter);
  },

  /**
   * @param {State} state State instance.
   * @param {number} index
   */
  [SET_CURRENT_TIMELINE_EVENT_TAB_INDEX]: (state, index) => {
    Vue.set(state, 'current_timeline_event_tab_index', index);
  },

  /**
   * @param {State} state State instance.
   * @param {string} feature_flag
   */
  [SET_FEATURE_FLAG]: (state, feature_flag) => {
    Vue.set(state.ui_feature_flags, feature_flag, true);
  },

  /**
   * @param {State} state State instance.
   * @param {boolean} large_timeline_ui
   */
  [SET_LARGE_TIMELINE_UI]: (state, large_timeline_ui) => {
    Vue.set(state, 'large_timeline_ui', large_timeline_ui);
  },

  [SET_SIMULATION_VARIABLES]: (state, payload) => {
    Vue.set(state, 'simulation_variables', {
      ...state.simulation_variables,
      ...payload,
    });
  },

  /**
   * @param {State} state State instance.
   * @param {Object} param1
   * @param {string} param1.condition_id
   * @param {boolean} param1.is_blocked
   */
  [SET_SIMULATION_BLOCKED_CONDITION]: (state, {
    condition_id,
    is_blocked,
  }) => {
    Vue.set(state.simulation_blocked_conditions, condition_id, is_blocked);
  },

  [CLEAR_ALL_SIMULATION_BLOCKED_CONDITIONS]: (state) => {
    Vue.set(state, 'simulation_blocked_conditions', {});
  },

  /**
   * @param {State} state State instance.
   * @param {Object} param1
   * @param {string[]} param1.event_ids
   */
  [SET_HIGHLIGHTED_EVENT_IDS]: (state, {
    event_ids,
  }) => {
    Vue.set(state, 'highlighted_event_ids', event_ids.reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {}));
  },

  /**
   * @param {State} state State instance.
   * @param {Object} param1
   * @param {string[]} param1.link_ids
   */
  [SET_HIGHLIGHTED_LINK_IDS]: (state, {
    link_ids,
  }) => {
    Vue.set(state, 'highlighted_link_ids', link_ids.reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {}));

    Vue.set(state, 'highlighted_left_ports', link_ids.reduce((acc, curr) => {
      acc[curr.split(':')[1]] = true;
      return acc;
    }, {}));

    Vue.set(state, 'highlighted_right_ports', link_ids.reduce((acc, curr) => {
      acc[curr.split(':')[0]] = true;
      return acc;
    }, {}));
  },

  /**
   * @param {State} state State instance.
   * @param {Object} param1
   * @param {string[]} param1.condition_ids
   */
  [SET_HIGHLIGHTED_PROGRESSION_CONDITION_IDS]: (state, {
    condition_ids,
  }) => {
    Vue.set(state, 'highlighted_progression_condition_ids', condition_ids.reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {}));
  },

  /**
   * @param {State} state State instance.
   * @param {string | undefined} user_role User role.
   */
  [SET_CURRENT_USER_ROLE]: (state, user_role) => {
    Vue.set(state, 'current_user_role', user_role);
  },

  /**
   * @param {State} state State instance.
   */
  [SET_CURRENT_WORKSPACE_ROLE]: (state) => {
    let role;
    if (!state.current_workspace_id) {
      role = undefined;
    } else if (state.user_token_workspace_ids.ws_viewer_ids.includes(state.current_workspace_id)) {
      role = member_roles_map.VIEWER;
    } else if (state.user_token_workspace_ids.ws_editor_ids.includes(state.current_workspace_id)) {
      role = member_roles_map.EDITOR;
    } else if (state.user_token_workspace_ids.ws_owner_ids.includes(state.current_workspace_id)) {
      role = member_roles_map.OWNER;
    } else {
      role = undefined;
    }
    Vue.set(state, 'current_workspace_role', role);
  },
};

/** @typedef {typeof mutations} Mutations */

export default mutations;
