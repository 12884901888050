import axiosInstance from './axios';
import {
  API_ROOT,
} from './api-paths';

/**
 *
 * @param {Object} param0
 * @param {string} param0.workspace_id
 * @param {string} param0.project_id
 */
export function project_created({
  workspace_id,
  project_id,
}) {
  const URL = `${API_ROOT}workspaces/${workspace_id}/projects/${project_id}/project-created/`;
  return axiosInstance.post(URL);
}
