
import {
  batch_write,
} from '@/services/firestore_utils';

/**
 * Writes timeline data to firestore in as few batch.commit operations as possible.
 * @param {Object} param0
 * @param {import('firebase/firestore').Firestore} param0.db
 * @param {import("firebase/firestore").DocumentReference} param0.destination_timeline_ref
 * @param {SequencedTimeline | PublishedTimeline} param0.timeline_data
 * @param {TimelineEventReference} param0.timeline_event_references_data
 * @param {TimelineChildCollections} param0.child_collections
 * @param {Object.<string, NarrativeStatecharts>} [param0.narrative_statecharts_by_id]
 * @param {Object.<string, NarrativeStatecharts>} [param0.condition_statecharts_by_id]
 * @param {Object.<string, NarrativeEvent>} [param0.narrative_events_by_id]
 * @param {Object.<string, HookContents>} [param0.hook_contents_by_id]
 * @param {string} param0.user_id
 * @returns {Promise<any[]>} Returns a promise that resolves on success and throws if the transaction could not succeed.
 */
export function write_timeline_to_firestore({
  db,
  destination_timeline_ref,
  timeline_data,
  timeline_event_references_data,
  child_collections,
  narrative_statecharts_by_id = {},
  condition_statecharts_by_id = {},
  narrative_events_by_id = {},
  hook_contents_by_id = {},
  user_id,
}) {

  const destination_timeline_path = destination_timeline_ref.path;

  /**
   * For each eventual write operation, we store the data to write and reference to write to.
   * This allows us to batch-write max of 500 documents at a time (a firestore limit https://cloud.google.com/firestore/quotas)
   * inside multiple batch.commit calls.
   * @type {import('@/services/firestore_utils').DocumentWriteData[]}
   */
  const document_write_data = [
    ...timeline_writes({
      destination_timeline_path,
      timeline_data,
      timeline_event_references_data,
    }),
    ...collection_writes({
      destination_timeline_path,
      child_collections,
    }),
    ...narrative_statechart_writes({
      destination_timeline_path,
      narrative_statecharts_by_id,
    }),
    ...condition_statechart_writes({
      destination_timeline_path,
      condition_statecharts_by_id,
    }),
    ...narrative_events_writes({
      destination_timeline_path,
      narrative_events_by_id,
    }),
    ...hook_contents_writes({
      destination_timeline_path,
      hook_contents_by_id,
    }),
  ];

  return Promise.all(batch_write({
    db,
    document_write_data,
    user_id,
  }));
}

/**
 * @param {Object} param0
 * @param {string} param0.destination_timeline_path
 * @param {SequencedTimeline | PublishedTimeline} param0.timeline_data
 * @param {TimelineEventReference} param0.timeline_event_references_data
 * @returns {import('@/services/firestore_utils').DocumentWriteData[]}
 */
function timeline_writes({
  destination_timeline_path,
  timeline_data,
  timeline_event_references_data,
}) {

  const timeline_id = destination_timeline_path.split('/').pop();

  const new_timeline_event_references_path = [
    destination_timeline_path,
    'timeline_event_references',
    timeline_id,
  ].join('/');

  return [
    {
      path: destination_timeline_path,
      data: timeline_data,
    },
    {
      path: new_timeline_event_references_path,
      data: timeline_event_references_data,
    },
  ];
}

/**
 * @param {Object} param0
 * @param {string} param0.destination_timeline_path
 * @param {TimelineChildCollections} param0.child_collections
 * @returns {import('@/services/firestore_utils').DocumentWriteData[]}
 */
function collection_writes({
  destination_timeline_path,
  child_collections,
}) {

  const writes = [];
  const timeline_id = destination_timeline_path.split('/').pop();

  Object.keys(child_collections).forEach((collection_name) => {

    child_collections[collection_name]
      .forEach((data) => {
        writes.push({
          path: [
            destination_timeline_path,
            collection_name,
            data.id,
          ].join('/'),
          data: {
            ...data,
            timeline_id,
          },
        });
      });
  });

  return writes;
}

/**
 * @param {Object} param0
 * @param {string} param0.destination_timeline_path
 * @param {Object.<string, NarrativeEvent>} param0.narrative_events_by_id
 * @returns {import('@/services/firestore_utils').DocumentWriteData[]}
 */
function narrative_events_writes({
  destination_timeline_path,
  narrative_events_by_id,
}) {

  return Object.keys(narrative_events_by_id).map((narrative_event_id) => {

    return {
      path: [
        destination_timeline_path,
        'narrative_events',
        narrative_event_id,
      ].join('/'),
      data: narrative_events_by_id[narrative_event_id],
    };

  });
}

/**
 * @param {Object} param0
 * @param {string} param0.destination_timeline_path
 * @param {Object.<string, HookContents>} param0.hook_contents_by_id
 * @returns {import('@/services/firestore_utils').DocumentWriteData[]}
 */
function hook_contents_writes({
  destination_timeline_path,
  hook_contents_by_id,
}) {
  return Object.keys(hook_contents_by_id).map((hook_content_id) => {
    return {
      path: [
        destination_timeline_path,
        'hook_contents',
        hook_content_id,
      ].join('/'),
      data: hook_contents_by_id[hook_content_id],
    };
  });
}

/**
 * @param {Object} param0
 * @param {string} param0.destination_timeline_path
 * @param {Object.<string, NarrativeStatecharts>} param0.narrative_statecharts_by_id
 * @returns {import('@/services/firestore_utils').DocumentWriteData[]}
 */
function narrative_statechart_writes({
  destination_timeline_path,
  narrative_statecharts_by_id,
}) {
  const narrative_statecharts_collection_path = [
    destination_timeline_path,
    'narrative_statecharts',
  ].join('/');

  return Object.keys(narrative_statecharts_by_id).map((statechart_id) => {

    const path = [
      narrative_statecharts_collection_path,
      statechart_id,
    ].join('/');

    return {
      path,
      data: {
        sismic_yaml: narrative_statecharts_by_id[statechart_id].sismic_yaml_string,
        title: narrative_statecharts_by_id[statechart_id].title,
      },
    };

  });
}

/**
 * @param {Object} param0
 * @param {string} param0.destination_timeline_path
 * @param {Object.<string, NarrativeStatecharts>} param0.condition_statecharts_by_id
 * @returns {import('@/services/firestore_utils').DocumentWriteData[]}
 */
function condition_statechart_writes({
  destination_timeline_path,
  condition_statecharts_by_id,
}) {

  const condition_statecharts_collection_path = [
    destination_timeline_path,
    'condition_statecharts',
  ].join('/');

  return Object.keys(condition_statecharts_by_id).map((condition_id) => {

    const path = [
      condition_statecharts_collection_path,
      condition_id,
    ].join('/');

    return {
      path,
      data: {
        sismic_yaml: condition_statecharts_by_id[condition_id].sismic_yaml_string,
        title: condition_statecharts_by_id[condition_id].title,
      },
    };

  });
}
