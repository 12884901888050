import {
  activity_states as shared_activity_states,
  activity_states_map as shared_activity_states_map,
  all_lifecycle_hooks as shared_all_lifecycle_hooks,
  all_lifecycle_hooks_map as shared_all_lifecycle_hooks_map,
  date_input_format as shared_date_input_format,
  invitee_status as shared_invitee_status,
  invitee_status_map as shared_invitee_status_map,
  member_role_hints as shared_member_role_hints,
  member_roles as shared_member_roles,
  member_roles_map as shared_member_roles_map,
  project_api_keys_type as shared_project_api_keys_type,
  project_api_keys_type_map as shared_project_api_keys_type_map,
  time_rule_operators as shared_time_rule_operators,
  time_rule_operators_map as shared_time_rule_operators_map,
  timeline_event_reference_directions as shared_timeline_event_reference_directions,
  timeline_event_reference_directions_map as shared_timeline_event_reference_directions_map,
  timeline_event_status as shared_timeline_event_status,
  timeline_event_status_map as shared_timeline_event_status_map,
  timeline_publish_sync_status as shared_timeline_publish_sync_status,
  timeline_publish_sync_status_map as shared_timeline_publish_sync_status_map,
  timeline_status as shared_timeline_status,
  timeline_status_map as shared_timeline_status_map,
  user_roles as shared_user_roles,
  user_roles_map as shared_user_roles_map,
  var_operator_to_text_map as shared_var_operator_to_text_map,
  var_operators as shared_var_operators,
  variable_based_time_rule_operators as shared_variable_based_time_rule_operators,
  variable_based_time_rule_operators_map as shared_variable_based_time_rule_operators_map,
  variable_change_operators as shared_variable_change_operators,
  variable_change_operators_map as shared_variable_change_operators_map,
  variable_rule_operators_map as shared_variable_rule_operators_map,
} from 'shared-js/constants/index.js';

/**
 * This service contains values used in both browser and server environments.
 *
 * `content-types.js` re-exports this data in a more browser-friendly format.
 */

export const activity_states = shared_activity_states;
export const activity_states_map = shared_activity_states_map;
export const all_lifecycle_hooks = shared_all_lifecycle_hooks;
export const all_lifecycle_hooks_map = shared_all_lifecycle_hooks_map;
export const date_input_format = shared_date_input_format;
export const invitee_status = shared_invitee_status;
export const invitee_status_map = shared_invitee_status_map;
export const member_role_hints = shared_member_role_hints;
export const member_roles = shared_member_roles;
export const member_roles_map = shared_member_roles_map;
export const project_api_keys_type = shared_project_api_keys_type;
export const project_api_keys_type_map = shared_project_api_keys_type_map;
export const time_rule_operators = shared_time_rule_operators;
export const time_rule_operators_map = shared_time_rule_operators_map;
export const timeline_event_reference_directions = shared_timeline_event_reference_directions;
export const timeline_event_reference_directions_map = shared_timeline_event_reference_directions_map;
export const timeline_event_status = shared_timeline_event_status;
export const timeline_event_status_map = shared_timeline_event_status_map;
export const timeline_publish_sync_status = shared_timeline_publish_sync_status;
export const timeline_publish_sync_status_map = shared_timeline_publish_sync_status_map;
export const timeline_status = shared_timeline_status;
export const timeline_status_map = shared_timeline_status_map;
export const user_roles = shared_user_roles;
export const user_roles_map = shared_user_roles_map;
export const var_operator_to_text_map = shared_var_operator_to_text_map;
export const var_operators = shared_var_operators;
export const variable_based_time_rule_operators = shared_variable_based_time_rule_operators;
export const variable_based_time_rule_operators_map = shared_variable_based_time_rule_operators_map;
export const variable_change_operators = shared_variable_change_operators;
export const variable_change_operators_map = shared_variable_change_operators_map;
export const variable_rule_operators_map = shared_variable_rule_operators_map;

export const support_email = 'support@fictioneers.com';

export const support_knowledge_base_link = 'https://support.fictioneers.com/help';

export const support_knowledge_base_events_link = 'https://support.fictioneers.com/help/events';

export const timeline_entities_map = {
  'event': 'event',
  'beat': 'beat',
  'progression_condition': 'progression_condition',
};

export const timeline_entities = Object.values(timeline_entities_map);

export const app_bar_layouts_map = {
  TIMELINE: 'TIMELINE',
};

export const app_bar_layouts = Object.values(app_bar_layouts_map);

export const inspector_tab_labels = [
  {
    id: 'details',
    name: 'Details',
  },
  {
    id: 'content',
    name: 'Content',
  },
  {
    id: 'links',
    name: 'Links',
  },
  {
    id: 'variables',
    name: 'Variables',
  },
];

export const visited_states_map = {
  COMPLETED: 'VISITED',
  AVAILABLE: 'ACTIVE',
  INITIAL: 'UNVISITED',
};
