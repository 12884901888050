import create_api_client from '@/services/api/api-client-factory';

/**
 * @param {string} workspace_id
 * @param {string} project_id
 * @param {string} provider
 * @param {string} q
 */
export async function content_search(
  workspace_id,
  project_id,
  provider,
  q
) {

  /** @type {ContentSearchResult[]} */
  let results = [];
  let error_message;

  const by_search = create_api_client(`workspaces/${workspace_id}/projects/${project_id}/content-integrations/by-search`);

  try {
    results = await by_search.get_list(null, {
      params: {
        provider,
        query: q,
      },
    });
  } catch (err) {
    error_message = handle_api_error(err);
  }

  return {
    results,
    error_message,
  };
}

function handle_api_error(err) {
  const error_messages_by_status = {
    401: 'Authentication failed, please authenticate.',
    403: 'Authentication failed, please authenticate.',
    404: 'Service not found, please try again.',
    500: 'Service failed, please try again.',
  };

  const default_error_message = 'Network error, please try again.';
  const error_status = err.response?.status;
  const error_message = error_messages_by_status[error_status] || default_error_message;
  return error_message;
}
