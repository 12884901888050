
import {
  member_roles_map,
  timeline_event_status_map,
  timeline_publish_sync_status_map,
  user_roles_map,
} from '@/services/constants';

import {
  api_key_refs,
  hook_content_refs,
  must_user_refs,
  narrative_event_refs,
  project_refs,
  timeline_event_link_refs,
  timeline_event_reference_refs,
  timeline_event_refs,
  timeline_linked_thread_refs,
  timeline_progression_condition_refs,
  timeline_push_event_refs,
  timeline_row_refs,
  timeline_snapshot_refs,
  webhook_secret_refs,
  workspace_member_refs,
  timeline_refs,
  timeline_beat_refs,
  workspace_refs,
} from '@/services/firestore/references';

import {
  get_event_siblings_in_row,
} from '@/services/event-siblings-in-row';

import {
  current_timeline_explicit_event_references,
} from '@/services/timeline-event-references';

import {
  event_thread_ids,
} from '@/services/event_thread_ids';

import {
  detect_closed_loops,
} from '@/services/detect-closed-loops';

import {
  get_next_linked_events,
  get_prev_linked_events,
  timeline_events_with_multiple_links,
} from '@/services/timeline-events-with-links';

/**
 * @typedef {import('@/store/types').StoreState} State
 * @typedef {import('@/store/types').StoreGetters} StoreGetters
 */

const getters = {};

/**
 * True if we've resolved to an error route (404/500).
 * @param {State} state
 * @returns {boolean}
 */
getters.is_error_route = (state) => {
  return !!state.route_error;
};

/**
 * True if we've triggered a 404.
 * @param {State} state
 * @returns {boolean}
 */
getters.is_404_route = (state) => {
  return state.route_error === '404';
};

/**
 * True if we've triggered a 500.
 * @param {State} state
 * @returns {boolean}
 */
getters.is_500_route = (state) => {
  return state.route_error === '500';
};

/**
 * True if the user is authenticated.
 * @param {State} state
 * @returns {boolean}
 */
getters.is_authenticated = (state) => {
  return state.is_signed_in || !!state.user_id;
};

/**
 * True if the user an app_admin.
 * @param {State} state
 * @returns {boolean}
 */
getters.user_is_app_admin = (state) => {
  return state.current_user_role === user_roles_map.app_admin;
};

/**
 * True if the user is an owner of the current workspace.
 * @param {State} state
 * @returns {boolean}
 */
getters.user_is_owner = (state) => {
  return state.current_workspace_role === member_roles_map.OWNER;
};

/**
 * True if the user has edit rights.
 * @param {State} state
 * @param {StoreGetters} getters
 * @returns {boolean}
 */
getters.user_can_edit = (state, getters) => {
  return getters.user_is_app_admin || getters.user_is_owner || state.current_workspace_role === member_roles_map.EDITOR;
};

/**
 * Returns references to firestore collections and documents.
 * @param {State} state
 * @param {StoreGetters} getters
 */
getters.firestore_refs = (state, getters) => {

  const workspace_id = state.current_workspace_id;
  const project_id = state.current_project_id;
  const timeline_id = state.current_timeline_id;

  let user_workspaces;
  let workspace_members;
  let projects;
  let api_keys;
  let webhook_secrets;
  let narrative_events;
  let hook_contents;
  let timelines;
  let published_timelines;
  let timeline_events;
  let timeline_rows;
  let timeline_beats;
  let timeline_event_links;
  let timeline_linked_threads;
  let timeline_progression_conditions;
  let timeline_push_events;
  let sequenced_timeline_snapshots;
  let workspace;
  let project;
  let timeline;
  let timeline_event_references;

  if (getters.user_is_app_admin) {
    user_workspaces = workspace_refs.collection();
  } else if (state.user_token_workspace_ids.all.length) {
    user_workspaces = workspace_refs.user_workspaces({
      workspace_ids: state.user_token_workspace_ids.all,
    });
  }

  if (workspace_id) {

    workspace_members = workspace_member_refs.collection({
      workspace_id,
    });

    projects = project_refs.collection({
      workspace_id,
    });

    workspace = workspace_refs.document({
      workspace_id,
    });

    if (project_id) {

      api_keys = api_key_refs.collection({
        workspace_id,
        project_id,
      });

      webhook_secrets = webhook_secret_refs.collection({
        workspace_id,
        project_id,
      });

      narrative_events = narrative_event_refs.collection({
        workspace_id,
        project_id,
      });

      hook_contents = hook_content_refs.collection({
        workspace_id,
        project_id,
      });

      timelines = timeline_refs.collection({
        is_published: false,
        workspace_id,
        project_id,
      });

      published_timelines = timeline_refs.collection({
        is_published: true,
        workspace_id,
        project_id,
      });

      project = project_refs.document({
        workspace_id,
        project_id,
      });

      if (timeline_id) {

        timeline_events = timeline_event_refs.collection({
          workspace_id,
          project_id,
          timeline_id: state.current_timeline_id,
          is_published: state.current_timeline_is_published,
        });

        timeline_rows = timeline_row_refs.collection({
          workspace_id,
          project_id,
          timeline_id: state.current_timeline_id,
          is_published: state.current_timeline_is_published,
        });

        timeline_beats = timeline_beat_refs.collection({
          workspace_id,
          project_id,
          timeline_id: state.current_timeline_id,
          is_published: state.current_timeline_is_published,
        });

        timeline_event_links = timeline_event_link_refs.collection({
          workspace_id,
          project_id,
          timeline_id: state.current_timeline_id,
          is_published: state.current_timeline_is_published,
        });

        timeline_linked_threads = timeline_linked_thread_refs.collection({
          workspace_id,
          project_id,
          timeline_id: state.current_timeline_id,
          is_published: state.current_timeline_is_published,
        });

        timeline_progression_conditions = timeline_progression_condition_refs.collection({
          workspace_id,
          project_id,
          timeline_id: state.current_timeline_id,
          is_published: state.current_timeline_is_published,
        });

        timeline_push_events = timeline_push_event_refs.collection({
          workspace_id,
          project_id,
          timeline_id: state.current_timeline_id,
          is_published: state.current_timeline_is_published,
        });

        sequenced_timeline_snapshots = timeline_snapshot_refs.collection({
          workspace_id,
          project_id,
          timeline_id,
        });

        timeline = timeline_refs.document({
          workspace_id,
          project_id,
          timeline_id: state.current_timeline_id,
          is_published: state.current_timeline_is_published,
        });

        timeline_event_references = timeline_event_reference_refs.document({
          workspace_id,
          project_id,
          timeline_id: state.current_timeline_id,
          is_published: state.current_timeline_is_published,
        });
      }
    }
  }

  return {
    must_users: must_user_refs.collection(),
    workspaces: workspace_refs.collection(),
    user_workspaces,
    workspace_members,
    projects,
    api_keys,
    webhook_secrets,
    narrative_events,
    hook_contents,
    timelines,
    published_timelines,
    timeline_events,
    timeline_rows,
    timeline_beats,
    timeline_event_links,
    timeline_linked_threads,
    timeline_progression_conditions,
    timeline_push_events,
    sequenced_timeline_snapshots,
    current: {
      workspace,
      project,
      timeline,
      timeline_event_references,
    },
  };
};

/**
 * Returns narrative events that match the passed `keyword`, where `keyword` may be
 * text or an ID
 * @param {State} state
 * @returns {function(string): NarrativeEventSearchResult[]}
 */
getters.narrative_events_for_keyword = (state) => (keyword = '') => {

  const trimmed_keyword = keyword.trim();
  const clean_keyword = trimmed_keyword.toLowerCase();

  // Search the current timeline by timeline event ID.
  const timeline_event = state.current_timeline_events[trimmed_keyword];

  return Object.values(state.project_narrative_events)
    .map((narrative_event) => {
      // ID match for narrative event ID or timeline event ID.
      const id_match = (narrative_event.id === trimmed_keyword)
        || (timeline_event?.narrative_event_id === narrative_event.id);

      const in_title = narrative_event.title && narrative_event.title.toLowerCase().indexOf(clean_keyword) != -1;
      const in_description = narrative_event.description && narrative_event.description.toLowerCase().indexOf(clean_keyword) != -1;

      let hit_strength = 0;

      if (id_match) {
        hit_strength += 10;
      }

      if (in_title) {
        hit_strength += 5;
      }

      if (in_description) {
        hit_strength += 3;
      }

      return {
        hit_strength,
        narrative_event,
      };
    })
    .filter(({
      hit_strength,
    }) => !!hit_strength)
    .sort((a, b) => b.hit_strength - a.hit_strength)
    .map(({
      narrative_event,
    }) => {
      // Include the timeline event id if the narrative event is on the current timeline
      const timeline_event = Object.values(state.current_timeline_events).find((e) => (e.narrative_event_id == narrative_event.id));
      return {
        ...narrative_event,
        timeline_event_id: (timeline_event) ? timeline_event.id : undefined,
      };
    });
};


/**
 * @typedef {Object} EventRowIndexMapping
 * @property {string} timeline_event_id - The ID of the timeline event
 * @property {number} row_index - The index of the row the timeline event is part of
 */
/**
 * Return a map of row indexes keyed by timeline event id.
 * @param {State} state
 * @returns {EventRowIndexMapping|Object}
 */
getters.current_timeline_event_row_indexes = (state) => {

  if (!state.current_timeline_is_ready || !state.current_timeline?.row_indexes) {
    return {};
  }

  const row_indexes = state.current_timeline.row_indexes;
  const event_row_ids = state.current_timeline.event_row_ids;

  return Object.keys(state.current_timeline_events).reduce((mapping, id) => ({
    ...mapping,
    [id]: row_indexes[event_row_ids[id]],
  }), {});
};


/**
 * completed analytics calculated for state.current_timeline_events from state.current_timeline_analytics
 * @param {State} state
 * @returns {Object<string, Object.<string, number>>} mapping of timeline_event_id to count
 */
getters.precompute_timeline_analytics = (state) => {
  if (state.current_timeline_analytics == null) {
    return {};
  }
  const completed = (timeline_event_id) => (
    state.current_timeline_analytics?.users_per_event_state?.[timeline_event_id]?.['COMPLETED'] || 0
  );
  const skipped = (timeline_event_id) => (
    state.current_timeline_analytics?.users_per_event_state?.[timeline_event_id]?.['SKIPPED'] || 0
  );
  const started = (timeline_event_id) => (
    (state.current_timeline_analytics?.users_per_event_state?.[timeline_event_id]?.['STARTED'] || 0)
    + completed(timeline_event_id)
    + skipped(timeline_event_id)
  );
  const available = (timeline_event_id) => (
    (state.current_timeline_analytics?.users_per_event_state?.[timeline_event_id]?.['AVAILABLE'] || 0)
    + started(timeline_event_id)
  );
  const initialised = (timeline_event_id) => (
    (state.current_timeline_analytics?.users_per_event_state?.[timeline_event_id]?.['INITIAL'] || 0)
    + available(timeline_event_id)
  );
  const event_analytics = (timeline_event_id) => {
    return {
      'INITIAL': initialised(timeline_event_id),
      'COMPLETED': completed(timeline_event_id),
      'AVAILABLE': available(timeline_event_id),
      'STARTED': started(timeline_event_id),
      'SKIPPED': skipped(timeline_event_id),
    };
  };
  return Object.fromEntries(Object.values(state.current_timeline_events).map((timeline_event) => {
    return [
      timeline_event.id,
      event_analytics(timeline_event.id),
    ];
  }));
};

/**
 * state.current_timeline_beats decorated with step_index and beat_index in step_index order.
 * @param {State} state
 */
getters.current_timeline_beats = (state) => {

  const current_timeline = state.current_timeline;

  if (!state.current_timeline_is_ready || !current_timeline) {
    return [];
  }

  const timeline_beats = Object.values(state.current_timeline_beats);

  return timeline_beats
    .map((beat) => ({
      ...beat,
      id: beat.id,
      step_index: current_timeline.beat_step_indexes[beat.id],
    }))
    .sort((a, b) => a.step_index - b.step_index)
    .map((beat, i) => ({
      ...beat,
      beat_index: i + 1,
    }));
};

/**
 * @typedef {Object} TimelineEventsWithMultipleLinks
 * @property {Object.<string, Array.<string>>} from_event_ids
 * @property {Object.<string, Array.<string>>} to_event_ids
 */
/**
 * Given an event ID event_1, this mapping tells you which events link to event_1 via:
 *  getters.current_timeline_events_with_links.to_event_ids[event_1]
 * and which events are linked from event_1 via:
 *  getters.current_timeline_events_with_links.from_event_ids[event_1]
 * @param {State} state
 * @returns {TimelineEventsWithMultipleLinks}
 */
getters.current_timeline_events_with_multiple_links = (state) => {

  if (!state.current_timeline_is_ready || !state.current_timeline_event_references) {
    return {
      to_event_ids: {},
      from_event_ids: {},
    };
  }

  return timeline_events_with_multiple_links({
    event_link_ids: Object.keys(state.current_timeline_event_references.event_link_ids),
  });
};

/**
 * Returns a mapping of {
 *   [event_id]: thread_id,
 * } for all events in threads.
 * @param {State} state
 * @returns {ReturnType<event_thread_ids>}
 */
getters.current_timeline_event_thread_ids = (state) => {

  if (!state.current_timeline_is_ready || !state.current_timeline_event_references?.events_in_threads) {
    return {};
  }

  return event_thread_ids({
    events_in_threads: state.current_timeline_event_references.events_in_threads,
  });
};

/**
 * @param {State} state
 */
getters.get_next_linked_events = (state) => {
  /**
   * Given a `timeline_event_id` string, returns the next linked events (or empty array).
   * @param {string} timeline_event_id
   */
  return (timeline_event_id) => {
    if (!state.current_timeline_event_references) {
      return [];
    }
    return get_next_linked_events({
      timeline_event_id,
      event_link_ids: Object.keys(state.current_timeline_event_references.event_link_ids),
    });
  };
};

/**
 * @param {State} state
 */
getters.get_prev_linked_events = (state) => {
  /**
   * Given a `timeline_event_id` string, returns the previous linked events (or empty array).
   * @param {string} timeline_event_id
   */
  return (timeline_event_id) => {
    if (!state.current_timeline_event_references) {
      return [];
    }
    return get_prev_linked_events({
      timeline_event_id,
      event_link_ids: Object.keys(state.current_timeline_event_references.event_link_ids),
    });
  };
};

/**
 * Returns a map of event_id: true for the current timeline's disabled events.
 *
 * @param {State} state
 * @returns {Object.<string, boolean>}
 */
getters.current_timeline_disabled_events = (state) => {

  if (!state.current_timeline_is_ready) {
    return {};
  }

  return Object.values(state.current_timeline_events)
    .reduce((acc, evt) => {
      if (evt.status === timeline_event_status_map.DISABLED && evt.id) {
        acc[evt.id] = true;
      }
      return acc;
    }, {});
};

/**
 * Returns a mapping of timeline_event_id: {previous: previous_timeline_event_id, next: next_timeline_event_id} for all
 * timeline events in threads for the current timeline.
 *
 * @param {State} state
 * @param {StoreGetters} getters
 * @returns {Object.<string, Object>}
 */
getters.current_timeline_event_siblings_by_timeline_event_id = (state, getters) => {

  if (!state.current_timeline) {
    return {};
  }

  return get_event_siblings_in_row({
    event_step_indexes: state.current_timeline.event_step_indexes,
    event_row_ids: state.current_timeline.event_row_ids,
    disabled_events: getters.current_timeline_disabled_events,
  });
};

getters.current_timeline_explicit_event_references_by_timeline_event_id =
/**
 * @param {State} state
 */
(state) => {
  return current_timeline_explicit_event_references(state.current_timeline_event_references?.explicit_timeline_event_references);
},


/**
 * @param {State} state
 * @param {StoreGetters} getters
 */
getters.get_timeline_event_references = (state, getters) => (timeline_event_id, direction='both') => {

  const event_references = getters.current_timeline_explicit_event_references_by_timeline_event_id[timeline_event_id] || {
    one_way: [],
    return: [],
  };

  if (!event_references || (event_references.one_way?.length < 1 && event_references.return?.length < 1)) {
    return [];
  }

  const data = [];
  if ([
    'both',
    'one_way',
  ].includes(direction) && event_references.one_way) {
    data.push(
      ...event_references.one_way.map((reference) => {
        return {
          value: reference,
        };
      })
    );
  }
  if ([
    'both',
    'return',
  ].includes(direction) && event_references.return) {
    data.push(
      ...event_references.return.map((reference) => {
        return {
          value: reference,
        };
      })
    );
  }
  return data || [];
};

/**
 * @param {State} state
 * @returns {import('@/services/Simulator').SimulatorBeat[]}
 */
getters.current_timeline_sorted_beats = (state) => {

  const current_timeline = state.current_timeline;

  if (!state.current_timeline_beats || !current_timeline) {
    return [];
  }

  return [
    ...Object.values(state.current_timeline_beats),
  ]
    .map((beat) => ({
      id: beat.id,
      step_index: current_timeline.beat_step_indexes[beat.id],
    }))
    .sort((a, b) =>
      current_timeline.beat_step_indexes[a.id] < current_timeline.beat_step_indexes[b.id]
        ? -1
        : 1
    );
};

/**
 * @param {State} state
 * @returns {boolean}
 */
getters.can_publish_current_timeline = (state) => {
  if (!state.current_timeline_is_ready) {
    return false;
  }

  if (!state.current_timeline) {
    return false;
  }

  const is_publishing = 'publish_sync_state' in state.current_timeline && state.current_timeline?.publish_sync_state === timeline_publish_sync_status_map.IN_PROGRESS;

  return !is_publishing && Object.values(state.current_timeline_events).length > 0;
};

/**
 * @param {State} state
 * @returns {Object.<string,WorkspaceMembership>}
 */
getters.workspace_members_by_must_user_id = (state) => {
  return Object.values(state.workspace_members)
    .reduce((accumulated_memberships, current_membership) => {
      accumulated_memberships[current_membership.must_user_id] = current_membership;
      return accumulated_memberships;
    }, {});
};

/**
 * @param {State} state
 * @param {StoreGetters} getters
 * @returns {function(string|undefined): string}
 */
getters.member_display_name_from_id = (state, getters) => (must_user_id) => {

  if (!must_user_id) {
    return 'Unknown';
  }

  const must_user = getters.workspace_members_by_must_user_id[must_user_id];

  return must_user?.display_name || must_user_id;
};

/**
 * @param {State} state
 * @returns {string}
 */
getters.timeline_route_name = (state) => {
  return state.current_timeline_is_published ? 'PublishedTimeline' : 'Timeline';
};

/**
 * @param {State} state
 * @returns {string}
 */
getters.timeline_row_route_name = (state) => {
  return state.current_timeline_is_published ? 'PublishedTimelineRow' : 'TimelineRow';
};

/**
 * @param {State} state
 * @returns {string}
 */
getters.timeline_row_edit_route_name = (state) => {
  return state.current_timeline_is_published ? 'PublishedTimelineRowEdit' : 'TimelineRowEdit';
};

/**
 * @param {State} state
 * @returns {string}
 */
getters.timeline_simulation_route_name = (state) => {
  return state.current_timeline_is_published ? 'PublishedTimelineSimulation' : 'TimelineSimulation';
};

/**
 * @param {State} state
 * @param {StoreGetters} getters
 * @returns {(route_name: string) => boolean}
 */
getters.is_simulation_route = (state, getters) => {
  return (route_name) => {
    return getters.timeline_simulation_route_name === route_name;
  };
};

/**
 * @param {State} state
 * @returns {string}
 */
getters.timeline_event_route_name = (state) => {
  return state.current_timeline_is_published ? 'PublishedTimelineEvent' : 'TimelineEvent';
};

/**
 * @param {State} state
 * @returns {string}
 */
getters.timeline_event_edit_route_name = (state) => {
  return state.current_timeline_is_published ? 'PublishedTimelineEventEdit' : 'TimelineEventEdit';
};

/**
 * @param {State} state
 * @returns {string}
 */
getters.timeline_beat_route_name = (state) => {
  return state.current_timeline_is_published ? 'PublishedTimelineBeat' : 'TimelineBeat';
};

/**
 * @param {State} state
 * @returns {string}
 */
getters.timeline_beat_edit_route_name = (state) => {
  return state.current_timeline_is_published ? 'PublishedTimelineBeatEdit' : 'TimelineBeatEdit';
};

/**
 * @param {State} state
 * @returns {string}
 */
getters.timeline_progression_condition_route_name = (state) => {
  return state.current_timeline_is_published ? 'PublishedTimelineProgressionCondition' : 'TimelineProgressionCondition';
};

/**
 * @param {State} state
 * @returns {string}
 */
getters.timeline_progression_condition_edit_route_name = (state) => {
  return state.current_timeline_is_published ? 'PublishedTimelineProgressionConditionEdit' : 'TimelineProgressionConditionEdit';
};

/**
 * @param {State} state
 * @returns {string}
 */
getters.timeline_link_route_name = (state) => {
  return state.current_timeline_is_published ? 'PublishedTimelineEventLink' : 'TimelineEventLink';
};

/**
 * @param {State} state
 * @returns {string}
 */
getters.timeline_variable_route_name = (state) => {
  return state.current_timeline_is_published ? 'PublishedTimelineVariable' : 'TimelineVariable';
};

/**
 * @param {State} state
 * @returns {Object.<string, boolean>}
 */
getters.closed_loops = (state) => {
  if (!state.current_timeline_event_references) {
    return {};
  }
  return detect_closed_loops({
    event_link_ids: state.current_timeline_event_references.event_link_ids,
    events_in_threads: state.current_timeline_event_references.events_in_threads,
  });

};

/** @typedef {import("vuex").GetterTree<State, State> & typeof getters} Getters */

export default /** @type {Getters} */(getters);
