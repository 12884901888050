
/**
 * Augments local timeline_event_references documents with new fields that
 * may not exist in firestore (used when adding new properties to existing documents).
 *
 * @param {import("firebase/firestore").DocumentSnapshot<TimelineEventReference>} snapshot
 * @param {import("firebase/firestore").SnapshotOptions} options
 */
export function from_firestore(snapshot, options) {

  const doc_data = snapshot.data(options);

  if (!doc_data) {
    return undefined;
  }

  if (!doc_data.events_in_threads) {
    doc_data.events_in_threads = {};
  }

  if (!doc_data.event_link_ids) {
    doc_data.event_link_ids = {};
  }

  return {
    id: snapshot.id,
    data: doc_data,
  };
}
