import * as yup from 'yup';

import {
  is_firestore_timestamp,
} from '../convert-firestore-timestamps-to-dates/index.js';

export const yup_map = yup.object().noUnknown(false);

/**
 * When updating an array in Firestore, the FieldValue.arrayRemove() etc. helper
 * functions can be passed. If an array is passed, this schema validates
 * the array values are of the expected type. If anything else is passed
 * (e.g. the return value of FieldValue.arrayRemove()) this schema does not
 * attempt to validate.
 * @param {yup.AnySchema} value_type
 */
export function firestore_array(value_type) {
  return yup.lazy((val) => {
    if (Array.isArray(val)) {
      return yup.array().of(value_type);
    }
    return yup.mixed();
  });
}

// Some dates are instantiated as firestore timestamp sentinels. We test if the value is
// a firestore timestamp (returning an object schema) otherwise we return a date schema.
export const yup_timestamp_or_date = yup.lazy((date_or_timestamp) => {

  if (is_firestore_timestamp(date_or_timestamp)) {
    return yup.object().noUnknown(false);
  }

  return yup.date();
});

/**
 * When updating a firestore document with a field that is a Map/object:
 *
 * document = {
 *   id: 'foo',
 *   object_field: {
 *     foo: 'bar,
 *   },
 * }
 *
 * you can update the properties of the object field like so:
 *
 * changes = {
 *   id: 'foo',
 *   'object_field.foo': 'baz',
 * }
 *
 * resulting in a document that looks like:
 *
 * document = {
 *   id: 'foo',
 *   object_field: {
 *     foo: 'baz,
 *   },
 * }
 *
 * The following custom object schema supports that behaviour so the change field 'object_field.foo' is seen as valid.
 *
 * It also allows us to specify the type of object values so we can be sure that e.g. {a: 'a', b: 'b'} only contains values that are strings.
 *
 * @param {Object} param0
 * @param {import("yup/lib/object").ObjectShape} param0.schema
 * @param {Object.<string, boolean>} param0.resource_to_nested_fields_map
 * @param {import("yup/lib/object").ObjectShape} param0.resource_to_nested_field_types_map
 */
export const lazy_firestore_object = ({
  schema,
  resource_to_nested_fields_map = {},
  resource_to_nested_field_types_map = {},
}) => yup.lazy((obj) => {

  const nested_map_keys = Object.keys(resource_to_nested_field_types_map);

  return yup.object(
    Object.keys(obj).reduce((acc, key) => {
      if (key.includes('.')) {

        // If the key includes a `.` it is considered a compound key in the form {`actual_key.property`: value}
        // that sets the value of a property of the object (or array) {actual_key: {property: value}}.
        const [
          actual_key,
        ] = key.split('.');

        // We only allow these if the yup schema referenced by `actual_key` is defined in
        // resource_to_nested_fields_map (which tells us which fields are arrays or objects).
        if (resource_to_nested_fields_map[actual_key]) {
          if (resource_to_nested_field_types_map[actual_key]) {
            // If the type is a map-like object (i.e. keys are dynamic and values are of the same type)
            // we get the value type from resource_to_nested_field_types_map.
            acc[key] = resource_to_nested_field_types_map[actual_key];
          } else {
            // Otherwise, we check for an innerType (which means we're an .array().of(innerType)) and use either that or yup.mixed().
            acc[key] = /** @type {yup.ArraySchema} */(schema[actual_key]).innerType || yup.mixed();
          }
        }
      } else if (nested_map_keys.includes(key) && /** @type {yup.AnySchema} */(schema[key]).type === 'object') {
        // Otherwise, if the key is in resource_to_nested_field_types_map and the schema type is an object,
        // we must ensure the values are of the specified type.
        acc[key] = yup.lazy((map_obj) => {
          return yup.object(
            Object.keys(map_obj).reduce((map_schema, map_key) => {
              map_schema[map_key] = resource_to_nested_field_types_map[key];
              return map_schema;
            }, {}));
        });
      } else if (schema[key]) {
        // Finally add the type definition for the schema...but only if we have a type defined for that key.
        acc[key] = schema[key];
      }
      return acc;
    }, {
      ...schema,
    }));
});
