import Vue from 'vue';
import Vuex from 'vuex';

import state from './state';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

import bootstrap_data_vuex_plugin from '@/services/bootstrap-data-vuex-plugin';

Vue.use(Vuex);

/** @type {import('@/store/types').Store} */
export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  plugins: [
    bootstrap_data_vuex_plugin,
  ],
  modules: {},
  strict: true,
});
