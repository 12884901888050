
import {
  runTransaction,
  doc,
  getDocs,
} from 'firebase/firestore';

import {
  timeline_event_link_refs,
  timeline_event_reference_refs,
  timeline_event_refs,
  timeline_linked_thread_refs,
  timeline_progression_condition_refs,
  timeline_push_event_refs,
  timeline_row_refs,
  timeline_refs,
  timeline_beat_refs,
  timeline_snapshot_refs,
} from './firestore/references';

/**
 * @template T
 * @param {import('firebase/firestore').DocumentSnapshot<T>[]} docs
 */
function transform_firestore_docs(docs) {
  return docs
    .filter((doc) => doc.exists())
    .map((doc) => /** @type {T} */({
      ...doc.data(),
      id: doc.id,
    }));
}

/**
 * When given a firestore db instance and reference to a timeline, transactionally fetches
 * and returns the timeline data from firestore.
 *
 * @param {Object} param0
 * @param {import('firebase/firestore').Firestore} param0.db
 * @param {string} param0.workspace_id
 * @param {string} param0.project_id
 * @param {string} param0.timeline_id
 * @param {boolean} param0.is_published
 * @returns {Promise<{
 *   timeline_data: SequencedTimeline | PublishedTimeline,
 *    timeline_event_references_data: TimelineEventReference,
 *    child_collections: TimelineChildCollections,
 * }>}
 */
export async function fetch_timeline_from_firestore({
  db,
  workspace_id,
  project_id,
  timeline_id,
  is_published,
}) {

  const source_timeline_ref = timeline_refs.document({
    workspace_id,
    project_id,
    timeline_id,
    is_published,
  });

  const events_collection_ref = timeline_event_refs.collection({
    workspace_id,
    project_id,
    timeline_id,
    is_published,
  });

  const rows_collection_ref = timeline_row_refs.collection({
    workspace_id,
    project_id,
    timeline_id,
    is_published,
  });

  const beats_collection_ref = timeline_beat_refs.collection({
    workspace_id,
    project_id,
    timeline_id,
    is_published,
  });

  const links_collection_ref = timeline_event_link_refs.collection({
    workspace_id,
    project_id,
    timeline_id,
    is_published,
  });

  const linked_threads_collection_ref = timeline_linked_thread_refs.collection({
    workspace_id,
    project_id,
    timeline_id,
    is_published,
  });

  const progression_conditions_collection_ref = timeline_progression_condition_refs.collection({
    workspace_id,
    project_id,
    timeline_id,
    is_published,
  });

  const push_events_collection_ref = timeline_push_event_refs.collection({
    workspace_id,
    project_id,
    timeline_id,
    is_published,
  });

  const sequenced_timeline_snapshots_collection_ref = timeline_snapshot_refs.collection({
    workspace_id,
    project_id,
    timeline_id,
  })

  const source_timeline_event_references_ref = timeline_event_reference_refs.document({
    workspace_id,
    project_id,
    timeline_id,
    is_published,
  });

  // We must fetch these outside of the transaction as Firestore does not allow collection
  // queries inside transactions.
  const push_events_ids_to_copy = await getDocs(push_events_collection_ref).then((snapshot) => {
    return snapshot.docs.map(({
      id,
    }) => id);
  });

  return runTransaction(db, async (transaction) => {

    // First we must get the timeline (to make sure it exists and that we're working with the latest data).
    // Also fetch the timeline_event_references_doc to save some time.
    const [
      timeline_doc,
      timeline_event_references_doc,
    ] = await Promise.all([
      transaction.get(source_timeline_ref),
      transaction.get(source_timeline_event_references_ref),
    ]);

    if (!timeline_doc.exists()) {
      // The timeline does not exist so there's nothing to publish.
      throw new Error(`Timeline ${source_timeline_ref.path} does not exist`);
    }

    let timeline_event_references_data;

    if (!timeline_event_references_doc.exists()) {
      // The timeline_event_references doc doesn't exist! Possibly because the timeline was created before the timeline_event_references feature.
      // We're going to create a new document so that future timelines can work with it.
      timeline_event_references_data = {
        id: source_timeline_ref.id,
        explicit_timeline_event_references: {},
        events_in_threads: {},
        event_link_ids: {},
      };
    } else {
      timeline_event_references_data = timeline_event_references_doc.data();

      // If the timeline_event_references doc does exist but doesn't contain linked
      // thread data (because the timeline was created before the linking_nodes feature),
      // we're going to update the existing document to maintain backwards compatability.
      if (!timeline_event_references_data.events_in_threads) {
        timeline_event_references_data.events_in_threads = {};
      }
      if (!timeline_event_references_data.event_link_ids) {
        timeline_event_references_data.event_link_ids = {};
      }
    }

    const timeline_data = timeline_doc.data();

    // We can now get IDs for all the events, threads, rows, progression conditions and beats we need to copy across.
    const event_ids_to_copy = Object.keys(timeline_data.event_step_indexes);
    const row_ids_to_copy = Object.keys(timeline_data.row_indexes);
    const beat_ids_to_copy = Object.keys(timeline_data.beat_step_indexes);
    const progression_condition_ids_to_copy = Object.keys(timeline_data.progression_condition_step_indexes);
    const linked_thread_ids_to_copy = Object.keys(timeline_event_references_data.events_in_threads);
    const link_ids_to_copy = Object.keys(timeline_event_references_data.event_link_ids);

    // Let's fetch _all_ the docs (in parallel) to copy across. Firestore does not allow collection
    // queries inside transactions so we must go one-by-one.
    const events_promise = Promise.all(event_ids_to_copy.map((id) => transaction.get(doc(events_collection_ref, id))));
    const linked_threads_promise = Promise.all(linked_thread_ids_to_copy.map((id) => transaction.get(doc(linked_threads_collection_ref, id))));
    const rows_promise = Promise.all(row_ids_to_copy.map((id) => transaction.get(doc(rows_collection_ref, id))));
    const beats_promise = Promise.all(beat_ids_to_copy.map((id) => transaction.get(doc(beats_collection_ref, id))));
    const progression_conditions_promise = Promise.all(progression_condition_ids_to_copy.map((id) => transaction.get(doc(progression_conditions_collection_ref, id))));
    const links_promise = Promise.all(link_ids_to_copy.map((id) => transaction.get(doc(links_collection_ref, id))));
    const push_events_promise = Promise.all(push_events_ids_to_copy.map((id) => transaction.get(doc(push_events_collection_ref, id))));

    const [
      event_docs,
      row_docs,
      beat_docs,
      progression_condition_docs,
      link_docs,
      linked_thread_docs,
      push_events_docs,
    ] = await Promise.all([
      events_promise,
      rows_promise,
      beats_promise,
      progression_conditions_promise,
      links_promise,
      linked_threads_promise,
      push_events_promise,
    ]);

    return {
      timeline_data,
      timeline_event_references_data,
      child_collections: {
        timeline_events: transform_firestore_docs(event_docs),
        timeline_linked_threads: transform_firestore_docs(linked_thread_docs),
        timeline_rows: transform_firestore_docs(row_docs),
        timeline_beats: transform_firestore_docs(beat_docs),
        timeline_progression_conditions: transform_firestore_docs(progression_condition_docs),
        timeline_push_events: transform_firestore_docs(push_events_docs),
        timeline_event_links: transform_firestore_docs(link_docs),
      },
    };
  });
}
