import {
  collection,
  doc,
} from 'firebase/firestore';

import {
  db,
} from '@/services/firebase.js';

import {
  converters,
} from '../converters/index.js';

export const webhook_secret_refs = {
  document: ({
    workspace_id,
    project_id,
    webhook_secret_id,
  }) => {
    const path = [
      'workspaces',
      workspace_id,
      'projects',
      project_id,
      'webhook_secrets',
      webhook_secret_id,
    ].join('/');
    return doc(db, path).withConverter(converters.webhook_secrets);
  },
  collection: ({
    workspace_id,
    project_id,
  }) => {
    const path = [
      'workspaces',
      workspace_id,
      'projects',
      project_id,
      'webhook_secrets',
    ].join('/');
    return collection(db, path).withConverter(converters.webhook_secrets);
  },
};


