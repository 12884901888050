
import {
  timeline_events_with_multiple_links,
} from './timeline-events-with-links.js';

/**
 *
 * @param {Object} param0
 * @param {TimelineEventReference["events_in_threads"]} param0.events_in_threads
 * @param {TimelineEventReference["event_link_ids"]} param0.event_link_ids
 */
export function detect_closed_loops({
  events_in_threads = {},
  event_link_ids = {},
}) {

  const links_data = timeline_events_with_multiple_links({
    event_link_ids: Object.keys(event_link_ids),
  });

  return Object.keys(events_in_threads)
    .reduce((acc, thread_id) => {
      const is_closed = !events_in_threads[thread_id].find((event_id) => !links_data.from_event_ids[event_id]);
      events_in_threads[thread_id].forEach((id) => acc[id] = is_closed);
      return acc;
    }, /** @type {Object.<string, boolean>} */({}));
}
