import axiosInstance from './axios';
import {
  API_ROOT,
} from './api-paths';

/**
 *
 * @param {Object} param0
 * @param {string} param0.workspace_id
 * @param {string} param0.project_id
 * @param {string} param0.published_timeline_id
 */
export function publish({
  workspace_id,
  project_id,
  published_timeline_id,
}) {
  const URL = `${API_ROOT}workspaces/${workspace_id}/projects/${project_id}/publish-timeline/`;
  const data = {
    published_timeline_id,
  };
  return axiosInstance.post(URL, data);
}
