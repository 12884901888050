<template>
  <v-app
    data-test-id="app-container"
    id="inspire">
    <template v-if="is_logged_in">
      <v-navigation-drawer
        v-model="help_drawer"
        color="surface"
        width="200"
        temporary
        fixed
        disable-resize-watcher
        hide-overlay
        right>
        <template #prepend>
          <v-list-item>
            <v-list-item-title class="py-4 subtitle-1">
              Help &amp; Support
            </v-list-item-title>
            <v-btn
              icon
              @click.stop="help_drawer = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-list-item>
          <v-divider />
        </template>
        <v-list class="pt-0">
          <v-subheader class="subtitle-1">
            Resources
          </v-subheader>
          <v-list-item
            :href="support_knowledge_base_link"
            target="_blank"
            rel="noreferrer">
            <v-list-item-icon>
              <v-icon>mdi-school</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Knowledge base
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list class="pt-0">
          <v-subheader class="subtitle-1">
            Get Support
          </v-subheader>
          <v-list-item
            :href="`mailto:${support_email}`"
            target="_blank"
            rel="noreferrer">
            <v-list-item-icon>
              <v-icon>mdi-email-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              Email
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <AppHeader/>

      <v-main>
        <v-container
          fluid
          fill-height
          pb-0
          class="align-start">
          <router-view v-if="can_show_main_view" />
          <NotFoundError v-if="is_404" />
          <ServerError v-if="is_500" />
        </v-container>
      </v-main>
    </template>

    <template v-else>
      <router-view />
      <NotFoundError v-if="is_404" />
      <ServerError v-if="is_500" />
    </template>
    <v-footer
      v-if="show_footer"
      app
      color="surface">
      <span>&copy; 2024 Fictioneers</span>
    </v-footer>
    <v-footer
      v-else
      class="footer-small" />
  </v-app>
</template>

<script>

import {
  defineComponent,
} from 'vue';

import throttle from 'lodash.throttle';
import * as Sentry from '@sentry/browser';

import NotFoundError from '@/components/Errors/NotFoundError.vue';
import ServerError from '@/components/Errors/ServerError.vue';
import AppHeader from '@/components/AppHeader.vue';

import {
  support_email,
  support_knowledge_base_link,
} from '@/services/constants';

import {
  SET_FEATURE_FLAG,
} from '@/store/mutation-types';

import {
  config,
} from './services/config';

import {
  ui_feature_flags,
} from '@/services/ui-feature-flags';

export default defineComponent({
  name: 'AppLayout',
  components: {
    AppHeader,
    NotFoundError,
    ServerError,
  },
  data: () => ({
    show_footer: true,
    help_drawer: false,
    support_email,
    support_knowledge_base_link,
    app_title_is_dev: config.fictioneers.app_title.is_dev,
    app_title_is_local: config.fictioneers.app_title.is_local,
  }),
  mounted() {
    window.addEventListener('resize', this.appContentResized);
    window.addEventListener('click', this.appContentClicked);
    Object.keys(ui_feature_flags)
      .filter((flag) => ui_feature_flags[flag].force_on)
      .forEach((feature_flag) => this.switch_on_feature_flag({
        feature_flag,
      }));
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.appContentResized);
    window.removeEventListener('click', this.appContentClicked);
  },
  methods: {
    /**
     * Tell third party services who the user is.
     * Aim to only send user ID to avoid GDPR issues with
     * personally identifiable information (e.g. name and email)
     */
    identify_user() {
      const user_in_firebase = `https://console.firebase.google.com/project/${config.firebase.project_id}/firestore/data/must_users/${this.$store.state.user_id}`;
      Sentry.setUser({
        id: this.$store.state.user_id,
        user_in_firebase,
      });
      Sentry.setTag('user_in_firebase', user_in_firebase);
    },
    async logout () {
      const auth_route_with_logged_out_msg = this.$router.resolve({
        name: 'Auth',
        query: {
          message: 'signed_out',
        },
      });
      await this.$store.dispatch('firebase_logout', auth_route_with_logged_out_msg?.href);
    },
    appContentResized: throttle(function(evt) {
      // @ts-ignore
      this.$nextTick(() => {
        // @ts-ignore
        this.$bus.$emit('resize:AppContent', evt);
      });
    }, 100, {
      trailing: true,
    }),
    /**
     * @param {Event} evt Click event.
     */
    appContentClicked(evt) {
      this.$bus.$emit('click:AppContent', evt);
    },
    set_meta_title() {

      let document_title = 'Fictioneers';
      let page_title = this.$route.meta.html_meta?.title;

      if (page_title) {
        document_title = `${page_title} | ${document_title}`;
      }

      window.document.title = document_title;
    },
    /**
     * Switches on passed-in feature flag as well as any dependent feature flags.
     * @param {Object} param0
     * @param {string | undefined} param0.feature_flag
     */
    switch_on_feature_flag({
      feature_flag,
    }) {
      if (!feature_flag) {
        return;
      }
      this.$store.commit(SET_FEATURE_FLAG, feature_flag);
      if (ui_feature_flags[feature_flag]?.depends_on) {
        this.switch_on_feature_flag({
          feature_flag: ui_feature_flags[feature_flag].depends_on,
        });
      }
    },
  },
  computed: {
    can_show_main_view() {
      return (this.$store.state.bootstrap_data_loaded && !this.$store.getters.is_error_route && !this.$store.state.sign_in_promise);
    },
    is_logged_in() {
      return this.$store.getters.is_authenticated;
    },
    is_404() {
      return !this.$store.state.sign_in_promise && this.$store.getters.is_404_route;
    },
    is_500() {
      return !this.$store.state.sign_in_promise && this.$store.getters.is_500_route;
    },
  },
  watch: {
    '$route.name': {
      handler() {
        this.show_footer = !this.$route.meta.hide_footer;
        this.set_meta_title();
      },
      immediate: true,
    },
    is_logged_in: {
      handler() {
        this.identify_user();
      },
    },
    '$route.query.featureFlags': {
      handler(new_val) {
        if (new_val) {
          if (!Array.isArray(new_val)) {
            new_val = [
              new_val,
            ];
          }
          new_val.forEach((feature_flag) => {
            if (ui_feature_flags[feature_flag]?.available_in_url) {
              this.switch_on_feature_flag({
                feature_flag,
              });
            }
          });
        }
      },
      immediate: true,
    },
    '$route.query.ff': {
      handler(new_val) {
        if (new_val) {
          if (!Array.isArray(new_val)) {
            new_val = [
              new_val,
            ];
          }
          new_val.forEach((feature_flag) => {
            if (ui_feature_flags[feature_flag]?.available_in_url) {
              this.switch_on_feature_flag({
                feature_flag,
              });
            }
          });
        }
      },
      immediate: true,
    },
  },
});
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: $weight-bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

</style>
