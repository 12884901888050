import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vuetify from 'vuetify/lib/framework';
import create_vuetify from './plugins/vuetify';
import event_bus from './plugins/event-bus';
import sentry_service from './services/sentry';
// Global styles and overrides
import './scss/_global.scss';

Vue.config.productionTip = false;

Vue.use(Vuetify);

sentry_service.init();

new Vue({
  router,
  store,
  vuetify: create_vuetify(Vuetify),
  // @ts-ignore
  event_bus,
  render: h => h(App),
}).$mount('#app');
