/**
 * This service is used in both browser and server environments.
 */
import * as yup from 'yup';

import {
  schemas,
} from './schemas.js';

import {
  yup_timestamp_or_date,
  lazy_firestore_object,
} from './custom_yup_schemas.js';

const validators = {};

/**
 * Maps a resource name to a yup schema.
 * @type {Object.<string, Object.<string, yup.AnySchema | import("yup/lib/Lazy").default>>}
 */
const resource_to_sync_schema_map = {};

/**
 * Maps a resource name to an array of fields required for create operation.
 * @type {Object.<string, string[]>}
 */
const resource_to_required_fields_map = {};

/**
 * Maps a resource name to an array of fields that are of type object or array.
 * @type {Object.<string, Object.<string, boolean>>}
 */
const resource_to_nested_fields_map = {};

/**
 * Maps a resource name to an object mapping schema keys of array/object
 * to the types of the values they contain e.g. if your object `thing` looks like
 * thing = {
 *   entity_indexes: {
 *     foo: 0,
 *     bar: 1,
 *     baz: 2,
 *   }
 * }
 *
 * and you want to ensure that all values of `entity_indexes` are numbers then
 * `resource_to_nested_field_types_map` for thing should look like:
 *
 * resource_to_nested_field_types_map.thing = {
 *   entity_indexes: yup.number(),
 * }
 *
 * @type {Object.<string, Object.<string, yup.AnySchema | import("yup/lib/Lazy").default>>}
 */
const resource_to_nested_field_types_map = {};

/**
 * @typedef {Object} FirestoreDocumentSchema
 * @property {string} resource_type The type/name of the resource which corresponds to the firestore collection e.g. timeline_rows.
 * @property {Object.<string, yup.AnySchema | import("yup/lib/Lazy").default>} schema Maps resource field names to yup schema types.
 * @property {string[]} [required_fields] An array of field names that are required when creating.
 * @property {Object.<string, yup.AnySchema>} [map_value_types] Any Map fields that should have values of the same type are defined here, mapping field to type.
 */

/**
 * Adds a schema to the validators.
 * @param {FirestoreDocumentSchema} schema
 */
function add_schema({
  resource_type,
  schema,
  required_fields = [],
  map_value_types = {},
}) {

  resource_to_sync_schema_map[resource_type] = schema;

  resource_to_required_fields_map[resource_type] = required_fields;

  // Any nested fields (of type object/array) can be targeted using the compound
  // resource['object_field.prop'] or resource['array_field.index'] approach.
  resource_to_nested_fields_map[resource_type] = Object.keys(schema)
    .filter((field_name) => [
      'array',
      'object',
    ].includes(schema[field_name].type))
    .reduce((acc, curr) => {
      acc[curr] = true;
      return acc;
    }, {});

  // For Nested fields targeted using the resource['object_field.prop'] or resource['array_field.index']
  // approach, we must define the expected type. This is found in `map_value_types`.
  resource_to_nested_field_types_map[resource_type] = Object.keys(map_value_types)
    .reduce((acc, key) => {
      acc[key] = yup.lazy((val) => {
        if (typeof val === 'object' && val._methodName) {
          // We assume this is of type firebase.firestore.FieldValue.
          return yup.object().noUnknown(false);
        }
        return map_value_types[key];
      });
      return acc;
    }, {});

  add_validator(resource_type);
}

/**
 * Creates an object keyed by resource type (e.g. timelines, hook_contents) providing three
 * methods:
 *
 *   validators['resource_type'].create(resource)
 *
 * Validates resource where all required fields are enforced (ensuring all data is created with required fields in place).
 * If resource was valid, returns resource with any unknown properties stripped.
 * Throws an error if resource was not valid.
 *
 *   validators['resource_type'].update(resource)
 *
 * Validates resource where all fields are optional (allowing for PATCH-like updates).
 * If resource was valid, returns resource with any unknown properties stripped.
 * Throws an error if resource was not valid.
 *
 *   validators['resource_type'].cast(resource)
 *
 * Attempts to cast resource as resource_type (e.g. converts statetime strings to JS dates).
 */
function add_validator(resource_name) {

  const schema_with_created_modified = {
    ...resource_to_sync_schema_map[resource_name],
    created_at: yup_timestamp_or_date,
    created_by: yup.string(),
    modified_at: yup_timestamp_or_date,
    modified_by: yup.string(),
  };

  const schema_definition_with_required = Object.keys(schema_with_created_modified)
    .reduce((schema_definition_with_required, key) => {

      if (resource_to_required_fields_map[resource_name].includes(key) && schema_with_created_modified[key].required) {
        schema_definition_with_required[key] = schema_with_created_modified[key].required();
      } else {
        schema_definition_with_required[key] = schema_with_created_modified[key];
      }

      return schema_definition_with_required;
    }, {});

  const create_schema = lazy_firestore_object({
    schema: schema_definition_with_required,
    resource_to_nested_fields_map: resource_to_nested_fields_map[resource_name],
    resource_to_nested_field_types_map: resource_to_nested_field_types_map[resource_name],
  });

  const update_schema = lazy_firestore_object({
    schema: {
      ...schema_with_created_modified,
      id: yup.string(),
    },
    resource_to_nested_fields_map: resource_to_nested_fields_map[resource_name],
    resource_to_nested_field_types_map: resource_to_nested_field_types_map[resource_name],
  });

  validators[resource_name] = {
    /**
     * Validates the resource using the create schema i.e. all required fields
     * must be present in the schema definition and valid.
     *
     * @param {Object} resource The resource to validate.
     * @returns {Object} If valid, returns the resource...otherwise it throws.
     */
    create: (resource) => {

      // If validation fails, this throws an error.
      return create_schema.validateSync(resource);
    },
    /**
     * Validates the resource using the update schema i.e. all passed fields
     * must be present in the schema definition and valid.
     *
     * @param {Object} resource The resource to validate.
     * @returns {Object} If valid, returns the resource...otherwise it throws.
     */
    update: (resource) => {

      // If validation fails, this throws an error.
      return update_schema.validateSync(resource);
    },
    /**
     * Casts the passed resource i.e. returns the resource with
     * only fields defined in the schema where values have been coerced to
     * the type defined for that value in the schema.
     *
     * @param {Object} resource The resource to cast.
     * @returns {Object} The cast resource.
     */
    cast: (resource) => {
      return update_schema.cast(resource);
    },
  };
}

schemas.forEach((schema) => add_schema(schema));

export const validate = function(resource_name, payload, {
  is_create = false,
} = {}) {

  const method_to_call = is_create ? 'create' : 'update';

  return validators[resource_name][method_to_call](payload);
};

export const cast = function(resource_name, payload) {
  return validators[resource_name]?.cast(payload) || payload;
};
