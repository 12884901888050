import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import SentryFullStory from '@sentry/fullstory';
import {
  BrowserTracing,
} from '@sentry/tracing';
import filterConsoleErrors from './sentry-filter-console-errors';
import router from '../router';
import {
  config,
} from './config';

function init () {
  if (!config.sentry.dsn) {
    return;
  }
  Sentry.init({
    Vue,
    dsn: config.sentry.dsn,
    logErrors: true,
    environment: config.sentry.environment,
    release: config.sentry.release,
    beforeSend: (event) => filterConsoleErrors(event),
    ignoreErrors: [
      'Failed to fetch dynamically imported module',
    ],
    integrations: [
      ...(config.full_story.key ? [
        new SentryFullStory(config.full_story.key),
      ] : []),
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [
          config.sentry.tracing || '',
        ],
      }),
    ],
    tracesSampleRate: 1,
  });
}

export default {
  init,
};
