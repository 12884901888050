import * as yup from 'yup';

import {
  yup_map,
  firestore_array,
} from './custom_yup_schemas.js';

import {
  activity_states,
  all_lifecycle_hooks,
  invitee_status,
  member_roles,
  project_api_keys_type,
  project_api_keys_type_map,
  time_rule_operators,
  timeline_event_reference_directions,
  timeline_event_status,
  timeline_publish_sync_status,
  timeline_status,
  user_roles,
  variable_change_operators,
  variable_rule_operators_map,
} from '../constants/index.js';

const timeline_event_status_re = new RegExp(timeline_event_status.join('|'));
const activity_states_re = new RegExp(activity_states.join('|'));
const variable_change_operators_re = new RegExp(variable_change_operators.join('|'));

/** @type {import('./index.js').FirestoreDocumentSchema[]} */
export const schemas = [];

schemas.push({
  resource_type: 'timeline_events',
  schema: {
    is_locked: yup.boolean(),
    status: yup.string().matches(timeline_event_status_re),
    timeline_id: yup.string(),
    narrative_event_id: yup.string(),
    variable_changes: firestore_array(yup.object({
      state: yup.string().transform(value => !value ? null : value).nullable().matches(activity_states_re),
      variable_id: yup.string(),
      operator: yup.string().transform(value => !value ? null : value).nullable().matches(variable_change_operators_re),
      operand: yup.number(),
    })),
  },
  required_fields: [
    'status',
    'timeline_id',
  ],
});

const timeline_status_re = new RegExp(timeline_status.join('|'));

const sequenced_timelines_schema = {
  title: yup.string(),
  description: yup.string().nullable(),
  status: yup.string().matches(timeline_status_re),
  is_locked: yup.boolean(),
  project_id: yup.string(),
  event_step_indexes: yup_map,
  beat_step_indexes: yup_map,
  progression_condition_step_indexes: yup_map,
  row_indexes: yup_map,
  event_row_ids: yup_map,
  beat_ids_being_dragged: yup_map,
  event_ids_being_dragged: yup_map,
  progression_condition_ids_being_dragged: yup_map,
  variables: yup_map,
};

const sequenced_timelines_required_fields = [
  'title',
  'status',
  'project_id',
  'event_step_indexes',
  'beat_step_indexes',
  'progression_condition_step_indexes',
  'row_indexes',
  'event_row_ids',
  'event_ids_being_dragged',
  'beat_ids_being_dragged',
  'progression_condition_ids_being_dragged',
];

const sequenced_timelines_map_types = {
  event_step_indexes: yup.number(),
  beat_step_indexes: yup.number(),
  progression_condition_step_indexes: yup.number(),
  row_indexes: yup.number(),
  event_row_ids: yup.string(),
  beat_ids_being_dragged: yup.string(),
  event_ids_being_dragged: yup.string(),
  progression_condition_ids_being_dragged: yup.string(),
  variables: yup.object({
    type: yup.string(),
    default: yup.string(),
    scope: yup.string(),
    name: yup.string(),
  }),
};

schemas.push({
  resource_type: 'sequenced_timelines',
  schema: sequenced_timelines_schema,
  required_fields: sequenced_timelines_required_fields,
  map_value_types: sequenced_timelines_map_types,
});

const timeline_publish_sync_status_re = new RegExp(timeline_publish_sync_status.join('|'));

schemas.push({
  resource_type: 'published_sequenced_timelines',
  schema: {
    ...sequenced_timelines_schema,
    sismic_yaml: yup.string(),
    publish_sync_state: yup.string().matches(timeline_publish_sync_status_re),
    users_count: yup.number().nullable(),
  },
  required_fields: [
    ...sequenced_timelines_required_fields,
    'sismic_yaml',
    'publish_sync_state',
  ],
  map_value_types: sequenced_timelines_map_types,
});

schemas.push({
  resource_type: 'sequenced_timeline_snapshots',
  schema: {
    ...sequenced_timelines_schema,
    published_timeline_id: yup.string(),
  },
  required_fields: [
    ...sequenced_timelines_required_fields,
    'published_timeline_id',
  ],
  map_value_types: sequenced_timelines_map_types,
});

schemas.push({
  resource_type: 'timeline_rows',
  schema: {
    title: yup.string(),
    description: yup.string().nullable(),
    timeline_id: yup.string(),
  },
  required_fields: [
    'timeline_id',
  ],
});

const event_references_directions_re = new RegExp(timeline_event_reference_directions.join('|'));

schemas.push({
  resource_type: 'timeline_event_references',
  schema: {
    explicit_timeline_event_references: yup_map,
    events_in_threads: yup_map,
    event_link_ids: yup_map,
  },
  required_fields: [
    'explicit_timeline_event_references',
    'events_in_threads',
    'event_link_ids',
  ],
  map_value_types: {
    explicit_timeline_event_references: yup.object({
      direction: yup.string().matches(event_references_directions_re).default('BIDIRECTIONAL'),
    }),
    events_in_threads: yup.array().of(yup.string()),
    event_link_ids: yup.bool(),
  },
});

const condition_rule_operator_re = new RegExp(
  [
    ...time_rule_operators,
    ...Object.values(variable_rule_operators_map),
  ].join('|')
);

const time_rule_schema = {
  operator: yup.string().transform(value => !value ? null : value).nullable().matches(condition_rule_operator_re),
  realtime_date: yup.string().nullable(),
  realtime_time: yup.string().nullable(),
  time_is_before: yup.boolean().nullable(),
  relativetime_time: yup.string().nullable(),
  normalizedtime_days: yup.number().nullable(),
  normalizedtime_time: yup.string().nullable(),
  real_time_variable: yup.string().nullable(),
  real_time_variable_delta: yup.boolean().nullable(),
  real_time_variable_delta_days: yup.number().nullable(),
  real_time_variable_delta_time: yup.string().nullable(),
  real_time_variable_delta_direction_before: yup.boolean().nullable(),
};

const rule_schema = {
  ...time_rule_schema,
  event_state_rules: yup.array().of(yup.object({
    event: yup.string(),
    state: yup.string().transform(value => !value ? null : value).nullable().matches(activity_states_re),
  })).nullable(),
};

const condition_schema = {
  rules: yup_map,
  rules_order: firestore_array(yup.string()),
  rules_and: yup.boolean(),
};

const condition_schema_map_value_types = {
  rules: yup.object(rule_schema),
};

const hex_code_regex = new RegExp('^#([A-Fa-f0-9]{6})$');

schemas.push({
  resource_type: 'timeline_event_links',
  schema: {
    from_event_id: yup.string(),
    to_event_id: yup.string(),
    colour: yup.string().matches(hex_code_regex).nullable(),
    intent_name: yup.string(),
    ...condition_schema,
  },
  required_fields: [
    'from_event_id',
    'to_event_id',
  ],
  map_value_types: condition_schema_map_value_types,
});

schemas.push({
  resource_type: 'timeline_linked_threads',
  schema: {},
  required_fields: [],
});

schemas.push({
  resource_type: 'workspaces',
  schema: {
    name: yup.string(),
  },
  required_fields: [
    'name',
  ],
});

schemas.push({
  resource_type: 'projects',
  schema: {
    title: yup.string(),
    default_timeline: yup.string(),
  },
  required_fields: [
    'title',
  ],
});

schemas.push({
  resource_type: 'timeline_beats',
  schema: {
    title: yup.string(),
    description: yup.string().nullable(),
    timeline_id: yup.string(),
  },
  required_fields: [
    'timeline_id',
  ],
});

schemas.push({
  resource_type: 'timeline_progression_conditions',
  schema: {
    title: yup.string(),
    description: yup.string().nullable(),
    timeline_id: yup.string(),
    ...condition_schema,
  },
  required_fields: [
    'timeline_id',
  ],
  map_value_types: condition_schema_map_value_types,
});

schemas.push({
  resource_type: 'timeline_push_events',
  schema: {
    title: yup.string(),
    url: yup.string().nullable(),
    ...time_rule_schema,
  },
  required_fields: [
    'title',
  ],
});

schemas.push({
  resource_type: 'narrative_statecharts',
  schema: {
    title: yup.string(),
    sismic_yaml: yup.string(),
  },
  required_fields: [
    'title',
    'sismic_yaml',
  ],
});

schemas.push({
  resource_type: 'condition_statecharts',
  schema: {
    title: yup.string(),
    sismic_yaml: yup.string(),
  },
  required_fields: [
    'title',
    'sismic_yaml',
  ],
});


schemas.push({
  resource_type: 'narrative_events',
  schema: {
    title: yup.string(),
    description: yup.string().nullable(),
    // used for the animatic / storyboard prototype
    image_url: yup.string().nullable(),
    custom_data: firestore_array(yup.object({
      key: yup.string(),
      value: yup.string(),
    })),
    icon: yup.string().nullable(),
  },
  required_fields: [],
});

// Content can be attached to a timeline event (as opposed to a timeline event's "hook")
// which is achieved by creating a `hook_content` with a hook type of `INTERACTABLE`.
// @TODO: untangle this and add an explicit way of attaching content to the event that
// does not use the confusing term `INTERACTABLE`.
const all_lifecycle_hooks_plus_interactable = [
  ...all_lifecycle_hooks,
  'INTERACTABLE',
];
const all_lifecycle_hooks_re = new RegExp(all_lifecycle_hooks_plus_interactable.join('|'));


schemas.push({
  resource_type: 'hook_contents',
  schema: {
    provider_id: yup.string(),
    content_type: yup.string(),
    content_id: yup.string(),
    must_title: yup.string().nullable(),
    must_description: yup.string().nullable(),
    hook: yup.string().matches(all_lifecycle_hooks_re),
    narrative_event_id: yup.string(),
    source_json: yup.object(),
    source_url: yup.string().nullable(),
  },
  required_fields: [
    'provider_id',
    'content_type',
    'content_id',
    'hook',
    'narrative_event_id',
  ],
});

const user_role_re = new RegExp(user_roles.join('|'));

schemas.push({
  resource_type: 'must_users',
  schema: {
    display_name: yup.string(),
    email: yup.string(),
    ws_owner_ids: yup.array().of(yup.string()),
    ws_editor_ids: yup.array().of(yup.string()),
    ws_viewer_ids: yup.array().of(yup.string()),
    profile_image: yup.string(),
    last_active_workspace_id: yup.string(),
    role: yup.string().matches(user_role_re),
  },
  required_fields: [
    'display_name',
    'email',
  ],
});

const invitee_status_re = new RegExp(invitee_status.join('|'));
const member_role_re = new RegExp(member_roles.join('|'));

schemas.push({
  resource_type: 'workspace_members',
  schema: {
    display_name: yup.string().nullable(),
    invite_state: yup.string().matches(invitee_status_re),
    invited_by_id: yup.string(),
    invitee_email: yup.string(),
    must_user_id: yup.string().nullable(),
    workspace_id: yup.string(),
    role: yup.string().matches(member_role_re),
  },
  required_fields: [
    'invite_state',
    'workspace_id',
    'invited_by_id',
    'invitee_email',
  ],
});

const project_api_keys_type_re = new RegExp(project_api_keys_type.join('|'));

schemas.push({
  resource_type: 'project_api_keys',
  schema: {
    title: yup.string(),
    type: yup.string().matches(project_api_keys_type_re),
    key: yup.string().when('type', {
      is: project_api_keys_type_map.SECRET,
      then: yup.string().nullable(),
    }),
  },
  required_fields: [
    'type',
    'key',
  ],
});

schemas.push({
  resource_type: 'webhook_secrets',
  schema: {
    signature_key: yup.string(),
  },
  required_fields: [
    'signature_key',
  ],
});
