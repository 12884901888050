/**
 * This file provides type-supported firestore converters (https://firebase.google.com/docs/reference/js/firestore_.firestoredataconverter).
 *
 * These allow us to transform/augment data when read from firestore (when modifying data
 * written back to firestore, we tend to prefer `firestore_utils.js`).
 */


import * as timeline_event_references from './timeline-event-references-converter.js';
import * as timelines from './timeline-converter.js';
import * as timeline_snapshots from './timeline-snapshot-converter.js';

import {
  convert_firestore_timestamps_to_dates,
} from 'shared-js/convert-firestore-timestamps-to-dates';

// Define new converters and add them here.
const converter_defs = {
  must_users: pass_through_converter(/** @type {MustUser} */({})),
  workspaces: pass_through_converter(/** @type {Workspace} */({})),
  workspace_members: pass_through_converter(/** @type {WorkspaceMembership} */({})),
  projects: pass_through_converter(/** @type {Project} */({})),
  timelines,
  timeline_event_references,
  timeline_events: pass_through_converter(/** @type {TimelineEvent} */({})),
  timeline_rows: pass_through_converter(/** @type {TimelineRow} */({})),
  timeline_beats: pass_through_converter(/** @type {TimelineBeat} */({})),
  timeline_event_links: pass_through_converter(/** @type {TimelineEventLink} */({})),
  timeline_linked_threads: pass_through_converter(/** @type {TimelineLinkedThread} */({})),
  timeline_progression_conditions: pass_through_converter(/** @type {TimelineProgressionCondition} */({})),
  timeline_push_events: pass_through_converter(/** @type {TimelinePushEvent} */({})),
  narrative_events: pass_through_converter(/** @type {NarrativeEvent} */({})),
  hook_contents: pass_through_converter(/** @type {HookContents} */({})),
  api_keys: pass_through_converter(/** @type {ProjectApiKeys} */({})),
  webhook_secrets: pass_through_converter(/** @type {WebhookSecrets} */({})),
  timeline_snapshots,
};

/**
 * @template {{}} T
 * @param {{id: string, data: T} | undefined} doc_data
 * @returns {T}
 */
function firestore_document_base_serializer(doc_data) {

  if (!doc_data) {
    return /** @type {T} */({});
  }

  const document = Object.defineProperty(doc_data.data, 'id', {
    value: doc_data.id,
    enumerable: true,
  });

  const document_with_converted_dates = convert_firestore_timestamps_to_dates(document);

  return document_with_converted_dates;
}

/**
 * @template {{}} T
 * @param {T} doc
 */
// eslint-disable-next-line
function pass_through_converter(doc) {
  return {
    /**
     * @returns {{id: string, data: T} | undefined}
     */
    from_firestore: (snapshot, options) => {
      const doc_data = snapshot.data(options);

      if (!doc_data) {
        return undefined;
      }

      return {
        id: snapshot.id,
        data: doc_data,
      };
    },
  };
}

/**
 * @typedef {{[K in keyof converter_defs]: import("firebase/firestore").FirestoreDataConverter<NonNullable<ReturnType<typeof converter_defs[K]["from_firestore"]>>["data"]>}} Converter
 */

/**
 * @type {Converter}
 */
export const converters = Object.keys(converter_defs)
  .reduce((acc, curr) => {
    acc[curr] = {
      toFirestore: (doc) => doc,
      fromFirestore: (doc, options) => {
        return firestore_document_base_serializer(converter_defs[curr].from_firestore(doc, options));
      },
    };
    return acc;
  }, /** @type {Converter} */({}));
