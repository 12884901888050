
export const date_input_format = 'DD/MM/YYYY';

export const activity_states_map = {
  'INITIAL': 'INITIAL',
  'AVAILABLE': 'AVAILABLE',
  'PURGED': 'PURGED',
  'REMOVED': 'REMOVED',
  'UNAVAILABLE': 'UNAVAILABLE',
  'STARTED': 'STARTED',
  'SKIPPED': 'SKIPPED',
  'COMPLETED': 'COMPLETED',
};

export const activity_states = Object.values(activity_states_map);

export const all_lifecycle_hooks_map = {
  'STARTED': 'STARTED',
  'CANCELLED': 'CANCELLED',
  'SKIPPED': 'SKIPPED',
  'COMPLETED': 'COMPLETED',
  'CONSUMED': 'CONSUMED',
  'AVAILABLE': 'AVAILABLE',
  'UNAVAILABLE': 'UNAVAILABLE',
  'REMOVED': 'REMOVED',
};

export const all_lifecycle_hooks = Object.values(all_lifecycle_hooks_map);

export const invitee_status_map = {
  'PENDING': 'PENDING',
  'ACCEPTED': 'ACCEPTED',
};

export const invitee_status = Object.values(invitee_status_map);

export const project_api_keys_type_map = {
  'SECRET': 'SECRET',
  'VISIBLE': 'VISIBLE',
};

export const project_api_keys_type = Object.values(project_api_keys_type_map);

export const time_rule_operators_map = {
  'NORMALIZED_TIME_IS_BEFORE': 'NORMALIZED_TIME_IS_BEFORE',
  'NORMALIZED_TIME_IS_ON_OR_AFTER': 'NORMALIZED_TIME_IS_ON_OR_AFTER',
  'REAL_TIME_IS_BEFORE': 'REAL_TIME_IS_BEFORE',
  'REAL_TIME_IS_ON_OR_AFTER': 'REAL_TIME_IS_ON_OR_AFTER',
  'RELATIVE_TIME_IS_BEFORE': 'RELATIVE_TIME_IS_BEFORE',
  'RELATIVE_TIME_IS_ON_OR_AFTER': 'RELATIVE_TIME_IS_ON_OR_AFTER',
};

export const time_rule_operators = Object.values(time_rule_operators_map);

export const variable_rule_operators_map = {
  VARIABLE_NOT_PASSES_TEST: 'VARIABLE_NOT_PASSES_TEST',
  VARIABLE_PASSES_TEST: 'VARIABLE_PASSES_TEST',
};

export const timeline_event_reference_directions_map = {
  'BIDIRECTIONAL': 'BIDIRECTIONAL',
  'UNIDIRECTIONAL': 'UNIDIRECTIONAL',
  'UNIDIRECTIONAL_INVERSE': 'UNIDIRECTIONAL_INVERSE',
};

export const timeline_event_reference_directions = Object.values(timeline_event_reference_directions_map);

export const timeline_event_status_map = {
  'ENABLED': 'ENABLED',
  'DISABLED': 'DISABLED',
  'REMOVED': 'REMOVED',
  'PURGED': 'PURGED',
};

export const timeline_event_status = Object.values(timeline_event_status_map);

export const timeline_publish_sync_status_map = {
  'IN_PROGRESS': 'IN_PROGRESS',
  'COMPLETE': 'COMPLETE',
  'FAILED': 'FAILED',
};

export const timeline_publish_sync_status = Object.values(timeline_publish_sync_status_map);

export const timeline_status_map = {
  'ACTIVE': 'ACTIVE',
  'ARCHIVED': 'ARCHIVED',
  'DELETED': 'DELETED',
};

export const timeline_status = Object.values(timeline_status_map);

export const variable_change_operators_map = {
  'INCREMENT': 'increment',
  'DECREMENT': 'decrement',
  'SET': 'set',
};

export const variable_change_operators = Object.values(variable_change_operators_map);

export const variable_based_time_rule_operators_map = {
  'REAL_TIME_IS_BEFORE_VARIABLE': 'REAL_TIME_IS_BEFORE_VARIABLE',
  'REAL_TIME_IS_BEFORE_VARIABLE_WITH_DELTA': 'REAL_TIME_IS_BEFORE_VARIABLE_WITH_DELTA',
  'REAL_TIME_IS_ON_OR_AFTER_VARIABLE': 'REAL_TIME_IS_ON_OR_AFTER_VARIABLE',
  'REAL_TIME_IS_ON_OR_AFTER_VARIABLE_WITH_DELTA': 'REAL_TIME_IS_ON_OR_AFTER_VARIABLE_WITH_DELTA',
};

export const variable_based_time_rule_operators = Object.values(variable_based_time_rule_operators_map);

export const var_operators = {
  OP_EQUAL: '=',
  OP_GREATER_THAN: '>',
  OP_GREATER_THAN_OR_EQUAL: '>=',
  OP_LESS_THAN: '<',
  OP_LESS_THAN_OR_EQUAL: '<=',
};

export const var_operator_to_text_map = {
  [var_operators.OP_EQUAL]: 'is equal to',
  [var_operators.OP_LESS_THAN]: 'is less than',
  [var_operators.OP_GREATER_THAN]: 'is greater than',
  [var_operators.OP_GREATER_THAN_OR_EQUAL]: 'is at least',
  [var_operators.OP_LESS_THAN_OR_EQUAL]: 'is at most',
};

export const user_roles_map = {
  'app_admin': 'app_admin',
};

export const user_roles = Object.values(user_roles_map);

export const member_roles_map = {
  'OWNER': 'OWNER',
  'EDITOR': 'EDITOR',
  'VIEWER': 'VIEWER',
};

export const member_roles = Object.values(member_roles_map);

export const member_role_hints = {
  [member_roles_map.OWNER]: 'Owners control workspace access and can edit projects & timelines',
  [member_roles_map.EDITOR]: 'Editors can view workspace settings and edit all aspects of projects and timelines',
  [member_roles_map.VIEWER]: 'Viewers can view workspace settings, projects and timelines',
};
