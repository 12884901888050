import {
  collection,
  doc,
  query,
  where,
  documentId,
} from 'firebase/firestore';

import {
  db,
} from '@/services/firebase.js';

import {
  converters,
} from '../converters/index.js';

const get_path = ({
  workspace_id = undefined,
}) => {
  const parts = [
    'workspaces',
  ];
  if (workspace_id) {
    parts.push(workspace_id);
  }
  return parts.join('/');
};

export const workspace_refs = {
  document: ({
    workspace_id,
  }) => {
    const path = get_path({
      workspace_id,
    });
    return doc(db, path).withConverter(converters.workspaces);
  },
  collection: () => {
    const path = get_path({});
    return collection(db, path).withConverter(converters.workspaces);
  },
  user_workspaces: ({
    workspace_ids,
  }) => {
    const path = get_path({});
    const col = collection(db, path).withConverter(converters.workspaces);
    return query(col, where(documentId(), 'in', workspace_ids));
  },
};
