/**
 * @typedef {Object} EventRowPosition
 * @property {string | null} previous - ID of previous event in row
 * @property {string | null} next - ID of next event in row
 */

/**
 * @typedef {Object} EventInRow
 * @property {string} event_id
 * @property {number} step_index
 * @property {number|undefined} row_index
 */

/**
 * Get the rows of events into a usable shape for get_event_siblings_in_row
 * @param {Object} param0
 * @param {Object<string, number>} param0.event_step_indexes
 * @param {Object<string, string>} param0.event_row_ids
 * @param {Object<string, boolean>} param0.disabled_events
 * @returns {Record<string, EventInRow[]>}
 */
export function get_rows({
  event_step_indexes,
  event_row_ids,
  disabled_events,
}) {
  return Object.keys(event_step_indexes).reduce((acc, event_id) => {
    if (disabled_events[event_id]) {
      return acc;
    }
    const row_id = event_row_ids[event_id];
    if (!acc[row_id]) {
      acc[row_id] = [];
    }
    acc[row_id].push({
      event_id,
      step_index: event_step_indexes[event_id],
    });
    return acc;
  }, {});
}

/**
 * Get the previous and next events for all events in timeline
 * @param {Object} param0
 * @param {Object<string, number>} param0.event_step_indexes
 * @param {Object<string, string>} param0.event_row_ids
 * @param {Object<string, boolean>} param0.disabled_events
 * @returns {Object<string, EventRowPosition>}
 */
export function get_event_siblings_in_row({
  event_step_indexes,
  event_row_ids,
  disabled_events,
}) {
  const rows = get_rows({
    event_step_indexes,
    event_row_ids,
    disabled_events,
  });
  // For each row, sort the events and assign them a current_index
  const results = Object.keys(rows)
    .reduce((acc, row_key) => {
      const row =  rows[row_key];
      row.sort((a, b) => a.step_index - b.step_index)
        .map(timeline_event => {
          timeline_event.row_index = row.findIndex((e) => e.event_id === timeline_event.event_id);
          return timeline_event;
        })
        .forEach(timeline_event => {
          const previous = row.find(te => timeline_event.row_index !== undefined && te.row_index === timeline_event.row_index - 1);
          const next = row.find(te => timeline_event.row_index !== undefined && te.row_index === timeline_event.row_index + 1);
          acc[timeline_event.event_id] = {
            previous: previous ? previous.event_id : null,
            next: next ? next.event_id : null,
          };
        });
      return acc;
    }, {});
  return {
    ...results,
  };
}
