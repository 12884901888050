
export const ADD_SIMULATION_HOOK = 'ADD_SIMULATION_HOOK';
export const BOOTSTRAP_DATA_LOADED = 'BOOTSTRAP_DATA_LOADED';
export const CLEAR_ALL_SIMULATION_BLOCKED_CONDITIONS = 'CLEAR_ALL_SIMULATION_BLOCKED_CONDITIONS';
export const CLEAR_CURRENT_PROJECT_ID = 'CLEAR_CURRENT_PROJECT_ID';
export const CLEAR_CURRENT_TIMELINE_ANALYTICS = 'CLEAR_CURRENT_TIMELINE_ANALYTICS';
export const CLEAR_CURRENT_TIMELINE_EVENTS_BEING_CREATED = 'CLEAR_CURRENT_TIMELINE_EVENTS_BEING_CREATED';
export const CLEAR_CURRENT_TIMELINE_EVENT_BEING_CREATED = 'CLEAR_CURRENT_TIMELINE_EVENT_BEING_CREATED';
export const CLEAR_CURRENT_TIMELINE_EVENT_ID = 'CLEAR_CURRENT_TIMELINE_EVENT_ID';
export const CLEAR_CURRENT_TIMELINE_HIGHLIGHTED_LINKED_EVENT_IDS = 'CLEAR_CURRENT_TIMELINE_HIGHLIGHTED_LINKED_EVENT_IDS';
export const CLEAR_CURRENT_TIMELINE_IS_READY = 'CLEAR_CURRENT_TIMELINE_IS_READY';
export const CLEAR_ROUTE_ERROR = 'CLEAR_ROUTE_ERROR';
export const CLEAR_SIGN_IN_PROMISE = 'CLEAR_SIGN_IN_PROMISE';
export const CLEAR_SIMULATION_ACT_AND_BEAT = 'CLEAR_SIMULATION_ACT_AND_BEAT';
export const CLEAR_SIMULATION_DATA = 'CLEAR_SIMULATION_DATA';
export const CLEAR_SIMULATION_TIMELINE_EVENT_ID = 'CLEAR_SIMULATION_TIMELINE_EVENT_ID';
export const SET_404_ROUTE_ERROR = 'SET_404_ROUTE_ERROR';
export const SET_500_ROUTE_ERROR = 'SET_500_ROUTE_ERROR';
export const SET_APP_BAR_HEIGHT = 'SET_APP_BAR_HEIGHT';
export const SET_CURRENT_PROJECT_ID = 'SET_CURRENT_PROJECT_ID';
export const SET_CURRENT_TIMELINE_ANALYTICS = 'SET_CURRENT_TIMELINE_ANALYTICS';
export const SET_CURRENT_TIMELINE_EVENT_BEING_CREATED = 'SET_CURRENT_TIMELINE_EVENT_BEING_CREATED';
export const SET_CURRENT_TIMELINE_EVENT_ID = 'SET_CURRENT_TIMELINE_EVENT_ID';
export const SET_CURRENT_TIMELINE_EVENT_TAB_INDEX = 'SET_CURRENT_TIMELINE_EVENT_TAB_INDEX';
export const SET_CURRENT_TIMELINE_HIGHLIGHTED_LINKED_EVENT_IDS = 'SET_CURRENT_TIMELINE_HIGHLIGHTED_LINKED_EVENT_IDS';
export const SET_CURRENT_TIMELINE_ID = 'SET_CURRENT_TIMELINE_ID';
export const SET_CURRENT_TIMELINE_IS_PUBLISHED = 'SET_CURRENT_TIMELINE_IS_PUBLISHED';
export const SET_CURRENT_TIMELINE_IS_READY = 'SET_CURRENT_TIMELINE_IS_READY';
export const SET_CURRENT_USER_ROLE = 'SET_CURRENT_USER_ROLE';
export const SET_CURRENT_WORKSPACE_ID = 'SET_CURRENT_WORKSPACE_ID';
export const SET_CURRENT_WORKSPACE_ROLE = 'SET_CURRENT_WORKSPACE_ROLE';
export const SET_FEATURE_FLAG = 'SET_FEATURE_FLAG';
export const SET_HIGHLIGHTED_EVENT_IDS = 'SET_HIGHLIGHTED_EVENT_IDS';
export const SET_HIGHLIGHTED_LINK_IDS = 'SET_HIGHLIGHTED_LINK_IDS';
export const SET_HIGHLIGHTED_PROGRESSION_CONDITION_IDS = 'SET_HIGHLIGHTED_PROGRESSION_CONDITION_IDS';
export const SET_LARGE_TIMELINE_UI = 'SET_LARGE_TIMELINE_UI';
export const SET_SIGNED_IN = 'SET_SIGNED_IN';
export const SET_SIGN_IN_PROMISE = 'SET_SIGN_IN_PROMISE';
export const SET_SIMULATION_ACT_AND_BEAT = 'SET_SIMULATION_ACT_AND_BEAT';
export const SET_SIMULATION_BEAT_MACHINE_COMPLETED = 'SET_SIMULATION_BEAT_MACHINE_COMPLETED';
export const SET_SIMULATION_BLOCKED_CONDITION = 'SET_SIMULATION_BLOCKED_CONDITION';
export const SET_SIMULATION_LINK_FOLLOWED = 'SET_SIMULATION_LINK_FOLLOWED';
export const SET_SIMULATION_STEP_INDEX = 'SET_SIMULATION_STEP_INDEX';
export const SET_SIMULATION_TIMELINE_EVENTS = 'SET_SIMULATION_TIMELINE_EVENTS';
export const SET_SIMULATION_TIMELINE_EVENT_ID = 'SET_SIMULATION_TIMELINE_EVENT_ID';
export const SET_SIMULATION_VARIABLES = 'SET_SIMULATION_VARIABLES';
export const SET_TIMELINE_ANALYTICS_FILTER = 'SET_TIMELINE_ANALYTICS_FILTER';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_USER_TOKEN_WORKSPACE_IDS = 'SET_USER_TOKEN_WORKSPACE_IDS';
export const UPDATE_SIMULATION_TIMELINE_EVENT = 'UPDATE_SIMULATION_TIMELINE_EVENT';
