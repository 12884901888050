import * as Sentry from '@sentry/browser';
import {
  config,
} from './config';

/**
 * Captures an error and sends it to Sentry, if it is available.
 * @param {any} err An exception-like object.
 * @param {import("@sentry/types").CaptureContext} [captureContext] Additional scope data to apply to exception event.
 */
const error = (err, captureContext) => {
  if (!config.sentry.org) {
    // eslint-disable-next-line
    console.error(err);
    return;
  }
  Sentry.captureException(err, captureContext);
};

const logger = {
  error,
};

export {
  logger,
};
