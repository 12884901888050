

/**
 * Splits arr into multiple arrays of max_items each.
 * @param {Array} arr The array to split
 * @param {number} max_items Maximum number of items per chunk
 */
export function chunk(arr, max_items) {
  const chunks = [];

  for (let i = 0; i < arr.length; i += max_items) {
    const chunk = arr.slice(i, i + max_items);
    chunks.push(chunk);
  }

  return chunks;
}
