import {
  timeline_event_reference_directions_map,
} from './constants/index.js';


/**
 * Returns a 'useful" (i.e. O(1) lookup) representation of the current timeline's event reference info.
 *
 * @param {Object.<string, TimelineEventReferenceDirectionValues> | undefined} explicit_timeline_event_references
 */
export const current_timeline_explicit_event_references = function(
  explicit_timeline_event_references
) {

  if (!explicit_timeline_event_references) {
    return {};
  }

  const timeline_event_references = explicit_timeline_event_references;
  /** @type {Record.<string, { one_way: string[], return: string[] }>} */
  const timeline_event_references_by_id = {};

  /*
   * Converts composite_id to a timeline event reference.
   * For example, given that
   * {
   *   'abc:def': {'direction': 'UNIDIRECTIONAL'}
   * }
   * to:
   * {
   *   abc: {
   *     one_way: ['def'],
   *     return: [],
   *   },
   *   def: {
   *     one_way: [],
   *     return: [],
   *   }
   * }
  */
  for (const composite_id in timeline_event_references) {
    const timeline_keys = composite_id.split(':');
    const reference_info = timeline_event_references[composite_id];

    if (!(timeline_keys[0] in timeline_event_references_by_id)) {
      timeline_event_references_by_id[timeline_keys[0]] = {
        one_way: [],
        return: [],
      };
    }
    if (!(timeline_keys[1] in timeline_event_references_by_id)) {
      timeline_event_references_by_id[timeline_keys[1]] = {
        one_way: [],
        return: [],
      };
    }

    if (timeline_event_reference_directions_map.UNIDIRECTIONAL === reference_info.direction) {
      timeline_event_references_by_id[timeline_keys[0]].one_way.push(timeline_keys[1]);
    } else if (timeline_event_reference_directions_map.UNIDIRECTIONAL_INVERSE === reference_info.direction) {
      timeline_event_references_by_id[timeline_keys[1]].one_way.push(timeline_keys[0]);
    } else {
      timeline_event_references_by_id[timeline_keys[0]].return.push(timeline_keys[1]);
      timeline_event_references_by_id[timeline_keys[1]].return.push(timeline_keys[0]);
    }
  }

  return timeline_event_references_by_id;
};
