
import {
  get_current_user,
} from '@/services/firebase-auth';

import {
  BOOTSTRAP_DATA_LOADED,
  SET_CURRENT_USER_ROLE,
  SET_CURRENT_WORKSPACE_ROLE,
  SET_USER_ID,
  SET_USER_TOKEN_WORKSPACE_IDS,
} from '@/store/mutation-types';

/**
 * @typedef {import('@/store/types').ActionContext} ActionContext
 * @param {{commit: ActionContext['commit'], dispatch: ActionContext['dispatch']}} context
 */
export default async function bootstrap_data_vuex_plugin(context) {

  let current_user = await get_current_user();
  if (current_user) {
    context.commit(SET_USER_ID, current_user.user.uid);
    context.commit(SET_USER_TOKEN_WORKSPACE_IDS, {
      ws_owner_ids: current_user.token.claims.ws_owner_ids,
      ws_editor_ids: current_user.token.claims.ws_editor_ids,
      ws_viewer_ids: current_user.token.claims.ws_viewer_ids,
    });
    context.commit(SET_CURRENT_USER_ROLE, current_user.token.claims.role);
    await context.dispatch('fetch_bootstrap_data');
    context.commit(SET_CURRENT_WORKSPACE_ROLE);
    context.commit(BOOTSTRAP_DATA_LOADED);
  }

  return;
}
