
/**
 * @template {{}} T
 * @param {T} document
 * @returns {T}
 */
export const convert_firestore_timestamps_to_dates = function (document) {

  const document_with_converted_dates = Object.keys(document)
    .reduce((document_with_converted_dates, key) => {

      document_with_converted_dates[key] = is_firestore_timestamp(document[key]) ? document[key].toDate() : document[key];

      return document_with_converted_dates;
    }, /** @type {T} */({}));

  return document_with_converted_dates;
};

export const is_firestore_timestamp = function(value) {

  const is_object = value && typeof value === 'object';

  if (!is_object) {
    return false;
  }

  if (value._methodName === 'serverTimestamp') {
    return true;
  }

  const has_seconds_property = Object.prototype.hasOwnProperty.call(value, 'seconds') || Object.prototype.hasOwnProperty.call(value, '_seconds');

  if (!has_seconds_property) {
    return false;
  }

  const has_nanoseconds_property = Object.prototype.hasOwnProperty.call(value, 'nanoseconds') || Object.prototype.hasOwnProperty.call(value, '_nanoseconds');

  if (!has_nanoseconds_property) {
    return false;
  }

  const has_toDate_method = typeof value.toDate === 'function';

  if (!has_toDate_method) {
    return false;
  }

  return true;
};
