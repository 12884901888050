
/**
 * @param {Object} param0
 * @param {string} param0.is_published
 * @param {string} param0.workspace_id
 * @param {string} param0.project_id
 * @param {string} [param0.timeline_id]
 */
export const timeline_path = ({
  is_published,
  workspace_id,
  project_id,
  timeline_id,
}) => {
  const collection = is_published ? 'published_sequenced_timelines' : 'sequenced_timelines';
  const collection_path = [
    'workspaces',
    workspace_id,
    'projects',
    project_id,
    collection,
  ];

  if (timeline_id) {
    collection_path.push(timeline_id);
  }

  return collection_path.join('/');
};

export const timeline_child_path = ({
  is_published,
  workspace_id,
  project_id,
  timeline_id,
  collection_name,
  document_id = undefined,
}) => {
  const parts = [
    timeline_path({
      is_published,
      workspace_id,
      project_id,
      timeline_id,
    }),
    collection_name,
  ];

  if (document_id) {
    parts.push(document_id);
  }

  return parts.join('/');
};
