<template>
  <section class="sign-out" />
</template>

<script>

import {
  defineComponent,
} from 'vue';

export default defineComponent({
  name: 'ServerErrorView',
  mounted() {
    const auth_route_with_logged_out_msg = this.$router.resolve({
      name: 'Auth',
      query: {
        message: 'signed_out',
      },
    });
    this.$store.dispatch('firebase_logout', auth_route_with_logged_out_msg?.href);
  },
});
</script>

